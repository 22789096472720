/*
// theme.js
export const theme = {
    colors: {
        primary: '#eee',
        secondary: '#1C80BC',
        
    },
    shadow: {
        boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)' // přidá stín kolem div
    }
    // další globální styly...
};
*/

// theme.js
import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      light: '#f8f8f8',
      main: '#eee',
      dark: '#002884',
      contrastText: '#fff',
    },
    secondary: {
      light: '#b3d1f3',
      main: '#1C80BC',
      dark: '#3498db',
      contrastText: '#0078b4',
    },
    type: 'light',
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
  spacing: 2,
  shape: {
    borderRadius: 4,
  },
  shadows: Array(25).fill('0px 0px 5px rgba(0, 0, 0, 0.2)'),
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#0078b4',
          },
        },
      },
    },
  },
});

export default theme;