//basedata.jsx
import React, { createContext, useState, useEffect } from 'react';
import { getCiselnik, getUkol, getProces } from '../api/apiBase';
import { fetchSections, fetchStructure, paramsListAll, checkTaskStatus, getData4, getKontakt } from '../api/apiRest';
import { set } from 'date-fns';

// Vytvoření kontextu
export const DataContext = createContext();


// Provider komponenta
export const StoreProvider = ({ children }) => {
    const [sectionsGlob, setSectionsGlob] = useState([]);
    const [structureGlob, setStructureGlob] = useState([]);
    const [contactsGlob, setContactsGlob] = useState([]);
    const [ciselnikGlob, setCiselnikGlob] = useState([]); //pole ciselniku
    const [procesAll, setProcesAll] = useState([]); //pole unikatnich procesnich parametru, brano z _ciselnik
    const [procesList, setProcesList] = useState([]); //pole parametru
    const [paramsLAllAll, setParamsLAllAll] = useState([]); //pole parametru
    const [isHeaderMenuVisible, setIsHeaderMenuVisible] = useState(false);
    const [aktualViewSection, setAktualViewSection] = useState('Přehled'); //aktualni zobrazena sekce   
    const [ukol, setUkol] = useState({}); //ukol pro detailni zobrazeni
    const [dataLookupOP, setDataLookupOP] = useState({}); //pole lookupu OP
    
    const token = localStorage.getItem('token');

    // Funkce pro načtení dat
    const fetchData = async () => {
        //const token = localStorage.getItem('token');
        try {
            
            //console.log('v baseData - fetchSections, token', token);

            const response2 = await fetchSections(token);
            const taskId2 = response2.taskId;
            //console.log('v baseData - taskId2 ', taskId2);
            const sectionData = await checkTaskStatus(taskId2, 'baseData');
            console.log('v baseData - sectionData ', sectionData);

            //const contactData = await fetchContacts();
            const contactData = await getKontakt(token);
            console.log('------------------------------v baseData - contactData ', contactData);

            /*
            const response3 = await getKontakt(token);
            const taskId3 = response3.taskId;
            const result3 = await checkTaskStatus(taskId3, 'baseData');
            const contactData = result3.result;
            console.log('v baseData - contactData ', contactData);    
            */

            const response4 = await fetchStructure(token);
            const taskId4 = response4.taskId;
            //console.log('v baseData - fetchStructure - taskId4 ', taskId4);
            const structureData = await checkTaskStatus(taskId4, 'baseData');
            console.log('v baseData - structureData ', structureData);

            const paramsLAllAll = await paramsListAll(token);
            console.log('v baseData - paramsLAllAll ', paramsLAllAll);
            /*
            const taskId1 = response1.taskId;
            //console.log('v baseData - paramsListAll - taskId1 ', taskId1);    
            const paramsLAllAllX = await checkTaskStatus(taskId1, 'baseData');
            //console.log('v baseData - paramsLAllAllX ', paramsLAllAllX);
            const paramsLAllAll = paramsLAllAllX;
            
            */

            const ciselnikAll = await getCiselnik(token); //vrátí pole ciselniku
            console.log('v baseData - ciselnikAll ', ciselnikAll);

            
            const ukolAll = await getUkol(token); //vrátí pole ukolu
            console.log('v baseData - ukolAll ', ukolAll);

            const procesListRes = await getProces(token);
            console.log('v baseData - procesListRes ', procesListRes);

            const procesAll = ciselnikAll
            .filter((obj, index, self) => 
                self.map(selfObj => selfObj.parametr).indexOf(obj.parametr) === index //self - jen unikatni hodnoty
            )
            .filter(obj => obj.parametr.includes('proces')) //jen procesni parametry
            .map(obj => obj.parametr); // pole unikatnich procesnich parametru 
            //console.log('v baseData - procesAll ', procesAll);

            
            setSectionsGlob(sectionData.data);
            setStructureGlob(structureData);
            setContactsGlob(contactData);
            setCiselnikGlob(ciselnikAll);
            setProcesList(procesListRes);
            setProcesAll(procesAll);
            setParamsLAllAll(paramsLAllAll);
            setUkol(ukolAll);

        } catch (error) {
            console.error('Chyba při načítání dat:', error);
            console.log('nastal session problem na serveru', error.message);
            if (error.message.includes('/logout')) {
                window.location.href = '/logout';
            }
        }

        
    };

    useEffect(() => {
        if (!sectionsGlob.length && !structureGlob.length && contactsGlob && !ciselnikGlob.length && !procesList.length && !procesAll.length && !paramsLAllAll.length && !ukol.length && token && token !== 'undefined' && token !== 'null') {
            fetchData();
        }
        
    }, [sectionsGlob, structureGlob, contactsGlob, ciselnikGlob, procesList, procesAll, paramsLAllAll, ukol, token]);

    
    //dataTable OP si nacte zde, protoze je velka a nechci aby user cekal v HledatComponent
    useEffect(() => {
        const fetchDataLookupOP = async () => {
            
            try {
                const response3 = await getData4(paramsLAllAll, 'lookup', '', 'OP', 'idOPS', '', token);
                //console.log('response3:', response3);
                const taskId3 = response3.taskId;
                //console.log('taskId3:', taskId3);
                const dataLookupOPX = await checkTaskStatus(taskId3, 'baseData');
                const dataLookupOP = dataLookupOPX?.data;
                setDataLookupOP(dataLookupOP);
                console.log('dataLookupOP:', dataLookupOP);
            } catch (error) {
                console.error('Chyba při načítání dat:', error);
            }
        };
    
        if (paramsLAllAll.length > 0 && token) {
            // Spustit fetchDataLookupOP pouze tehdy, když paramsLAllAll je k dispozici
            fetchDataLookupOP();
        }
    }, [paramsLAllAll]); // Sledovat změny paramsLAllAll


    // Objekt s daty a funkcemi, které budou dostupné ve všech komponentách
    const store = {
        sectionsGlob,
        setSectionsGlob,
        structureGlob,
        setStructureGlob,
        contactsGlob,
        setContactsGlob,
        ciselnikGlob,
        setCiselnikGlob,
        procesList,
        setProcesList,
        ukol,
        setUkol,
        procesAll,
        setProcesAll,
        isHeaderMenuVisible,
        setIsHeaderMenuVisible,
        aktualViewSection,
        setAktualViewSection,
        paramsLAllAll,
        setParamsLAllAll,
        dataLookupOP,
        setDataLookupOP
    };
    //console.log('V DataContext.js - store', store);

    return (
        <DataContext.Provider value={store}>
            {children}
        </DataContext.Provider>
    );
};

