//LookupComponent.jsx
import { DetailDataContext } from '../store/detailDataContext';
import HledatComponent from './HledatComponent'; // importujte HledatComponent
import { dataInputDefault1 } from '../api/dataInputDefault1';
import Modal from 'react-modal';
import React, { useEffect, useRef, useState, useContext } from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { DataContext } from '../store/baseData';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import LinkIcon from '@mui/icons-material/Link';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';

    

// Toto je nutné pro přístupnost (a11y)
Modal.setAppElement('#root');

const LookupCiselnikComponent = ({ param, placementRef }) => {
    // Přiřazení hodnoty inputu na základě dostupných dat
    const [modalIsOpen, setModalIsOpen] = useState(false);
    

    const openNewWindow = () => {
        // místo otevření nového okna, otevřete modální dialog
        setModalIsOpen(true);
    };

    const {
        inputLookups,
        setInputLookups,
        inputDefaults,
        setInputDefaults,
        detailDataNutne,
        detailDataRest,
        isChanged,
        setIsChanged,
        inputChanged,
        setInputChanged
      
    } = useContext(DetailDataContext); // Získání hodnot z kontextu

    const { sectionsGlob, structureGlob, contactsGlob, ciselnikGlob, isHeaderMenuVisible, setIsHeaderMenuVisible } = useContext(DataContext);

    //const [inputValue, setInputValue] = useState(inputLookups?.[param.id] || '');
    //console.log('11111111111 v LookupComponent - inputValue', inputValue, inputLookups, param.id);
    //useEffect(() => {
    //    setInputValue(inputLookups?.[param.id] || '');
    //}, [inputLookups, param.id, param, placementRef]);
    
    const rowData = detailDataNutne?.dataGlob[0]; // Získání dat z detailDataContext
    const paramsL = detailDataNutne?.paramsLGlob; // Získání dat z detailDataContext
    const headers = detailDataNutne?.headers; // Získání dat z detailDataContext
    const dataDefaultGlob = detailDataRest?.dataDefaultGlob; // Získání dat z detailDataContext
    const zdrojPar = param.zdrojParametr;
    const zdrojParRidicihoPar = param.zdrojParRidicihoPar;
    const header = param.parametr;
    const paramsDefaultGlob = detailDataRest?.paramsDefaultGlob;
    const zdroj = param.zdroj;
    const unit = param.jednotka;
    const ridiciParametr = param.ridiciParametr;
    const idRidiciParametr = paramsL.find(item => item.parametr === ridiciParametr)?.id;  
    const isId = header.substring(0, 2) === 'id'; // Zjistí, zda je parametr typu id, kontrluja prvni dva znaky

    const paramArray = Object.entries(param).map(([key, value]) => ({ key, value }));

    const inputLookup = inputLookups?.[param.id] || '';
    //console.log('v LookupComponent - inputLookup', inputLookup, inputLookups);
    const [druh, setDruh] = useState(rowData[header] || '');
    
    const [lookupList, setLookupList] = useState(ciselnikGlob.filter(item => item.parametr === param.parametr)?.map(item => item.value));
    //const [ridiciParametrValue, setRidiciParametrValue] = useState(rowData[param.ridiciParametr] || '');
    
    //console.log('v LookupComponent - placementRef', placementRef);
    //console.log('v LookupComponent - paramArray', paramArray, 'param', param, 'param.zdrojParametr', param.zdrojParametr, 'param.ridiciParametr', param.ridiciParametr);
    //console.log('v LookupComponent - dataDefaultGlob', dataDefaultGlob.default, zdrojParRidicihoPar, zdrojPar);
    

    
    
    useEffect(() => {
        if (detailDataNutne && ciselnikGlob) {
            //console.log('000000 - v LookupCiselnikComponent - useEffect: ', header);
           //console.log('v LookupComponent - param', param);
           let ridiciParametrValue

            if (inputLookups && inputLookups[idRidiciParametr]) {
                ridiciParametrValue = inputLookups[idRidiciParametr];
                //console.log('v LookupComponent - mam data v inputLookups - ridiciParametrValue:', ridiciParametrValue);
            } else {
                ridiciParametrValue = rowData[ridiciParametr];
                //console.log('v LookupComponent - nemam data v inputLookups - ridiciParametrValue:', ridiciParametrValue);
            }
            //console.log('v LookupComponent - ridiciParametrValue', ridiciParametrValue);            

            const zavislyParametrCiselniku = paramArray.filter(item => item.ridiciParametr === header && item.zdroj === '_ciselnik');
            let druhRes = '';
            if (param.zdroj == '_ciselnik' && ridiciParametr) {
                druhRes = 'zavisly'
                //console.log(header, ' je parametr vybirany z ciselniku podle zvoleneho jineho parametru')
                
            } else if (zavislyParametrCiselniku.length > 0) {
                druhRes = 'ridici'
                //console.log(header, ' je parametr ridici pro jiny parametr vybirany z ciselniku')
                //console.log('rizeny parametr je:', zavislyParametrCiselniku)
                
            }
            setDruh(druhRes); // aktualizace stavu

            //console.log('v LookupCiselnikComponent - param.zdroj :', param.zdroj, 'param.parametr: ', param.parametr, 'ridiciParametr :', ridiciParametr, 'ridiciParametrValue :', ridiciParametrValue, 'ciselnikGlob: ', ciselnikGlob );
            
            if(param.zdroj == '_ciselnik' && ridiciParametr && ridiciParametrValue){
                const filterIndex = param.parametr + '-' + ridiciParametrValue;
                const lookupListRes = ciselnikGlob.filter(item => item.parametr === filterIndex).map(item => item.value);
                setLookupList(lookupListRes);
                //console.log('v LookupCiselnikComponent - s ridici par - lookupListRes :', filterIndex, lookupListRes, lookupList);
            } else {
                const lookupListRes = ciselnikGlob.filter(item => item.parametr === param.parametr)?.map(item => item.value); 
                setLookupList(lookupListRes);
                //console.log('v LookupCiselnikComponent - bez ridiciho par - lookupListRes :', lookupListRes, lookupList);
            }
            //console.log('v LookupCiselnikComponent - po setLookupList - lookupList: ', lookupList, 'inputLookup: ', inputLookup);

            
            if (lookupList && !inputLookup && param && param.parametr && rowData && rowData[param.parametr] && !inputChanged) {
                //console.log('v LookupComponent - splnena podminka pro vlozeni hodnoty do inputu', param.id, rowData[param.parametr]);
                setInputLookups(prevValues => ({
                    ...prevValues,
                    [param.id]: rowData[param.parametr],
                }));
            }
            //console.log('v LookupComponent - inputLookups', inputLookups);
            
        }
        
  
    }, [param, placementRef, ciselnikGlob, detailDataNutne, idRidiciParametr, inputLookups]);
    
    
    const handleEntityClick = async (event) => {
        const newInputValue = event.target.value;
        console.log('v LookupComponent - handleEntityClick - newInputValue', newInputValue);
        const newInputId = newInputValue ? ciselnikGlob.find(item => item.value === newInputValue)?.id :'';
        //console.log('v LookupComponent - handleEntityClick', newInputValue);
        //console.log('v LookupComponent - handleEntityClick - param', param.id, newInputValue);
               
        try {
            setInputLookups(prevValues => ({
                ...prevValues,
                [param.id]: newInputValue,
            }));
            console.log('v LookupComponent - handleEntityClick - param', param);
            //console.log('v LookupComponent - handleEntityClick - inputDefaults', inputDefaults);
            const inputDefs = dataInputDefault1(header, newInputId, paramsDefaultGlob, dataDefaultGlob, paramsL, zdroj);
            //console.log('v handleEntityClick - inputDefs', inputDefs)
           
            
            for (let [id, inputDef] of Object.entries(inputDefs)) {
                setInputDefaults(prevValues => {
                    const newValues = {
                        ...prevValues,
                        [id]: inputDef,
                    };
                    //console.log('v handleEntityClick - zmenen default :', newValues);
                    return newValues;
                });
            }
            setIsChanged(true);
            setInputChanged(true); 
        
            
            console.log('v LookupComponent - handleEntityClick - inputLookups', inputLookups, 'lookupList :', lookupList)
            
            
        } catch (error) {
            console.error('Chyba při zmene default:', error);
        }
 
    };

        
    if (!detailDataNutne && !ciselnikGlob){
        return <div>Loading...</div>;
    }else{
        return (
            <Box 
                component="form"
                sx={{
                    display: 'grid',
                    placeItems: 'center',
                    '& .MuiTextField-root': { m: 1 },
                    margin: '10px 0 10px 0',
                }}
                noValidate
                autoComplete="off"
            >
                
                <FormControl style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                   
                    <TextField
                    select
                    //labelId={`input_${header}`}
                    id={`input_${header}`}
                    value={inputLookup}
                    label={param.vizu}
                    onChange={handleEntityClick}
                    
                    style={{
                        margin: '0px 0 10px 0',
                        height: '40px',
                        width: '300px',
                        fontSize: '16px',
                        textAlign: 'left',
                    }}
                    
                    InputProps={{
                        style: { height: '40px' }, // Change this to adjust the height
                        endAdornment: (
                            <InputAdornment position="end">
                                {unit && <span style={{ marginRight: '20px' }}>{unit}</span>}
                                {inputLookup && (
                                    <IconButton onClick={() => handleEntityClick({ target: { value: '' } })} style={{ marginRight: '10px' }}>
                                        <CloseIcon />
                                    </IconButton>
                                )}
                            </InputAdornment>
                        ),
                    }}
                >
                    {Array.isArray(lookupList) && lookupList.map((item, index) => (
                        <MenuItem key={index} value={item}>{item}</MenuItem>
                    ))}
                </TextField>
                   
                </FormControl>
            </Box>
        );
    }
    
}
export default LookupCiselnikComponent;

