// PlacementComponent.jsx
import React, { useEffect, useRef, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box, Grid, Paper } from '@mui/material';
import { DataContext } from '../store/baseData'; // Import DetailDataProvider
import { set } from 'date-fns';
import Lookup from '../atoms/Lookup';
import Typography from '@mui/material/Typography';


const PlacementDiv = styled(Paper)(({ theme }) => ({
    m: 1,
    backgroundColor: theme.palette.primary.light,
    color: 'black',
    margin: '5px',
    padding: '10px',
    width: '200px',
    //boxShadow: theme.palette.primary.main,
    
  }));

const Item = styled(Paper)(({ theme }) => ({
    
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.primary,
    boxShadow: 'none', // Add this line to remove the box shadow
}));


const ProcesView = ({ tableEntita, data}) => {
    const navigate = useNavigate();
    const page = 'intranet';
    
    const { sectionsGlob, structureGlob, contactsGlob, ciselnikGlob, setCiselnikGlob, procesAll, isHeaderMenuVisible, setIsHeaderMenuVisible, aktualViewSection, setAktualViewSection, paramsLAllAll, setParamsAllAll } = useContext(DataContext);

   
    console.log('v procesView - tableEntita', tableEntita);
    //console.log('v procesView - data', data);
    //console.log('v procesView - paramsLAllAll', paramsLAllAll);
    //console.log('v procesView - procesAll', procesAll);
    
    const procesEntita = paramsLAllAll.filter(obj => obj && obj.parametr && obj.parametr.includes('proces') && obj.tableEntita === tableEntita).map(obj => obj.parametr);
    //const procesEntita = paramsLAllAll.filter(obj => obj.parametr.includes('proces') && obj.tableEntita === tableEntita).map(obj => obj.parametr);
    //console.log('1 v procesView - procesEntita', procesEntita);
    //['procesOPBRealizace', 'procesOPBObchod', 'procesOPBDistribuce', 'procesOPBObchod']
    
    const [aktualProces, setAktualProces] = useState('');
    const [procesValue, setProcesValue] = useState([]);
    const [dataProces, setDataProces] = useState([]);
    const [procesEntitaAll, setProcesEntitaAll] = useState(procesAll.filter(obj => procesEntita.some(entita => obj.includes(entita)))  );

    

        //const procesEntitaAllRes = procesAll.filter(obj => procesEntita.includes(obj));
        //console.log('1a v procesView - procesEntitaAll', procesEntitaAllRes);
        //['procesOPBObchod', 'procesOPBRealizace']

    useEffect(() => {
        
        if(aktualProces == ''){setAktualProces(procesEntitaAll[0])}
        //console.log('000000000 v procesView - aktualProces', aktualProces, 'primo z pole: ', procesEntitaAll[0]);

        let aktualProcesIndex
        let aktualProcesIndexPo
        if(aktualProces && typeof aktualProces === 'string'){
            aktualProcesIndex = aktualProces.split('-')[0];
            aktualProcesIndexPo = aktualProces.split('-')[1];
            //console.log('111111111 v procesView - proces', aktualProcesIndex);
            //console.log('111111111 v procesView - proces', aktualProcesIndexPo);
            
        }
       
        //console.log('1 v procesView - aktualProcesindex', aktualProcesIndex);

        const ridiciParametrAktualProcesIndex = paramsLAllAll.filter(obj => obj.parametr === aktualProcesIndex && obj.page === 'intranet').map(obj => obj.ridiciParametr);
        //console.log('1 v procesView - ridiciParametrAktualProcesIndex', ridiciParametrAktualProcesIndex);

        const procesEntitaAllRes = procesAll.filter(obj => procesEntita.some(entita => obj.includes(entita)));
        //console.log('1a v procesView - v useEffect - procesEntitaAllRes', procesEntitaAllRes);
        //console.log('111111111 v procesView - aktualProces', aktualProces);

        const procesValueRes = ciselnikGlob.filter(obj => obj.parametr === aktualProces).map(obj => obj.value);
        //console.log('1b v procesView - procesValueRes', procesValueRes);

        const dataProcesResX = data.filter(obj => obj[ridiciParametrAktualProcesIndex] === aktualProcesIndexPo)
        //console.log('2a v procesView - dataProcesResX', dataProcesResX);
        
        const dataProcesRes = dataProcesResX.reduce((acc, item) => {
            const key = item[aktualProcesIndex];
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push({
                id: item.id,
                codeRnt: item.codeRnt,
                name: item.name
            });
            return acc;
        }, {});
        //console.log('2b v procesView - dataProcesRes', dataProcesRes);

        
        setProcesValue(procesValueRes);
        setDataProces(dataProcesRes);
        setProcesEntitaAll(procesEntitaAllRes);
        //console.log('3 v procesView - procesEntitaAll', procesEntitaAllRes);

    }, [aktualProces, setProcesEntitaAll, setAktualProces, setProcesValue, tableEntita, aktualViewSection]);

    const handleEntityClick = (val) => {
        //console.log('v procesView - handleEntityClick - val', val);        
        setAktualProces(val);
        
       
    };
    //console.log('v procesView - aktualProces', aktualProces);
    //console.log('000000 v procesView - dataProces', dataProces);
    
    

    //console.log('paramsL', paramsL)
    if (!procesAll || !ciselnikGlob || !dataProces || !procesValue) {
        //console.log('4444 v PlacementComponent - detailData, rendrovani ', detailData);
        return <div>Loading ProcesView...</div>;
    } else {
        return (
            <>
              {procesEntitaAll.length > 0 && <Lookup label={'Proces'} lookupList={procesEntitaAll} inputLookup={aktualProces} handleEntityClick={handleEntityClick} />}
              <Box sx={{ display: 'flex', flexWrap: 'nowrap', overflowX: 'auto', height: '80vh' }}>
                {procesValue.map(id => {
                  const name = id;
                  return (
                    <Box sx={{ flexShrink: 0, width: 210, height: 1500, marginRight: 1 }} key={id}>
                      <Item sx={{ height: 1500 }}>
                        <PlacementDiv key={id}>
                          <div id={`placement_${id}`}>
                            <Typography 
                              variant="h7"
                              sx={{
                                //color: (theme) => theme.palette.secondary.dark,
                                fontWeight: 'bold',
                                paddingBottom: '0px',
                                
                              }}
                            >
                              {name}
                            </Typography>
                            {Object.entries(dataProces).map(([procesName, procesItems], index) => {
                              if(procesName === id){
                                return (
                                  <div key={index}>
                                    {procesItems.map((item, itemIndex) => (
                                      <Box 
                                        key={itemIndex}
                                        sx={{
                                          borderTop: '1px solid black',
                                          padding: '10px',
                                          margin: '10px 0',
                                          '&:hover': {
                                            backgroundColor: 'white',
                                          },
                                        }}
                                      >
                                        {Object.entries(item).map(([key, value], entryIndex) => {
                                          if (key === 'id') {
                                            return (
                                              <div 
                                                key={entryIndex}
                                                onClick={() => navigate(`/detail-view?tableEntita=${tableEntita}&idUn=${value}&entita=${tableEntita}&page=${page}`)}
                                              >
                                                <Typography 
                                                  variant="h8"
                                                  sx={{
                                                    cursor: 'pointer',
                                                    color: (theme) => theme.palette.secondary.main,
                                                    '&:hover': {
                                                      textDecoration: 'underline',
                                                    },
                                                  }}
                                                >
                                                  {value}
                                                </Typography>
                                              </div>
                                            )
                                          } else {
                                            return (
                                              <div key={entryIndex}>
                                                <Typography variant="h8">{value}</Typography>
                                              </div>
                                            )
                                          }
                                        })}
                                      </Box>
                                    ))}
                                  </div>
                                )
                              }
                            })}
                          </div>
                        </PlacementDiv>
                      </Item>
                    </Box>
                  );
                })}
              </Box>
            </>
          )
    };
};

export default ProcesView;
