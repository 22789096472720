import React, {useContext, useEffect} from 'react';
import ScrollableTabsButtonPrevent from '../atoms/ScrollableTabsButtonPrevent';
import { DataContext } from '../store/baseData';

function ViewSectionComponent({ dataLookup}) {
   
    const { sectionsGlob, structureGlob, contactsGlob, ciselnikGlob, isHeaderMenuVisible, setIsHeaderMenuVisible, aktualViewSection, setAktualViewSection } = useContext(DataContext);
   
    // Nastavení výchozí hodnoty pro aktualDetailSection
    useEffect(() => {
       //console.log('000000 - v ViewSectionComponent - useEffect: ', aktualViewSection);
    }, [setAktualViewSection]);
    
    //console.log('v DetailSectionComponent - aktualDetailSection', aktualDetailSection);

    // Podmíněné renderování
   
        return (
            <ScrollableTabsButtonPrevent
                value={aktualViewSection}
                handleChange={(newValue) => setAktualViewSection(newValue)}
                dataLookup={dataLookup}
            />
        );
   
}

export default ViewSectionComponent;