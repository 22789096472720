import * as React from 'react';
import { DemoItem } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import dayjs from 'dayjs';
import { createTheme, ThemeProvider } from '@mui/material/styles';

export default function DateTimne1({ param, selectedDates, setSelectedDates, setIsChanged }) {
  const [cleared, setCleared] = React.useState(false);
  const theme = createTheme({
    palette: {
      primary: {
        main: '#1C80BC', // Modrá barva pro primární barvu
      },
      // Případně můžete přizpůsobit další barvy
    },
  });

  React.useEffect(() => {
    if (cleared) {
      const timeout = setTimeout(() => {
        setCleared(false);
      }, 1500);

      return () => clearTimeout(timeout);
    }
    return () => {};
  }, [cleared]);

  const selectedDate = selectedDates?.[param.id] || null;
  //console.log('v DateTime1 - selectedDate', selectedDate, param.id, param.vizu);
  
    const handleDateChange = (val) => {
      const dateValue = val ? val.toDate() : null;
      setSelectedDates(prevValues => ({
        ...prevValues,
        [param.id]: dateValue,
      }));
      setIsChanged(true);
    };
  

    return (
      <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            margin: '10px 0 20px 0',
            position: 'relative',
             '& .MuiOutlinedInput-root': {
                '&:hover fieldset': {
                  borderColor: 'primary.main', // Změna barvy borderu při najetí myši
                },
              },     
          }}
        >
          {param.input === 'dateTime' ? (
                <DateTimePicker
                    label={param.vizu}
                    sx={{ 
                        width: 260,
                        height: 70,
                    }}
                    value={selectedDate === null ? null : dayjs(selectedDate)}
                    onChange={handleDateChange}
                    format="DD.MM.YYYY HH:mm"
                    ampm={false}
                    emptyLabel="Smazáno"
                    slotProps={{
                        textField: {
                          sx: {
                            width: '300px',
                            '.MuiInputBase-input': {
                              height: '10px',
                            },
                          },
                          clearable: true,
                          onClear: () => { 
                            setCleared(true); 
                            setSelectedDates(prevValues => ({
                              ...prevValues,
                              [param.id]: null,
                            }));
                            setIsChanged(true); 
                          },
                        },
                    }}
                />
            ) : param.input === 'date' ? (
                <DatePicker
                    label={param.vizu}
                    sx={{ 
                        width: 260,
                        height: 70,
                    }}
                    value={selectedDate === null ? null : dayjs(selectedDate)}
                    onChange={handleDateChange}
                    format="DD.MM.YYYY"
                    emptyLabel="Smazáno"
                    slotProps={{
                        textField: {
                          sx: {
                            width: '300px',
                            '.MuiInputBase-input': {
                              height: '10px',
                            },
                          },
                          clearable: true,
                          onClear: () => { 
                            setCleared(true); 
                            setSelectedDates(prevValues => ({
                              ...prevValues,
                              [param.id]: null,
                            }));
                            setIsChanged(true); 
                          },
                        },
                    }}
                />
            ) : null
          }
  
          {cleared && (
            <Alert
              sx={{ position: 'absolute', bottom: 0, right: 0 }}
              severity="success"
            >
              Smazáno!
            </Alert>
          )}
        </Box>
      </LocalizationProvider>
      </ThemeProvider>
    );
}

/*

import * as React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import dayjs from 'dayjs';

export default function ClearableProp({ param, selectedDates, setSelectedDates, setIsChanged }) {
  const [cleared, setCleared] = React.useState(false);

  // ... vaše useEffect a ostatní kód zůstává stejný ...

  const selectedDate = selectedDates?.[param.id];

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          position: 'relative',
        }}
      >
        <DateTimePicker
          label={param.vizu}
          value={selectedDate === null ? null : dayjs(selectedDate)}
          onChange={handleDateChange}
          ampm={false}
          inputFormat="DD.MM.YYYY HH:mm"
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{ width: 260 }}
              // Zde můžete přidat další vlastnosti TextField
            />
          )}
        />

        {cleared && (
          <Alert
            sx={{ position: 'absolute', bottom: 0, right: 0 }}
            severity="success"
          >
            Field cleared!
          </Alert>
        )}
      </Box>
    </LocalizationProvider>
  );
}
*/