export const dataInputDefault1 = (inputName, value1, paramsDefault, dataDefault, paramsL, zdroj, val) => { //plnění defaultních parametrů daty
    //console.log('ve fci dataInputDefault1', inputName, value1);
    //console.log('ve fci dataInputDefault1 - dataDefault', dataDefault, 'paramsDefault', paramsDefault, 'paramsL', paramsL, 'zdroj', zdroj, 'val', val);
    // pole ridicich parametru v entite
    //console.log('v dataInputDefault1 - paramsL:', paramsL);
    const ridiciParams = paramsL 
        .filter(obj => obj.ridiciParametr) // jen neprazdne
        .map(obj => obj.ridiciParametr) // jen hodnoty v ridiciParametr
        .filter((value, index, self) => self.indexOf(value) === index); // odstrani duplicity
    //console.log('v dataInputDefault1 - ridiciParams:', ridiciParams);

    let ridiciParamNeHlav = [];
    for (let ridiciParam of ridiciParams) {
        if (paramsL.find(obj => obj.parametr === ridiciParam && obj.ridiciParametr !== null))
            ridiciParamNeHlav.push(ridiciParam);
    }
    //console.log('v dataInputDefault1 - pole ridiciParamNeHlav:', ridiciParamNeHlav)    
    
    let inputDef = {};
    
    try {
        let inputDef2X2 = {};
        
        const inputDef2X1 = inputDef2(inputName, value1, val)
        console.log('x1x1x1x1x v dataInputDefault1 - hodnota ridiciho param:', value1, 'inputDef2X1: ', inputDef2X1);
        for (let ridiciParam of ridiciParamNeHlav) { // projedu vsechny ridici parametry v entite

            for (let [key, value] of Object.entries(inputDef2X1)) {
                //console.log('kontrola key', key)
                const parametrX = paramsL.find(obj => obj.id == key)?.parametr
                //console.log('kontrola', parametrX, ridiciParam)
                if(ridiciParam === parametrX){
                    const ridiciParamId = paramsL.find(obj => obj.parametr === ridiciParam)?.id
                    const valueRP = inputDef2X1[ridiciParamId]
                    //console.log('xxxxxx v dataInputDefault1 - ridiciParam, valueRP ', ridiciParam, valueRP);
                    const inputDefF = inputDef2(ridiciParam, valueRP)
                    //console.log('x2x2x2x2x v dataInputDefault1 - jednotliva pole ', inputDefF);
                    inputDef2X2 = { ...inputDef2X2, ...(inputDefF || {})};
                    console.log('x3x3x3x3x v dataInputDefault1 - ridiciParamNeHlav ', inputDef2X2);
                }

            }
            
        }

        inputDef = { ...inputDef2X1, ...inputDef2X2 };


    } catch (error) {
        console.error(error);
    }
        
   
       
    //ridiciParamsVporadi.push({ poradi: 1, ridiciParametr: ridiciParam });
    function inputDef2(ridiciParam, value, val) {
        //console.log('ve fci inputDef2, vstupni param ridiciParam:', ridiciParam, 'value:', value)
        const paramWithZdroj = paramsDefault.find(obj => obj.ridiciParametr === ridiciParam && obj.zdroj);
        //console.log('ve fci inputDef2,paramWithZdroj je:', paramWithZdroj);
        let vystup = {};
        if (paramWithZdroj) { // kdyz se plni jen lookup jednoho parametru (s dlouhym seznamem), ridiciParam chybi
            const zdrojTab = paramWithZdroj.zdroj; 
            const paramsDefaultAkt = paramsDefault.filter(obj => obj.ridiciParametr === ridiciParam) // vyfiltruji parametry typu default, kde je ridici par prave prochazeny

            //const zdrojTab = paramsDefault.find(obj => obj.ridiciParametr === ridiciParam).zdroj; // najdu zdrojovou tabulku
            const zdrojParRidPar = paramsDefault.find(obj => obj.ridiciParametr === ridiciParam).zdrojParRidicihoPar; // najdu jmeno zdrojoveho parametru v zdrojTab ridiciho par

            const dataZdroj = dataDefault.default[zdrojTab];

            //console.log('dataZdroj jsou:', dataZdroj, 'zdrojTab:', zdrojTab, 'zdrojParRidPar:', zdrojParRidPar);
            console.log('paramsDefaultAkt jsou:', paramsDefaultAkt);
            
            

            for (let defParAll of paramsDefaultAkt) {// prochazim parametry typu default, kde je ridici par prave nahore prochazeny
                const defPar = defParAll.parametr; // najdu jmeno zdrojoveho parametru v zdrojTab aktualne prochazeneho par
                const zdrojPar = defParAll.zdrojParametr; // najdu jmeno zdrojoveho parametru v zdrojTab aktualne prochazeneho par
                const idPar = defParAll.id; 

                //console.log('zdrojPar je:', zdrojPar);
                //console.log('ve fci inputDef2', 'input', input, 'idInput', idInput);
                let ridiciValue
                if(zdrojParRidPar !== 'id'){ ridiciValue = val} else (ridiciValue = value.toString())

                if (
                    dataZdroj &&
                    Array.isArray(dataZdroj) &&
                    zdrojParRidPar &&
                    ridiciValue !== undefined &&
                    zdrojPar
                ) {
                    
                    //console.log('podminka pro doplneni je splnena', 'dataZdroj', dataZdroj, 'zdrojParRidPar', zdrojParRidPar, 'ridiciValue', ridiciValue )
                    const foundItem = dataZdroj.find((z) => z[zdrojParRidPar] == ridiciValue);
                    //console.log('foundItem je:', foundItem);    
                    const inputX = foundItem ? foundItem[zdrojPar] : null;
                    //console.log('inputX je:', inputX);
                    vystup[defParAll.id] = inputX;
                    
                }
            }
            console.log('111111 vystup z inputDef2 je:', vystup);

        }

        return vystup;

    }

    return inputDef;
};

/*
for (let defParAll of paramsDefaultAkt) {// prochazim parametry typu default, kde je ridici par prave nahore prochazeny
                const defPar = defParAll.parametr; // najdu jmeno zdrojoveho parametru v zdrojTab aktualne prochazeneho par
                const zdrojPar = defParAll.zdrojParametr; // najdu jmeno zdrojoveho parametru v zdrojTab aktualne prochazeneho par
                //console.log('doplnuji parametr defPar:', defPar);
                //console.log('zdrojPar je:', zdrojPar);
                const input = document.getElementById(`input_${defPar}`); //zachytim element ktery budu plnit 
                //console.log('nalezen element:', input);
                if (input) { //vyhodnoti, zda byl element zachycen; kdyz je na jine detailSection, nezachyti se
                    const idInputx = input.getAttribute('id');
                    const idInput = idInputx.split("_")[1];
                    //console.log('ve fci inputDef2', 'input', input, 'idInput', idInput);
                    let ridiciValue
                    if(zdrojParRidPar !== 'id'){ ridiciValue = val} else (ridiciValue = value.toString())

                    if (
                        dataZdroj &&
                        Array.isArray(dataZdroj) &&
                        zdrojParRidPar &&
                        ridiciValue !== undefined &&
                        zdrojPar
                    ) {
                        
                        //console.log('podminka pro doplneni je splnena', 'dataZdroj', dataZdroj, 'zdrojParRidPar', zdrojParRidPar, 'ridiciValue', ridiciValue )
                        const foundItem = dataZdroj.find((z) => z[zdrojParRidPar] == ridiciValue);
                        //console.log('foundItem je:', foundItem);    
                        const inputX = foundItem ? foundItem[zdrojPar] : null;
                        console.log('inputX je:', inputX);
                        vystup[defParAll.id] = inputX;
                        input.textContent = inputX;
                        input.setAttribute('value', inputX);
                    }

                    
                }

            }
            */