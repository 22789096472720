import React, {useContext, useEffect} from 'react';
import styled from 'styled-components';
import { DetailDataContext } from '../store/detailDataContext'; // Import DetailDataProvider
import ScrollableTabsButtonPrevent from '../atoms/ScrollableTabsButtonPrevent';


function DetailSectionComponent({ dataLookup}) {
    //console.log('v DetailSectionComponent - dataLookup', dataLookup);
    let selectedIndex = 0;
    
    const dataLookupPlus = [
        ...dataLookup,
        'Úkoly'
    ]
    
    const {
        aktualDetailSection,
        setAktualDetailSection,
        detailDataNutne
    } = useContext(DetailDataContext); // Získání hodnot z kontextu
    

    // Nastavení výchozí hodnoty pro aktualDetailSection
    useEffect(() => {
        //console.log('000000 - v DetailSectionComponent - useEffect: ', aktualDetailSection);
        if (detailDataNutne && aktualDetailSection == null) {
            setAktualDetailSection(detailDataNutne?.detailSectionsGlob[0]);
        }
    }, [detailDataNutne, aktualDetailSection, setAktualDetailSection]);
    
    //console.log('v DetailSectionComponent - aktualDetailSection', aktualDetailSection);

    // Podmíněné renderování
    if (!detailDataNutne || aktualDetailSection == null) {
        return <div>Loading DetailSectionComponent...</div>;
    } else {
        return (
            <ScrollableTabsButtonPrevent    
                value={aktualDetailSection}
                handleChange={(newValue) => setAktualDetailSection(newValue)}
                dataLookup={dataLookupPlus}
            />
        );
    }
}

export default DetailSectionComponent;