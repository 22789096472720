import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
//import { GenContext } from '../store/genData';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import logo from '../img/enadoBlack.png';
import { Link } from 'react-router-dom';
import { Box } from '@mui/system';
import theme from '../theme';
import Alert from '@mui/material/Alert';


const Register = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const navigate = useNavigate();
    const [message, setMessage] = useState(null);
    const [severity, setSeverity] = useState(null);

    /*
    const isHeaderMenuVisible = useContext(GenContext).isHeaderMenuVisible;
    const setIsHeaderMenuVisible = useContext(GenContext).setIsHeaderMenuVisible;
    
    useEffect(() => {
        setIsHeaderMenuVisible(false);
    }, [setIsHeaderMenuVisible]);
    */

    const handleSubmit = async (event) => {
        event.preventDefault();
        const response = await fetch('/api/register', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ name, email, password })
        });
        const data = await response.json();
        console.log('registrace provedena - success: ', response.success);
        // Zde byste měli zpracovat odpověď (např. přesměrování, zobrazení chyby)
        if (response.ok) {
            setSeverity('success');
            setMessage(`Registrace proběhla úspěšně. Budete přesměrováni na přihlašovací stránku`);
            // Close the alert after 5 seconds
            setTimeout(() => {
                setMessage(null);
                navigate('/login'); // Přesměrování na hlavní stránku po úspěšném přihlášení
            }, 6000);
            console.log('registrace provedena - success: ', data.success);
        }else{
            setSeverity('error');
            setMessage('Registrace se nepovedla. Zkuste to prosím znovu');
            setTimeout(() => {
                setMessage(null);
            }, 6000);
            console.log('registrace se nepovedla - success: ', data.success);
        }
        

    };

    return (
        <Box
            sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                justifyContent: 'center', 
                alignItems: 'center', 
                height: '100vh', // Full viewport height
            }}
        >
            <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{
                    width: 360,
                    height: 500,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    bgcolor: 'background.paper',
                    boxShadow: 1,
                    borderRadius: 1,
                    p: 2,
                    
                }}
            >
                <Link to="/board-view">
                    <img src={logo} alt="Logo" width='98.8' height='43.0'/>
                </Link>
                <TextField
                    type="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    label="Jméno"
                    variant="outlined"
                    width="100%"
                    sx={{ margin: '20px', backgroundColor: 'transparent' }}
                    InputProps={{
                        style: { backgroundColor: 'transparent' },
                    }}
                />
                <TextField
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    label="Email"
                    variant="outlined"
                    width="100%"
                    sx={{ margin: '20px', backgroundColor: 'transparent' }}
                    InputProps={{
                        style: { backgroundColor: 'transparent' },
                    }}
                />
                <TextField
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    label="Heslo"
                    variant="outlined"
                    width="450px"
                    sx={{ margin: '20px', backgroundColor: 'transparent' }}
                    InputProps={{
                        style: { backgroundColor: 'transparent' },
                    }}
                />
                <Button
                type="submit" variant="contained" color="primary"
                sx={{ 
                    backgroundColor: theme.palette.secondary.dark,
                    fontWeight: 700,
                    margin: '20px',
                    '&:hover': {
                      backgroundColor: theme.palette.secondary.main, // Nastaví barvu pozadí při najetí myší na stejnou barvu jako je výchozí
                    },
                  }}
                >
                    Registrovat
                </Button>
                {message && (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <Alert variant="outlined" severity={severity} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 70, width: 350, overflow: 'auto', backgroundColor: 'white' }}>
                            {message}
                        </Alert>
                        </div>
                        )}
            </Box>
        </Box>
    );
};

export default Register;

