import { webhookEvent } from './apiExt';
import { checkTaskStatus} from './apiRest'; // Předpokládám, že tyto funkce jsou importovány z './api'


export const webhook = async (tableEntita, idUn, paramsL, inputRests) => {

    console.log('v webhook na zacatku - tableEntita: ', tableEntita, 'idUn: ', idUn, 'paramsL: ', paramsL);
    
    let ident = null;
    let dbPlace = null;
    
    
    if(tableEntita == 'ServisZasah'){
        console.log('11111111111111111 - volam webhookEvent pro ServisZasah');
        console.log('inputRests[\'455\'], Založit událost v kalendáři:', inputRests?.['455']); // bool parametr ktery ovlivnuje user
        console.log('inputRests[\'456\'], Poslat událost zákazníkovi:', inputRests?.['456']); // bool parametr ktery ovlivnuje user
        if (inputRests?.['456'] !== false || inputRests?.['455'] !== false) {
            dbPlace = paramsL.find((item) => item.tableEntita == tableEntita).zdrojDB;
            console.log('000000000000000 - v webhook - dbPlace: ', dbPlace, 'tableEntita: ', tableEntita, 'idUn: ', idUn);

            ident = 'GCMSEvent'
            try {
                const token = localStorage.getItem('token');
                const response1 = await webhookEvent(tableEntita, idUn, dbPlace, ident, token);
                const taskId1 = response1.taskId;
                const webhookRes = await checkTaskStatus(taskId1);
                console.log('webhookEvent dekoncen', webhookRes)
                return webhookRes
                } catch (error) {
                console.error(error);
            }
        }else{return { result: {success: 'NA', message: 'Zákazník NEDOSTANE oznámení o události' }}}
        

    }else if(tableEntita == 'RealizacniCinnost'){
        console.log('11111111111111111 - volam webhookEvent pro RealizacniCinnost');
        console.log('inputRests[\'2903\'], Založit událost v kalendáři:', inputRests?.['2903']); // bool parametr ktery ovlivnuje user
        console.log('inputRests[\'2904\'], Poslat událost zákazníkovi:', inputRests?.['2904']); // bool parametr ktery ovlivnuje user
        if (inputRests?.['2904'] || inputRests?.['2903']) {
            dbPlace = paramsL.find((item) => item.tableEntita == tableEntita).zdrojDB;
            console.log('000000000000000 - v webhook - dbPlace: ', dbPlace, 'tableEntita: ', tableEntita, 'idUn: ', idUn);

            ident = 'GCMSEvent'
            try {
                const token = localStorage.getItem('token');
                const response1 = await webhookEvent(tableEntita, idUn, dbPlace, ident, token);
                const taskId1 = response1.taskId;
                const webhookRes = await checkTaskStatus(taskId1);
                console.log('webhookEvent dekoncen', webhookRes)
                return webhookRes
                } catch (error) {
                console.error(error);
            }
        }else{return { result: {success: 'NA', message: 'Zákazník NEDOSTANE oznámení o události' }}}
        

    }else if(tableEntita == 'Ukol'){
        console.log('000000000 - volam webhookEvent pro Ukol', inputRests);
        console.log('inputRests[\'1660\']:', inputRests?.['1660']);
        if (inputRests?.['1660'] !== false) {
            console.log('11111111111111111 - volam webhookEvent pro Ukol');
            dbPlace = 'Intranet'
            console.log('000000000000000 - v webhook - dbPlace: ', dbPlace, 'tableEntita: ', tableEntita, 'idUn: ', idUn);

            ident = 'UkolEmail'
            try {
                const token = localStorage.getItem('token');
                const response1 = await webhookEvent(tableEntita, idUn, dbPlace, ident, token);
                const taskId1 = response1.taskId;
                const webhookRes = await checkTaskStatus(taskId1);
                console.log('webhookEvent dekoncen', webhookRes)
                return webhookRes
                } catch (error) {
                console.error(error);
            }
            
        }else{
            return { result: {success: 'NA', message: 'Uživatel NEDOSTANE oznámení emailem' }}
        }
        
    }
    else{
        return { result: {success: 'NA', message: 'Entita nespuští žádný další proces' }}
    }

}
