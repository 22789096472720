import React, {useContext} from 'react';
import { Select } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { DataContext } from '../store/baseData';

export default function Lookup({ label, lookupList, inputLookup, handleEntityClick }) {
    //console.log('Lookup', 'label:', label, 'lookupList:', lookupList, 'inputLookup:', inputLookup);


    //const { sectionsGlob, structureGlob, contactsGlob, ciselnikGlob, setCiselnikGlob, procesAll, isHeaderMenuVisible, setIsHeaderMenuVisible, aktualViewSection, setAktualViewSection, paramsLAllAll, setParamsAllAll } = useContext(DataContext);

    return (
      <Select
                labelId={`input_${label}`}
                id={`input_${label}`}
                value={inputLookup}
                label={label}
                onChange={(event) => handleEntityClick(event.target.value)}
                style={{
                    margin: '0px 0 10px 0',
                    height: '40px',
                    width: '380px',
                    fontSize: '16px',
                    textAlign: 'center',
                }}
         >
                {Array.isArray(lookupList) && lookupList.map((item, index) => (
                    <MenuItem key={index} value={item}>{item}</MenuItem>
                ))}
            </Select>
    );
  }