// V souboru boardView.js
import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { DataContext } from '../store/baseData';
import { GenContext } from '../store/genData';
import SectionComponent from '../molecules/SectionComponent';

import { styled } from '@mui/material/styles';
import { Grid, Paper } from '@mui/material';
import { Box } from '@mui/system';
import LinearIndeterminate from '../atoms/LinearIndeterminate';


const Item = styled(Paper)(({ theme }) => ({
    
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.primary,
    boxShadow: 'none',
    display: 'flex',
    justifyContent: 'center',
    margin: '10px',
    
}));


const BoardView = () => {
    const navigate = useNavigate(); // Inicializace useNavigate
    const [entityTitle, setEntityTitle] = useState('Načítám data...');
    const { sectionsGlob, structureGlob, contactsGlob, ciselnikGlob, paramsLAllAll } = useContext(DataContext);
    const isHeaderMenuVisible = useContext(GenContext).isHeaderMenuVisible;
    //console.log('isHeaderMenuVisible v login.jsx: ', isHeaderMenuVisible);
    const setIsHeaderMenuVisible = useContext(GenContext).setIsHeaderMenuVisible;
    useEffect(() => {
        setIsHeaderMenuVisible(true);
    }, [setIsHeaderMenuVisible]);

    //console.log('sectionsGlob', sectionsGlob);
    //console.log('structureGlob', structureGlob);
    const favicon = '/enadoSlunicko.png'
    
    const handleEntityClick = (tableEntita, entita, page) => {
        // Implementace navigace
        // Můžete použít 'useNavigate' z 'react-router-dom' nebo jiný způsob navigace
        navigate(`/list-view?tableEntita=${tableEntita}&entita=${entita}&page=${page}`);
    };

    console.log('paramsLAllAll', paramsLAllAll);
    console.log('sectionsGlob', sectionsGlob);
    console.log('structureGlob', structureGlob);
    if (!paramsLAllAll.length || !sectionsGlob.length || !structureGlob.length ) {
        return <LinearIndeterminate/>
    } else {    
        return (
            <Box sx={{ position: 'relative' }}>
                <Box display="flex" flexWrap="wrap" sx={{ overflow: 'auto', height: '90vh' }}>
                    {sectionsGlob.map((section, index) => (
                    <Box
                        sx={{ width: { xs: '100%', sm: '220px' }, flexShrink: 0, padding: '0px' }}
                        key={index}
                    >
                        <Item >
                            <SectionComponent 
                            sectionGlob={section} 
                            structureGlob={structureGlob} 
                            handleEntityClick={handleEntityClick} 
                            />
                        </Item>
                    </Box>
                    ))}
                    
                    
                </Box>
                
            </Box>
        );
    }
};

export default BoardView;
