

export const webhookEvent = async (table, idUn, dbPlace, ident, token) => {
    const response = await fetch('/webhookIntranet', {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            table: table,
            idUn: idUn,
            dbPlace: dbPlace,
            ident: ident
        })
    });
    /*
    if (!response.ok) {
        return response.text().then(error => {
        throw new Error(error);
    });
    }
    */
    if (!response.ok) {
        const errorData = await response.json();
        if (errorData.redirect) {
            window.location.href = errorData.redirect;
        } else {
            const errorText = JSON.stringify(errorData);
            throw new Error(errorText);
        }
    }
    return response.json();
};
