// Header.js
import React, { useState, useContext } from 'react';
import { GenContext } from '../store/genData';
import PrimarySearchAppBar from '../atoms/PrimarySearchAppBar';


const Header = () => {
    const isHeaderMenuVisible = useContext(GenContext).isHeaderMenuVisible;
    const setIsHeaderMenuVisible = useContext(GenContext).setIsHeaderMenuVisible;
    

    return (
        <header className="header-container" >
            
            {isHeaderMenuVisible && <PrimarySearchAppBar />}
        </header>
    );
};

export default Header;
