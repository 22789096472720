import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
//import { GenContext } from '../store/genData';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import logo from '../img/enadoBlack.png';
import { Link } from 'react-router-dom';
import { Box } from '@mui/system';
import theme from '../theme';
import Alert from '@mui/material/Alert';


const Login = () => {
    console.log('v login.jsx');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const [message, setMessage] = useState(null);
    const [severity, setSeverity] = useState(null);
   
    
    /*
    const isHeaderMenuVisible = useContext(GenContext).isHeaderMenuVisible;
    //console.log('isHeaderMenuVisible v login.jsx: ', isHeaderMenuVisible);
    const setIsHeaderMenuVisible = useContext(GenContext).setIsHeaderMenuVisible;
    const { setUserEmail } = useContext(GenContext);
    
    useEffect(() => {
        setIsHeaderMenuVisible(false);
    }, [setIsHeaderMenuVisible]);
    */
    

    const handleSubmit = async (event) => {
        event.preventDefault();
        const response = await fetch('/api/login', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email, password })
        });
        console.log('v login - response: ', response);
                    
        if(!response.ok){ //var1 a var2 jsou false
            console.log('prihlaseni se nepovedlo: ', response);
            console.log('nastal session problem na serveru, user bude odhlasen ', response.body);
            setSeverity('error');
            setMessage('Neplatný email nebo heslo. Zkuste to prosím znovu');
            setTimeout(() => {
                setMessage(null);
                navigate('/login');
            }, 1000);
            
        }else {
            const data = await response.json();
            console.log('prijata datat po prihlaseni: ', data);
            console.log(`v login - ${data.message} `);
            localStorage.setItem('token', data.token); // Uložení JWT do localStorage
            localStorage.setItem('userEmail', data.email);
            setSeverity(data.severity);
            setMessage(data.message);
            setTimeout(() => {
            setMessage(null);
                navigate(data.navigate); // Přesměrování na custom stránku po úspěšném přihlášení
            }, 1000);


        }
        
        
    };

    return (
        <Box
            sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                justifyContent: 'center', 
                alignItems: 'center', 
                height: '100vh', // Full viewport height
            }}
        >
            <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{
                    width: 360,
                    height: 400,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    bgcolor: 'background.paper',
                    boxShadow: 1,
                    borderRadius: 1,
                    p: 2,
                    
                }}
            >
                <Link to="/board-view">
                    <img src={logo} alt="Logo" width='98.8' height='43.0'/>
                </Link>
                <TextField
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    label="Email"
                    variant="outlined"
                    width="100%"
                    sx={{ margin: '20px', backgroundColor: 'transparent' }}
                    InputProps={{
                        style: { backgroundColor: 'transparent' },
                    }}
                />
                <TextField
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    label="Heslo"
                    variant="outlined"
                    width="450px"
                    sx={{ margin: '20px', backgroundColor: 'transparent' }}
                    InputProps={{
                        style: { backgroundColor: 'transparent' },
                    }}
                />
                <Button
                type="submit" variant="contained" color="primary"
                sx={{ 
                    backgroundColor: theme.palette.secondary.dark,
                    fontWeight: 700,
                    margin: '20px',
                    '&:hover': {
                      backgroundColor: theme.palette.secondary.main, // Nastaví barvu pozadí při najetí myší na stejnou barvu jako je výchozí
                    },
                  }}
                >
                    Přihlásit
                </Button>
                {message && (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <Alert variant="outlined" severity={severity} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'auto', width: 350, overflow: 'auto', backgroundColor: 'white' }}>
                            {message}
                        </Alert>
                        </div>
                        )}
            </Box>
        </Box>
    );
};

export default Login;

