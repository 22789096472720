//LookupComponent.jsx
import React, { useState, useContext, useEffect } from 'react';
import { DetailDataContext } from '../store/detailDataContext';
import { useLocation, useNavigate } from 'react-router-dom';
import DataTable from '../organisms/poleTable1'; // Importujte komponentu DataTable
import { addItemPole } from '../api/apiRest';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Box, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { paramsLLimit } from '../api/apiRest';
import { DataContext } from '../store/baseData';


const PoleComponent = ({ param, placementRef }) => {

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down(900));

    
     
    const {
        tableEntita,
        page,
        idUn,
        entita,
        detailDataNutne,
        detailDataRest,
                
    } = useContext(DetailDataContext); // Získání hodnot z kontextu

    const {  paramsLAllAll } = useContext(DataContext);

    const [headersParam, setHeadersParam] = useState(''); // definice stavu
    const [headers, setHeaders] = useState([]); // hlavicka tabulky
    const [dataZdroj, setDataZdroj] = useState(''); 
    const [mjs, setMjs] = useState([]); // jednotky
    const [inputFormat, setInputFormat] = useState([]); // format vstupu [date, number
    const rowData = detailDataNutne.dataGlob[0]; // Získání dat z detailDataContext
    const paramsL = detailDataNutne.paramsLGlob; // Získání dat z detailDataContext
    const zdrojPar = param.zdrojParametr;
    const zdrojParRidicihoPar = param.zdrojParRidicihoPar;
    const header = param.parametr;
    const zdroj = param.zdroj;
    const dataEntitaListGlob = detailDataRest?.dataEntitaListGlob; // Získání dat z detailDataContext
    const pole = 'pole';
    console.log('v PoleComponent - detailDataRest', detailDataRest, 'detailDataNutne', detailDataNutne);
    
   /*
    const columns = matches 
        ? headersParam.slice(0, 3) // Zobrazit pouze první tři sloupce, pokud je šířka rodičovského prvku menší než 900px
        : headersParam; // Jinak zobrazit všechny sloupce
    
    */
    //const columns = headersParam.slice(0, 3);
    //console.log('v PoleComponent - dataEntitaListGlob', dataEntitaListGlob);
    //const [druh, setDruh] = useState(rowData[header] || '');
    
    //console.log('v LookupComponent - placementRef', placementRef);
    //console.log('v LookupComponent - paramArray', paramArray, 'param', param, 'param.zdrojParametr', param.zdrojParametr, 'param.ridiciParametr', param.ridiciParametr);
    //console.log('v LookupComponent - dataDefaultGlob', dataDefaultGlob.default, zdrojParRidicihoPar, zdrojPar);
    
// paramsL[j].zdroj, header, dataEntitaListGlob, paramsL[j].zdrojParametr, placement, 'pole', tableEntita, rowData.id
//createDefault(paramsL[j], header, dataDefaultGlobOne, placement, paramsL[j].zdroj, paramsL[j].zdrojParametr);

    useEffect(() => {
        if(detailDataRest && paramsL && paramsLAllAll){
            //console.log('v PoleComponent - useEffect - dataEntitaListGlob', dataEntitaListGlob['data'][pole][zdroj], 'paramsL', paramsL);
            let dataZdrojRes
            if (pole == 'pole') {
                dataZdrojRes = dataEntitaListGlob['data'][pole][zdroj]
            } else {
                dataZdrojRes = dataEntitaListGlob['data']
            }
            //console.log('data listovane entity jsou ', dataZdrojRes)
            const tableEntitaItem = zdroj; // pozor tableEtnitu nahrazuji jmenem zdrojove tabulky
            
            
            //const headersParamRes = dataZdrojRes && dataZdrojRes[0] ? Object.keys(dataZdrojRes[0]) : [];
            console.log('v PoleComponent - paramsLAllAll', paramsLAllAll, 'param.zdroj', param.zdroj);
            /*
            const filteredParams = paramsLAllAll.filter(item => item.tableEntita.includes(param.zdroj));
            const headersParamRes = paramsLAllAll.filter(item => item.tableEntita.includes(param.zdroj)).map(item => item.parametr);
            const headersRes = filteredParams.map(item => item.vizu);
            */
            const filteredParams = paramsLAllAll.filter(item => item.tableEntita && item.tableEntita.includes(param.zdroj));
            console.log('v PoleComponent - filteredParams', filteredParams);
            const headersParamRes = paramsLAllAll.filter(item => item.tableEntita && item.tableEntita.includes(param.zdroj)).map(item => item.parametr);
            const headersRes = filteredParams.map(item => item.vizu);

            //const headersRes = paramsL.filter(item => item.parametr === headersParamRes).map(objekt => objekt.vizu);
            const mjsRes = filteredParams.map(item => item.mj);

            const inputFormatRes = filteredParams.map(item => item.format);
            
            console.log('v PoleComponent - filteredParams', filteredParams);
            console.log('v PoleComponent - dataZdrojRes', dataZdrojRes); // id: 18, name: 'Nepoint Prevence v místě dle SS', popisPrace: null, dateZasah: null, idServis: 24
            console.log('v PoleComponent - headersParamRes', headersParamRes); // 'id', 'name', 'popisPrace', 'dateZasah', 'idServis', 'nameServis',
            console.log('v PoleComponent - headersRes', headersRes); // id Servisního případu', 'Název servisního případu', 'id Obchodního případu Raynet', 'id OPB', 'Název OPB
            console.log('v PoleComponent - mjsRes', mjsRes);
            console.log('v PoleComponent - inputFormatRes', inputFormatRes);

            const headersParamResLimit = headersParamRes.slice(0, 6);
            const headersResLimit = headersRes.slice(0, 6);
            const mjsResLimit = mjsRes.slice(0, 6);
            const inputFormatResLimit = inputFormatRes.slice(0, 6);

            setHeadersParam(headersParamResLimit);
            setDataZdroj(dataZdrojRes);

            setHeaders(headersResLimit);
            setMjs(mjsResLimit);
            setInputFormat(inputFormatResLimit);
            
        }
        //console.log('v PoleComponent - po fetchData', 'tableEntita', tableEntita, 'page', page, 'dataZdroj', dataZdroj, 'headersParam', headersParam);
        
    }, [param, placementRef, detailDataRest, paramsLAllAll]);
    
    // Funkce pro přesměrování na detail entity
    const handleEntityClick = (tableEntita, entita, page, id) => {
        //console.log('v PoleComponent - handleEntityClick', zdroj, page, id);
        window.open(`/detail-view?tableEntita=${zdroj}&idUn=${id}&entita=${zdroj}&page=${page}`, '_blank');
        //?view=detailView&tableEntita=${tableEntitaItem}&idUn=${record[header]}&entita=${tableEntitaItem}&page=intranet`
       
    };
    const tableEntitaItem = zdroj; // pozor tableEtnitu nahrazuji jmenem zdrojove tabulky
    const paramLink = `id${tableEntita}`
    const idParEnt = rowData.id;
    

    const handleAddItemPole = async () => {
        console.log('1111111111111111 addItemPole called');
        try {
            const token = localStorage.getItem('token');
            const newId = await addItemPole(tableEntitaItem, paramLink, idParEnt, token);
            //console.log('zalozena polozka s id: ', newId)
            window.open(`/detail-view?tableEntita=${tableEntitaItem}&idUn=${newId}&entita=${tableEntitaItem}&page=intranet`, '_blank')
        } catch (error) {
            console.log(`Error: ${error.message}`);
        }

    }

    //console.log('v PoleComponent - dataZdroj', dataZdroj, 'headersParam', headersParam, 'columns', columns);
    
    if (!detailDataNutne || !dataZdroj || !headers || !headersParam){
        return <div>Loading...</div>;
    }else{
        return (
            <div role="document" id="doc-list-id-Projekce" className="doc-entitaList" style={{ width: '560px', cursor: 'default' }}>
                <Box className="AddItemIcon">
                <IconButton
                            onClick={handleAddItemPole}
                            sx={{ 
                                margin: '15px',
                                backgroundColor: theme.palette.secondary.dark,
                                fontWeight: 700,
                                '&:hover': {
                                    backgroundColor: theme.palette.secondary.main,
                                },
                            }}
                        >
                            <AddIcon
                                sx={{ 
                                    color: 'white', 
                                    fontSize: '22px'
                                }}
                            />
                        </IconButton>
                
                </Box>

                <Box className="tab-hledat" id="tab-hledat" sx={{ width: 560 }}>


                    <DataTable 
                        tableEntita={tableEntita} 
                        pageP={page} 
                        data={dataZdroj} 
                        headers={headers} 
                        columnAccessors={headersParam} 
                        onRowClick={handleEntityClick}
                        mjs={mjs}
                        inputFormat={inputFormat}
                    />
                    
                    </Box>
                               
                
            </div>
        );

    }
    
};

export default PoleComponent;
