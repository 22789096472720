import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

export default function ScrollableTabsButtonPrevent({ value, handleChange, dataLookup }) {
  return (
    <Box sx={{
      width: '100%',
      bgcolor: 'background.paper',
      marginBottom:'10px' }
      }>
      <Tabs
        value={value}
        onChange={(event, newValue) => handleChange(newValue)}
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
        aria-label="scrollable auto tabs example"
        TabIndicatorProps={{ style: { backgroundColor: '#1C80BC' } }} // Aktivní záložka s podtržením
              >
        {dataLookup.map((lookup) => (
          <Tab
            key={lookup}
            label={lookup}
            value={lookup} // Používáme hodnotu lookup (label) namísto indexu
            sx={{ 
              boxShadow: 'none', 
              backgroundColor: 'transparent', 
              '&.Mui-selected': { color: 'inherit' }, // Barva textu vybrané záložky je nastavena na původní hodnotu
              fontSize: '12px', // Menší velikost písma
              fontWeight: 'bold' // Větší tloušťka písma
            }}
            
          />
        ))}
      </Tabs>
    </Box>
  );
}

