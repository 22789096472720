import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

export default function SimpleContainer({ children }) {
    return (
      <React.Fragment>
        <CssBaseline />
        <Container maxWidth="false">
          <Box sx={{ height: '100%', width: '100%', padding: 0, margin: 0 }}>
            {children}
          </Box>
        </Container>
      </React.Fragment>
    );
  }