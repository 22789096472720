// V souboru detailView.jsx
import React, { useState, useEffect, useContext, useRef, createRef } from 'react';
import { useNavigate } from 'react-router-dom';
//import { detailData } from '../api/detailData';
//import DetailRenderInput from '../organisms/detailRenderInput';
import PlacementComponent from '../molecules/PlacementComponent';
import DetailSectionComponent from '../molecules/DetailSectionComponent';
import { DataContext } from '../store/baseData';
import { GenContext } from '../store/genData';
import { DetailDataContext } from '../store/detailDataContext'; // Import DetailDataProvider
import { styled } from '@mui/material/styles';
import { is } from 'date-fns/locale';
import { save } from '../api/save';
import { toast } from 'react-toastify';
import { theme } from '../theme';
import { Button as MuiButton } from '@mui/material';
import Button from '@mui/material/Button';
import SplitButton from '../atoms/SplitButton';
import SimpleContainer from '../atoms/SimpleContainer';
import ScrollableTabsButtonPrevent from '../atoms/ScrollableTabsButtonPrevent';
import HlavaTextFields from '../atoms/HlavaTextFields';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import  LinearIndeterminate from '../atoms/LinearIndeterminate';
import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import { addItem } from '../api/apiRest';
import Alert from '@mui/material/Alert';


const Container1 = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
}));



const CustomPage = () => {
    const userEmail = localStorage.getItem('userEmail');
    const divbtnsRef = useRef(null);
    const [entityTitle, setEntityTitle] = useState('Načítám data...');
    const [placementRefs, setPlacementRefs] = useState({});
    const [message, setMessage] = useState(null);
    const [severity, setSeverity] = useState('success');
    const { sectionsGlob, structureGlob, contactsGlob, ciselnikGlob} = useContext(DataContext);
    const isHeaderMenuVisible = useContext(GenContext).isHeaderMenuVisible;
    const setIsHeaderMenuVisible = useContext(GenContext).setIsHeaderMenuVisible;
    useEffect(() => {
        setIsHeaderMenuVisible(true);
    }, [setIsHeaderMenuVisible]);
    console.log('userEmail v detailView: ', userEmail);

    const navigate = useNavigate(); // Inicializace useNavigate
    const theme = useTheme();
    
            
    const {
        saveSuccessful,
        setSaveSuccessful,
        tableEntita,
        page,
        idUn,
        entita,
        aktualDetailSection,
        detailData,
        inputDefaults,
        inputEditLongs,
        inputRests,
        inputLookups,
        inputFiles,
        selectedDates,
        isChanged,
        setIsChanged,
        inputsAll,
        setInputsAll
    } = useContext(DetailDataContext); // Získání hodnot z kontextu


    const data = detailData; // Získání dat z detailDataContext
    const rowData = detailData?.dataGlob[0]; // Získání dat z detailDataContext
    const paramsL = detailData?.paramsLGlob; // Získání dat z detailDataContext
    const headers = detailData?.headers; // Získání dat z detailDataContext
    const dataSablona = detailData?.sablonaListGlob; // Získání dat z detailDataContext
    const detailSectionsGlob = detailData?.detailSectionsGlob; // Získání dat z detailDataContext
    const dataDefaultGlob = detailData?.dataDefaultGlob; // Získání dat z detailDataContext
    //console.log('tableEntita', tableEntita, 'page', page, 'idUn', idUn, 'entita', entita, 'aktualDetailSection', aktualDetailSection);
    
   
    
    useEffect(() => {
        
        setInputsAll({ 
            ...(inputDefaults || {}), 
            ...(inputEditLongs || {}), 
            ...(inputLookups || {}), 
            ...(inputRests || {}), 
            //...(inputFiles || {}), 
            //...(selectedDates || {}) 
        });
        //console.log('111111 v DetailView - inputsALL', inputsAll);
        //console.log('111111 v DetailView - k ulozeni?', isChanged);
    }, [inputDefaults, inputEditLongs, inputLookups, inputFiles, selectedDates, inputRests, isChanged]);
    
    
// Tento kód projde všechny refy v newPlacementRefs, získá jejich aktuální hodnoty a uloží je do objektu data. Poté můžete data uložit jak potřebujete.
    async function handleSave() {
        
        try {
            console.log('v detailView - handleSave - kliknuto');
            console.log('v detailView - handleSave - shromazdena data z cele detailView - inputsAll', inputsAll);
            console.log('v detailView - handleSave - shromazdena data z cele detailView - inputFiles', inputFiles);
            console.log('v detailView - handleSave - shromazdena data z cele detailView - selectedDates', selectedDates);
            setIsChanged(false)
            const saved = await save(tableEntita, idUn, inputsAll, paramsL, inputFiles, selectedDates);
            console.log(saved)
            if (saved.error) {
                console.error('Error: ', saved.error);
                setMessage(saved.error);
                setSeverity('error');
            } else {
                console.log('kontrola: ', saved)
                setMessage(`Uloženo ${saved.result}`);
                setSeverity('success');
                setSaveSuccessful(true);
                console.log('v detailView - prepl jsem saveSuccessful', saveSuccessful)
            }

            // Close the alert after 5 seconds
            setTimeout(() => {
                setMessage(null);
            }, 5000);

        } catch (error) {
            console.error(`Error: ${error.message}`);
            console.log('nastal session problem na serveru, user bude odhlasen ', error.message);
            if (error.message.includes('/logout')) {
                window.location.href = '/logout';
            }
        }
      
    }

    async function handleAddItem() {
        
        try {
            console.log('v detailView - addItem - kliknuto', tableEntita);
           
            const saved = await addItem(tableEntita);
            console.log('v detailView - addItem - saved')

            if (saved.error) {
                console.error('Error: ', saved.error);
                setMessage(saved.error);
                setSeverity('error');
            } else {
                console.log('kontrola: ', saved);
                //setMessage(`Založena nová položka ${saved}`);
                setSeverity('success');
                console.log(`/detail-view?tableEntita=${tableEntita}&idUn=${saved}&entita=${tableEntita}&page=${page}`);
                window.open(`/detail-view?tableEntita=${tableEntita}&idUn=${saved}&entita=${tableEntita}&page=${page}`, '_blank');
            }
             // Close the alert after 5 seconds
             setTimeout(() => {
                setMessage(null);
            }, 5000);
           
        } catch (error) {
            console.error(`Error: ${error.message}`);
            setMessage(error.message);
            setSeverity('error');
        }
      
    }

    const handleEntityClick = () => {
        // Implementace navigace
        // Můžete použít 'useNavigate' z 'react-router-dom' nebo jiný způsob navigace
        navigate(`/list-view?tableEntita=${tableEntita}&entita=${entita}&page=${page}`);
    };

    
    
    const favicon = '/enadoSlunicko.png'

    useEffect(() => {
        
    }, []);

    useEffect(() => {
        if (data) {
            //console.log('000000 - v DetailView - useEffect: ', rowData.name);
            //console.log('v detailView - detailData', detailData);   
            const entityTitleResult = 'Zákazník - ' + userEmail + ' - ' + data.structurEntGlob[0].entita + ' - ' + rowData.id + ' - ' + rowData.name;
   
            const newPlacementRefs = {}; //Vytvoří prázdný objekt newPlacementRefs. Do tohoto objektu se budou ukládat reference na jednotlivé placementy.
            let headIndex = 0; //Inkrementuje hodnotu headIndex o jedna v každé iteraci hlavního cyklu
            for (let header of headers) {
                headIndex++;
                for (let j = 0; j < paramsL.length; j++) {
                    if (paramsL[j].parametr == header) {
                        const placementy = paramsL[j].idPlacement; //ytvoří konstantu placementy, která je nastavena na hodnotu idPlacement aktuálního prvku v poli paramsL.
                        newPlacementRefs[placementy] = createRef(); //Vytvoří novou referenci pomocí React.createRef() a přiřadí ji do objektu newPlacementRefs pod klíčem placementy
                    }
                }
            }

            setEntityTitle(entityTitleResult);
            setPlacementRefs(newPlacementRefs);

            document.title = entityTitle;
            // Set favicon
            const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
            link.type = 'image/x-icon';
            link.rel = 'shortcut icon';
            link.href = favicon;
            document.getElementsByTagName('head')[0].appendChild(link);


        }
        

        if (page !== 'intranet') {
            if (divbtnsRef.current) {
                divbtnsRef.current.setAttribute('hidden', 'true');
            }
        }

        

        
    }, [data, favicon, entityTitle]);

       
    function ButtonFce({ isChanged, handleClick, children }) {
        return (
            <Button
                variant="contained"
                type="button"
                sx={{ 
                    fontWeight: 700,
                    height: '34px',
                    ':hover': {
                        backgroundColor: theme.palette.secondary.main
                    },
                    backgroundColor: theme.palette.secondary.dark,
                    margin: theme.spacing(2), // Nastaví odstup na všechny strany tlačítka
                    
                }}
                style={{ backgroundColor: isChanged ? theme.palette.secondary.dark : theme.palette.primary.main }} // Použijte barvy z theme
                onClick={handleClick}
            >
                {children}
            </Button>
        );
    }
   

        // In your render function
    if (!data) {
        return <LinearIndeterminate/>
        
    } else {
        //console.log('vypis ziskanych parametru: ', 'XaktualDetailSection', data?.XaktualDetailSection, 'structurEntGlob', data?.structurEntGlob, 'data1', data?.dataGlob, 'paramsL', data?.paramsLGlob, 'detailSectionsGlob', data?.detailSectionsGlob, 'dataEntitaListGlob', data?.dataEntitaListGlob, 'sablonaListGlob', data?.sablonaListGlob, 'headers', data?.headers);
        return (
            <SimpleContainer>
              <Box 
                    sx={{ 
                        display: 'flex', 
                        flexDirection: { xs: 'column', md: 'row' }, 
                        justifyContent: 'center', 
                        alignItems: 'center',
                        borderBottom: `1px solid ${theme.palette.secondary.dark}`,
                            '&:hover': {
                            borderBottom: `2px solid ${theme.palette.secondary.main}`,
                            },
                    }}
                    >
                    <Box 
                        sx={{ 
                        order: { xs: 2, md: 1 }, 
                        marginRight: { md: 2 }
                        }}
                    >
                        <Box 
                            sx={{ 
                                display: 'flex', 
                                justifyContent: 'space-between', 
                                alignItems: 'center',
                                width: '400px',
                            }}
                            >
                            <ButtonFce 
                                isChanged={isChanged} 
                                handleClick={handleSave}
                                
                            >
                                Uložit
                            </ButtonFce>
                            <IconButton
                                onClick={handleAddItem}
                                sx={{ 
                                    margin: '10px',
                                    backgroundColor: theme.palette.secondary.dark,
                                    fontWeight: 700,
                                '&:hover': {
                                    backgroundColor: theme.palette.secondary.main, // Nastaví barvu pozadí při najetí myší
                                },
                                }}
                            >
                                <AddIcon
                                sx={{ 
                                    color: 'white', 
                                    fontSize: '22px'
                                }}
                                />
                            </IconButton>
                            <SplitButton
                                isChanged={true} 
                                handleClick={handleSave}
                            >
                                Šablony
                            </SplitButton>
                        </Box>
                        {message && (
                            <Alert variant="outlined" severity={severity} style={{ position: 'fixed', top: 10, left: 50, zIndex: 9999, height: 50, width: 250, overflow: 'auto', textAlign: 'center', backgroundColor: 'white' }}>
                                {message}
                            </Alert>
                        )}
                    </Box>
                    <Box 
                        sx={{ 
                        order: { xs: 1, md: 2 }, 
                        textAlign: 'center', 
                        width: '100%',
                        marginRight: { xs: '0px', md: '400px' },
                        }}
                    >
                        <Typography 
                        variant="h6" 
                        onClick={handleEntityClick}
                        sx={{
                            backgroundColor: 'none',
                            fontSize: '18px',
                            color: 'black',
                            fontWeight: 'bold',
                            padding: '2px 0px 8px 0',
                            cursor: 'pointer',
                            zIndex: '100',
                            textDecoration: 'none',
                            marginTop: '10px',
                            marginBottom: '10px',
                            width: '100%',
                            
                        }}
                        >
                        {entityTitle}
                        </Typography>
                    </Box>
                </Box>
                        
                <DetailSectionComponent className="detail-section"
                    dataLookup={detailSectionsGlob}
                />
              
              <div style={{ position: 'sticky', top: 0 }}>
                <PlacementComponent 
                  placementRefs={placementRefs}
                />
                {/* další komponenty zde */}
              </div>
              
              </SimpleContainer>
        );
    }

}

export default CustomPage;
