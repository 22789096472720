
import { saveData, checkTaskStatus, addQuickUkolApi} from './apiRest'; // Předpokládám, že tyto funkce jsou importovány z './api'


export const save = async (tableEntita, idUn, data, paramsL, inputFiles, selectedDates, command, idKalkulace, paramsLKalkulace, rowDataAllKalkulace, rowDataAllProcesMapa, paramsLProcesMapa) => {

    console.log('v save na zacatku - tableEntita: ', tableEntita, 'idUn: ', idUn, 'data: ', data, 'paramsL: ', paramsL, 'inputFiles', inputFiles, 'selectedDates', selectedDates, 'idKalkulace', idKalkulace, 'command', command);
    let dataforSave
    let dataProcesMapa
    let idProcesMapa
    let idIdentOPB
    let idOP
    let ukladatProcesMapa = false;
    const dataVstup = { ...data }; //obsahuje všechny klíče a hodnoty z data. Jakékoli změny v data poté neovlivní dataVstup
    //vyhodnotim zda je v ukladane entite aplikovana kalkuace
    const idIdent = paramsL.find(index => index.parametr === 'kalkulacePole1Data' && index.tableEntita === tableEntita)?.id;
   
    //vyhodnotim zda je v ukladane entite zobrazovana mapaProces
    const idIdentMapaProces = paramsL.find(index => index.parametr === 'ProcesMapaPole1Data' && index.tableEntita === tableEntita)?.id;
        
    // -------------------------- zde projdou jen entity, kam se aplikuje kalkulace, kalkulace samotna prochazi jinou cestou
    if(idIdent){ 
        if(data[3492]){ 
            delete data[3492]; // odstranim z data pole 3492 od ProcesMapa
        }
        console.log('111 ------------------------------ dataVstup: ', dataVstup)
        console.log('v save - id parametru kalkulacePole1Data ukladane entity (existuje jen u entity s aplikovatelnou kalkulaci) - idIdent: ', idIdent, 'idIdentMapaProces: ', idIdentMapaProces);
        let zasazeneKalkulacePuvodni = [];
        let ukladatKalkulace = false;
        
        let dataKalkulaci = [];
        //najdu vsechny puvodni kalkulace jeste pred aktualne provadenou zmenou, ktere jsou aktivni a maji vyplnene pole1Data a byla do nich v minulosti aplikovana ukladana entita
        if(rowDataAllKalkulace.length !== 0){
            rowDataAllKalkulace.forEach(item => {
                if(item.aktivni && item.pole1Data) { // jen aktivni kalkulace
                    try {
                        //console.log('v save - prochazim pole1Data kazde aktivni kalkulace - item.pole1Data', item.id, item.name)
                        let parsedData = JSON.parse(item.pole1Data);
                        parsedData.forEach(dataItem => {
                            if(dataItem['appId' + tableEntita] === idUn) { // napr. tableEntita ZalohaFakturace, parovanaEntita OPB, v datech hleda idOPB
                                //console.log('v save - prochazim pole1Data kazde aktivni kalkulace - item.pole1Data', item.id)
                                
                                zasazeneKalkulacePuvodni.push(item.id);
                            }
                            
                        });
                    } catch(e) {
                        console.error(`Error parsing JSON for item: ${JSON.stringify(item)} error: ${e}`);
                    }
                };
            });
            
        }
        //console.log('v save - puvodnidataKalkulace aktualni k ukladane entite: ', zasazeneKalkulacePuvodni)

        // kdyz se nezobrazi detailSection List pod Kalkulaci, tak se nevytvori pole 2088
        if(data[2088]){
            //najdu v data pole 2088 ty polozky, ktere se aplikovali do dane entity (nejen druh entity ale i id entity idUn)
        
            let filteredItems = data[2088].filter(item => item['appId' + tableEntita] === idUn);
            const zasazeneKalkulaceNove = filteredItems.map(item => item.defIdKalkulace);
            //console.log('v save - vytvoreno pole pro ulozeni kalkulace - filteredItems: ', filteredItems)
            //console.log('v save - vytvoreno pole pro ulozeni kalkulace - zasazeneKalkulaceNove: ', zasazeneKalkulaceNove)
            const zasazeneKalkulaceAll = [...new Set([...zasazeneKalkulacePuvodni, ...zasazeneKalkulaceNove])];
            //console.log('v save - vytvoreno pole pro ulozeni kalkulace - zasazeneKalkulaceAll: ', zasazeneKalkulaceAll)

            let filteredItemsAll = data[2088].filter(item => zasazeneKalkulaceAll.includes(item.defIdKalkulace));
            //console.log('v save - vytvoreno pole pro ulozeni kalkulace - filteredItemsAll: ', filteredItemsAll)
            
            //ulozim vysledek pod idIdent do puvodniho objektu data
            data[idIdent] = JSON.stringify(filteredItemsAll);
            //console.log('v save - vytvoreno pole pro ulozeni kalkulace - dataEntity: ', data)

            dataKalkulaci = filteredItemsAll.reduce((acc, item) => {
                let existingIndex = acc.findIndex(obj => obj['2080'] === item.defIdKalkulace);
                if (existingIndex !== -1) {
                    acc[existingIndex]['2088'].push(item);
                } else {
                    acc.push({
                        '2080': item.defIdKalkulace,
                        '2088': [item]
                    });
                }
                return acc;
            }, []);
            //console.log('v save - vytvoreno pole pro ulozeni kalkulace - newArray: ', newArray)
                    
            //odstranim z data pole 2088
            const { '2088': pole1Data, ...restData } = data; //odstranim z data pole 2088
            //data pro ulozeni jen entity kam se kalkulace aplikovala, není to pro kalkulaci
            dataforSave = restData;
            //console.log('v save - ukladani entity, kam se aplikovala Kalkulace - dataEntity: ', dataforSave)
            //data pro ulozeni jen kalkulaci, kam se aplikovala entita
            //console.log('v save - ukladani kalkulace, dataKalkulaci: ', dataKalkulaci)
            ukladatKalkulace = true;
            console.log('2222222 - v save - dataforSave: ', dataforSave, 'dataKalkulaci: ', dataKalkulaci)

        }else {
            dataforSave = data;
            //console.log('v save - ukladani entity, kam se dat aplikovat Kalkulace, ale zde nedoslo k otevreni zalozky Kalkuluace - dataforSave: ', dataforSave)
        }
        //console.log('------------------------------ dataVstup: ', dataVstup)
        //kdyz je obsazena ProcesMapa, tak ji ulozim do entity ProcesMapa
        if(dataVstup[3492]){
            dataProcesMapa = { '3492': dataVstup['3492'] };
            idIdentOPB =  tableEntita !== 'OPB' ? paramsL.find(index => index.parametr === 'idOPB' && index.tableEntita === tableEntita)?.id : paramsL.find(index => index.parametr === 'id' && index.tableEntita === tableEntita)?.id;      
            idOP = dataVstup[idIdentOPB];
            idProcesMapa = rowDataAllProcesMapa.find(index => index.idOPB === idOP)?.id;
            ukladatProcesMapa = true;
            console.log('3333333 - v save - dataProcesMapa: ', dataProcesMapa, 'idProcesMapa: ', idProcesMapa)
        }

        try {
            const token = localStorage.getItem('token');
            //samostatne ulozeni entity
            console.log('v save - ukladani entity, kde byla zobrazena Kalkulace - dataforSave: ', dataforSave, tableEntita, idUn)
            const response1 = await saveData(tableEntita, idUn, dataforSave, paramsL, inputFiles, selectedDates, command, token);
            const taskId1 = response1.taskId;
            const saved = await checkTaskStatus(taskId1);
            
            //samostatne ulozeni vsech kalkulaci, do kterych se aplikuje ukladana entita
            if(ukladatKalkulace){
                dataKalkulaci.forEach(async (item) => {
                    console.log('v save - ukladani kalkulace - v ciklu ukldani vsech kalkulaci - item: ', item)
                    const response2 = await saveData('Kalkulace', item['2080'], item, paramsLKalkulace, {}, {}, command, token);
                    const taskId2 = response2.taskId;
                    const saved1 = await checkTaskStatus(taskId2);
                    console.log('ulozeno', saved1)
                });
            }

            if(ukladatProcesMapa){
                console.log('v save - ukladani ProcesMapa - dataProcesMapa: ', dataProcesMapa, 'idProcesMapa: ', idProcesMapa)
                const response2 = await saveData('ProcesMapa', idProcesMapa, dataProcesMapa, paramsLProcesMapa, {}, {}, command, token);
                const taskId2 = response2.taskId;
                const saved1 = await checkTaskStatus(taskId2);
                console.log('ulozeno', saved1)
            }
            
            console.log('ulozeno', saved)
            if (saved.result){
                return saved.result
            }
            //console.log('vracim z detailData Store: ', structurEntGlob, dataGlob, paramsLAllGlob, detailSectionsGlob, paramsLGlob, response1, taskId1, dataEntitaListGlob, dataDefaultGlob, sablonaListGlob);
            return {};
        } catch (error) {
            console.error(error);
        }
    
     //--------------- entita je kalkulace--------------
    }else if(tableEntita === 'Kalkulace'){
        const paramsLKalkulaceDef = {2080: 'defIdKalkulace', 2083: 'defIdOPB', 2415: 'defProcesOPBObchod', 2416: 'defProcesOPBRealizace', 2465: 'defIdOPS', 2467: 'defProcesOPSObchod', 2468: 'defProcesOPSRealizace', 2408: 'defIdServis', 2417: 'defProcesServis'}
         //v pripade entity kalkulace zkopiruji do 2088 (pole1Data) hodnoty parametru def
         //console.log('v save - data pred zkopirovani hodnot parametru def do pole1Data: ', data)     
       if(data[2088]){ // kdyz se nezobrazi detailSection List pod Kalkulaci, tak se nevytvori pole 2088
            data[2088].forEach(item => {
                Object.entries(paramsLKalkulaceDef).forEach(([key, value]) => {
                    //console.log('v save - v ciklu pred if item: ', item, 'value: ', value )   
                    //if (value in item) {
                        //console.log('v save - v ciklu data[key]: ', data[key], 'item[value]: ', item[value] )
                        item[value] = data[key];
                    //}
                });
            });
       }
         
        
        console.log('v save - data po zkopirovani hodnot parametru def do pole1Data: ', data)

        dataforSave = data;
        try {
            const token = localStorage.getItem('token');
            const response1 = await saveData(tableEntita, idUn, dataforSave, paramsL, inputFiles, selectedDates, command, token);
            const taskId1 = response1.taskId;
            const saved = await checkTaskStatus(taskId1);
            console.log('ulozeno', saved)
            if (saved.result){
                return saved.result
            }
            //console.log('vracim z detailData Store: ', structurEntGlob, dataGlob, paramsLAllGlob, detailSectionsGlob, paramsLGlob, response1, taskId1, dataEntitaListGlob, dataDefaultGlob, sablonaListGlob);
            return {};
        } catch (error) {
            console.error(error);
        }

     // ----------------- zde resim ukladani ostatnich entit, ktere nejsou kalkulace nebo se do nich neaplikuje kalkulace a neni Procesmapa----------
    }else {
        
        //kdyz je obsazena ProcesMapa, tak ji ulozim do entity ProcesMapa
        if(dataVstup[3492] && tableEntita !== 'ProcesMapa'){
            dataProcesMapa = { '3492': dataVstup['3492'] };
            idIdentOPB =  tableEntita !== 'OPB' ? paramsL.find(index => index.parametr === 'idOPB' && index.tableEntita === tableEntita)?.id : paramsL.find(index => index.parametr === 'id' && index.tableEntita === tableEntita)?.id;      
            idOP = dataVstup[idIdentOPB];
            idProcesMapa = rowDataAllProcesMapa.find(index => index.idOPB === idOP)?.id;
            ukladatProcesMapa = true;
            console.log('3333333 - v save - dataProcesMapa: ', dataProcesMapa, 'idProcesMapa: ', idProcesMapa)
            delete data[3492];
            dataforSave = data;
            console.log('v save, jedna se o entitu, kde neni aplikovana kalkulace ale ProcesMapa ano - dataforSave : ', dataforSave)
        }else if(dataVstup[3492] && tableEntita == 'ProcesMapa'){
            dataforSave = dataVstup;
            ukladatProcesMapa = false;
            console.log('v save, jedna se o entitu ProcesMapa - dataforSave : ', dataforSave)
        }else{
            dataforSave = dataVstup;
            ukladatProcesMapa = false;
            console.log('v save, jedna se o entitu, kde neni aplikovana kalkulace ani ProcesMapa - dataforSave : ', dataforSave)
        }
                
        try {
            const token = localStorage.getItem('token');
            const response1 = await saveData(tableEntita, idUn, dataforSave, paramsL, inputFiles, selectedDates, command, token);
            const taskId1 = response1.taskId;
            const saved = await checkTaskStatus(taskId1);
            console.log('ulozeno', saved)

            if(ukladatProcesMapa){
                console.log('v save - ukladani ProcesMapa - dataProcesMapa: ', dataProcesMapa, 'idProcesMapa: ', idProcesMapa)
                const response2 = await saveData('ProcesMapa', idProcesMapa, dataProcesMapa, paramsLProcesMapa, {}, {}, command, token);
                const taskId2 = response2.taskId;
                const saved1 = await checkTaskStatus(taskId2);
                console.log('ulozeno', saved1)
            }

            if (saved.result){
                return saved.result
            }

            
            //console.log('vracim z detailData Store: ', structurEntGlob, dataGlob, paramsLAllGlob, detailSectionsGlob, paramsLGlob, response1, taskId1, dataEntitaListGlob, dataDefaultGlob, sablonaListGlob);
            return {};
        } catch (error) {
            console.error(error);
        }
    }
   

}

export const addQuickUkol = async (entita, body, command) => {

    console.log('v save - addQuickUkol - body: ', body, 'command: ', command, 'entita: ', entita)
   
    try {
        const token = localStorage.getItem('token');
        const response2 = await addQuickUkolApi(entita, body, command, token);
        const taskId2 = response2.taskId;
        const saved = await checkTaskStatus(taskId2);
        console.log('ulozeno', saved)
        if (saved.result){
            return saved.result
        }
        
        return {};
    } catch (error) {
        console.error(error);
    }

}