import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';


const SectionDiv = styled(Paper)(({ theme }) => ({
    m: 1,
    backgroundColor: theme.palette.primary.main,
    color: 'black',
    margin: '10px',
    padding: '10px',
    boxShadow: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: '#f8f8f8',
    },
  }));
  
  const PlacementHead = styled('div')({
    fontWeight: 'bold',
    // další styly pro nadpis sekce
  });
  
  const SectionList = styled('ul')({    
    listStyleType: 'none',
    padding: 0,
    // další styly pro seznam
  });
  
  const ListItem = styled('li')({
    padding: '10px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#eee',
    },
  });

const SectionComponent = ({ sectionGlob, structureGlob, handleEntityClick }) => {
    return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        '& > :not(style)': {
          m: 1,
          width: 200,
          
        },
      }}
    >
      <SectionDiv elevation={0}>
        <Typography 
          variant="h6"
          fontWeight={600}
        >
          {sectionGlob.section}
        </Typography>
        <SectionList>
          {structureGlob.filter(item => item.section === sectionGlob.section).map(item => (
            <ListItem 
              key={item.tableEntita} 
              onClick={() => handleEntityClick(item.tableEntita, item.entita, item.page)}>
              {item.entites}
            </ListItem>
          ))}
        </SectionList>
      </SectionDiv>
      
    </Box>
  );
};

export default SectionComponent;
