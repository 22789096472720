//RestForm.jsx

import React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { styled, keyframes } from '@mui/material/styles';
import { is } from 'date-fns/locale';





export default function RestForm({
  required, id, label, value, variant, readOnly, unit, style, helperText, disableUnderline, onChange, onBlur, paramInput, iconZadani, multiline, rows, onKeyDown }) {
  //console.log('FRestForm', 'required:', required, 'id:', id, 'label:', label, 'value:', value, 'variant:', variant, 'readOnly:', readOnly, 'unit:', unit, 'style:', JSON.stringify(style), 'helperText:', helperText);
  //console.log('v RestForm - value', value, 'readOnly', readOnly, 'paramInput: ', paramInput);
    
  
  return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1 },
        margin: paramInput === 'default' ? '0px 0 0px 0' : paramInput === 'auto' ? '0px 0 0px 0' : '15px 0 15px 0',
     
      }}
      noValidate
      autoComplete="off"
      //style={{ width: '500px' }}
    >
      <div>
        <TextField
          onKeyDown={onKeyDown} // Přidat obsluhu události onKeyDown
          multiline={multiline}
          rows={rows} // Počet řádků, které chcete mít viditelné
          required={required}
          id={id}
          label={label}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          variant={readOnly ? "filled" : variant} // Změna varianty na "filled", pokud je readOnly
          helperText={helperText}
          InputProps={{
            readOnly: readOnly,
            style: style,
            overflow: 'auto',
            classes: { underline: readOnly ? 'no-underline' : '' },
            endAdornment: (
          <>
            {unit && <InputAdornment position="end">{unit}</InputAdornment>}
            {iconZadani}
          </>
        ),
            
          }}
          InputLabelProps={{
            style: { 
              marginTop: '5px', // Přidává horní odsazení pro label
              marginLeft: '10px',
              paddingTop: readOnly ? '0px' : '0px' // Zachovává padding labelu od spodu a nahoře
            }
          }}
          
        />
      </div>
    </Box>
  );
}


