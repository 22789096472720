import * as React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import ButtonGroup from '@mui/material/ButtonGroup';

export default function GroupButton({ buttons, changeScale }) {
  
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > *': {
          m: 1,
        },
      }}
    >
      <ButtonGroup size="small" color="secondary" aria-label="Měřítko">
        {buttons.map((button) => (
          <Button key={button} onClick={() => changeScale(button)}>
            {button}
          </Button>
        ))}
      </ButtonGroup>
    </Box>
  );
}
