//LookupQuick.jsx

import React, { useState, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

const LookupQuick = ({ param, lookupList, selectedInput, setSelectedInput }) => {
    const [inputValue, setInputValue] = useState('');
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setInputValue(selectedInput || '');
    }, [selectedInput]);

    const handleInputChange = (event, newInputValue) => {
        setInputValue(newInputValue);
        // Zde není nutné měnit stav 'open', protože otevření řeší onFocus
    };

    const handleChange = (event, newValue) => {
        setSelectedInput(newValue);
    };

    return (
        <Autocomplete
            id="lookup-quick"
            sx={{ 
                width: 300,
                margin: '0px 0 10px 0',
            }}
            options={lookupList}
            value={selectedInput}
            inputValue={inputValue}
            onInputChange={handleInputChange}
            onChange={handleChange}
            open={open}
            onOpen={() => {
                if (inputValue !== '') {
                    setOpen(true);
                }
            }}
            onClose={() => setOpen(false)}
            renderInput={(params) => 
                <TextField 
                    {...params} 
                    label={param.vizu} 
                    onFocus={() => setOpen(true)} 
                    InputProps={{
                        ...params.InputProps,
                        sx: {
                            height: '43px', // Adjust as needed
                            '.MuiInputBase-input': {
                                height: '10px',
                                padding: '0px',
                            },
                        },
                    }}
                />
            }
        />
    );
    
}

export default LookupQuick;
