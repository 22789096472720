// api/apiBase.js

//const token = localStorage.getItem('token');



/*
const fetchContacts = async () => {
    const response = await fetch('/get-kontakt');
    if (response.status === 401) {
        console.log('v fetchContacts - chyba, response.status: ', response.status);
        // Session expired, redirect to relogin
        window.location.href = '/login';
        return;
    } else{
        console.log('v fetchContacts, response: ', response);
        return response.json();
    }
    
};
*/

const getCiselnik = async (token) => {
    //console.log('kontrola1, /get-ciselnik');
    const response = await fetch('/get-ciselnik', {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
    });
    /*
    if (!response.ok) {
        return response.text().then(error => {
        throw new Error(error);
    });
    }
    */
    if (!response.ok) {
        const errorData = await response.json();
        if (errorData.redirect) {
            window.location.href = errorData.redirect;
        } else {
            const errorText = JSON.stringify(errorData);
            throw new Error(errorText);
        }
    }
    //console.log('v /get-ciselnik, response:', response);
    return response.json();
};

const getProces = async (token) => {
    //console.log('kontrola1, /get-proces');
    const response = await fetch('/get-proces', {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
    });
    /*
    if (!response.ok) {
        return response.text().then(error => {
        throw new Error(error);
    });
    }
    */
    if (!response.ok) {
        const errorData = await response.json();
        if (errorData.redirect) {
            window.location.href = errorData.redirect;
        } else {
            const errorText = JSON.stringify(errorData);
            throw new Error(errorText);
        }
    }
    //console.log('v /get-proces, response:', response);
    return response.json();
};

const getUkol = async (token) => {
    //console.log('kontrola1, /get-ukol');
    const response = await fetch('/get-ukol', {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
    });
    /*
    if (!response.ok) {
        return response.text().then(error => {
        throw new Error(error);
    });
    }
    */
    if (!response.ok) {
        const errorData = await response.json();
        if (errorData.redirect) {
            window.location.href = errorData.redirect;
        } else {
            const errorText = JSON.stringify(errorData);
            throw new Error(errorText);
        }
    }
    //console.log('v /get-ciselnik, response:', response);
    return response.json();
};



export { getCiselnik, getUkol, getProces };
