//FileComponent je komponenta, která se stará o zobrazení a editaci dat typu file
import React, { useState, useEffect, useContext } from 'react';
import { DetailDataContext } from '../store/detailDataContext';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { InputLabel, Input, FormControl } from '@mui/material';
import { styled } from '@mui/material/styles';
import { OutlinedInput } from '@mui/material';
import {TextField} from '@mui/material';


function fileNameCor(filename){
  //console.log('0 - fce5.fileNameCor - filename', filename);
  let filename1 = filename.replace(/[\/\\#%*:<>?|]/g, '-'); // Nahradí všechny neplatné znaky pomlckou
  //console.log('1 - fce5.fileNameCor - filename1', filename1);
  let filename2 = removeDiacritics(filename1);
  //console.log('2 - fce5.fileNameCor - filename2', filename2);
  return filename2;

}

function removeDiacritics(str) {
    const diacriticsMap = {
        'á': 'a', 'č': 'c', 'ď': 'd', 'é': 'e', 'ě': 'e', 'í': 'i', 
        'ň': 'n', 'ó': 'o', 'ř': 'r', 'š': 's', 'ť': 't', 'ú': 'u', 
        'ů': 'u', 'ý': 'y', 'ž': 'z', 'Á': 'A', 'Č': 'C', 'Ď': 'D', 
        'É': 'E', 'Ě': 'E', 'Í': 'I', 'Ň': 'N', 'Ó': 'O', 'Ř': 'R', 
        'Š': 'S', 'Ť': 'T', 'Ú': 'U', 'Ů': 'U', 'Ý': 'Y', 'Ž': 'Z',
        'Ä': 'A', 'Ý': 'Y', 'Í': 'I' // Přidáno
    };

    return str.replace(/[áčďéěíňóřšťúůýžÁČĎÉĚÍŇÓŘŠŤÚŮÝŽ]/g, function(match) {
        return diacriticsMap[match];
    });
}


const FileComponent = ({ param, placementRef }) => {

    const theme = createTheme({
        palette: {
          primary: {
            main: '#1C80BC', // Modrá barva pro primární barvu
          },
          // Případně můžete přizpůsobit další barvy
        },
      });
    
    const [selectedFile, setSelectedFile] = useState(null);

    const {
        saveSuccessful,
        setSaveSuccessful,
        inputFiles,
        setInputFiles,
        detailDataNutne,
        isChanged,
        setIsChanged
    } = useContext(DetailDataContext); // Získání hodnot z kontextu

    
    const rowData = detailDataNutne.dataGlob[0]; // Získání dat z detailDataContext
    const paramsL = detailDataNutne.paramsLGlob; // Získání dat z detailDataContext
    const headers = detailDataNutne.headers; // Získání dat z detailDataContext
    const header = param.parametr;
    let imgPath = '';
    let nameBezP = '';
    let nameBezPBezInterpunkce = '';
    let fileNamePdfFirst = '';

    if(rowData[header]){
      imgPath = 'https://enadosro.sharepoint.com/sites/dbFiles/Sdilene%20dokumenty/intranet/';
      nameBezP = rowData[header]?.split('.').slice(0, -1).join('.');
      nameBezPBezInterpunkce = fileNameCor(nameBezP);
      fileNamePdfFirst = nameBezPBezInterpunkce + 'Fi' + '.png';
    }
    

    


    //------------------- overeni existence souboru
    const [isImageLoaded, setIsImageLoaded] = useState(true);

        
    // nejen pri prvnim nacteni vrátí 'nic', pokud je inputFiles?.[param.id] undefined, a vrátí null nebo hodnotu inputFiles?.[param.id], pokud je null nebo něco jiného.
    const inputFile = inputFiles?.[param.id] === undefined ? 'nic' : inputFiles?.[param.id]; 
    //console.log('v FileComponent - ', 'inputFiles?.[param.id]: ', inputFiles?.[param.id], 'param.id', param.id, 'inputFile: ', inputFile);
    //console.log('v FileComponent pred useEffect - saveSuccessful', saveSuccessful)
    useEffect(() => {
        // pokud je v DB soubor a zaroven neni v inputFile, tak ho dam do inputFiles[header]
        if (!selectedFile && (inputFile === 'nic') && param && param.parametr && rowData && rowData[param.parametr]) { 
            //console.log('v FileComponent - useEffect - pred nastavenim inputFiles', param.parametr, inputFiles, rowData[param.parametr]);
            setInputFiles(prevValues => ({
                ...prevValues,
                [param.id]: rowData[param.parametr],
            }));
            //console.log('v FileComponent - useEffect - nastavuji inputFiles', param.parametr, rowData[param.parametr]);
            //setSelectedFile('zDat');

        }
       
        //console.log('v FileComponent uvnitr useEffect- saveSuccessful', saveSuccessful);
        
        setSaveSuccessful(false);
        
    }, [param, placementRef, saveSuccessful, inputFiles]);
    //console.log('v FileComponent - po vykresleni - inputFile:', inputFile);

    const handleFileDelete = (id) => {
        //console.log('v FileComponent - handleFileDelete klikl jsem, id: ', id, 'inputFiles: ', inputFiles[id]);
        
            //console.log('v FileComponent - handleFileDelete - vstup inputFiles', inputFiles);
            
            setInputFiles(prevInputFiles => { // odstraneni souboru z inputFiles
                if (!prevInputFiles) {
                    return { [id]: null };
                } else {
                    return { ...prevInputFiles, [id]: null };
                }
            });
            setIsChanged(true);
    };
    
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        //console.log('v FileComponent - handleFileChange - file: ', param.vizu, param.id, file);
        if (file) {
          setSelectedFile(file);
          setInputFiles(prevValues => ({
            ...prevValues,
            [param.id]: file
          }));
          setIsChanged(true);
        }
      };
    
      const clearSelectedFile = () => {
        setSelectedFile(null);
        setInputFiles(prevValues => ({
          ...prevValues,
          [param.id]: null
        }));
        setIsChanged(true);
      };


      return (
        <ThemeProvider theme={theme}>
          <Box sx={{ position: 'relative', display: 'flex', justifyContent: 'center', margin: '30px 0 20px 0', width: '100%', height: '100%', backgroundColor: 'inherit' }}>
            
            {(!inputFiles || inputFiles.length == 0 || inputFiles?.[param.id] == 'nic' || inputFiles?.[param.id] == null) && (
              
              <TextField
              variant="outlined"
              fullWidth
              label={param.vizu}
              type="file"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{ 'aria-label': param.vizu }}
              onChange={handleFileChange}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '&:hover fieldset': {
                    borderColor: 'primary.main', // Změna barvy borderu při najetí myši
                  },
                },
                width: '300px'
              }}
            />
            )}
            
            {selectedFile && (inputFiles && inputFiles?.[param.id]) && (
              <>
                <TextField
                variant="outlined"
                fullWidth
                label={param.vizu}
                value={inputFiles?.[param.id].name}
                InputLabelProps={{
                    shrink: true,
                }}  
                inputProps={{ 'aria-label': param.vizu }}
                
                />
                <IconButton onClick={clearSelectedFile}><DeleteIcon /></IconButton>
              </>
            )}
            
            {rowData[param.parametr] && inputFiles?.[param.id] !== null && (
              <Box 
              sx={{ 
                border: '1px solid', 
                borderColor: 'divider', 
                borderRadius: '4px', 
                padding: '10px 5px 0px 5px',
                display: 'flex',
                flexDirection: 'column',
                width: '300px',
                position: 'relative', // Přidáno pro absolutní pozicování InputLabel
                ':hover': { // Přidáno pro změnu barvy borderu při najetí myši
                    borderColor: 'primary.main',
                },
              }}
            >
              <InputLabel 
                shrink 
                htmlFor={param.parametr}
                sx={{ 
                  position: 'absolute', // Absolutní pozicování
                  left: '14px', // Odsazení od levého okraje
                  top: '-9px', // Odsazení od horního okraje
                  backgroundColor: '#f8f8f8', // Barva pozadí labelu
                  padding: '0px 5px 0px 5px', // Padding pro zachování prostoru kolem textu
                  zIndex: 1, // Nastavení na vyšší hodnotu pro zobrazení v popředí
                  
                }}
              >
                {param.vizu}
              </InputLabel>
              <Box>
              {isImageLoaded ? (
                  <img
                      src={`${imgPath + fileNamePdfFirst}`}
                      onLoad={() => setIsImageLoaded(true)}
                      onError={() => setIsImageLoaded(false)}
                      style={{ width: 290, height: 150, objectFit: 'cover', objectPosition: 'top' }}
                  />
              ) : (
                  <div>
                      <p>Náhled není k dispozici</p>
                      <p>{rowData[header]}</p>
                  </div>
              )}
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button component="a" href={`${imgPath + rowData[header]}`} target="_blank" rel="noopener noreferrer">Otevřít soubor</Button>
                <IconButton onClick={() => handleFileDelete(param.id)}><DeleteIcon /></IconButton>
              </Box>
            </Box>
            )}
            
          </Box>
        </ThemeProvider>
      );
};

export default FileComponent;

/*
TextField
              variant="outlined"
              fullWidth
              label={param.vizu}
              type="file"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{ 'aria-label': param.vizu }}
              onChange={handleFileChange}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '&:hover fieldset': {
                    borderColor: 'primary.main', // Změna barvy borderu při najetí myši
                  },
                },
                width: '300px'
              }}
            />
*/