import React, { useState, useRef, useEffect } from 'react';
import { localeText } from '../atoms/localeText'; // Nastavení lokalizačních řetězců
import { AgGridReact } from 'ag-grid-react';
import { TextField } from '@mui/material';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { LicenseManager } from 'ag-grid-enterprise';
import { AgChart } from 'ag-charts-community';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';

//import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';

import dayjs from 'dayjs';
import FormatCZK from '../atoms/FormatCZK';
import IconButton from '@mui/material/IconButton';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

LicenseManager.setLicenseKey('Using_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-059104}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Enado_s.r.o.}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{enadodata}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{enadodata}_need_to_be_licensed___{enadodata}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Charts_and_AG_Grid}_Enterprise_versions_released_before_{25_April_2025}____[v3]_[0102]_MTc0NTUzNTYwMDAwMA==eb62c95c3603f7547d6b9fb444e34c51');
AgChart.licenceKey = "Using_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-059104}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Enado_s.r.o.}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{enadodata}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{enadodata}_need_to_be_licensed___{enadodata}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Charts_and_AG_Grid}_Enterprise_versions_released_before_{25_April_2025}____[v3]_[0102]_MTc0NTUzNTYwMDAwMA==eb62c95c3603f7547d6b9fb444e34c51";

// Registrace RowGroupingModule
ModuleRegistry.registerModules([RowGroupingModule]);
ModuleRegistry.registerModules([ExcelExportModule]);


const GlobalFilter = React.forwardRef(({ onFilterChanged }, ref) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', margin: '10px 0' }}>
      <TextField
        type="search"
        onChange={e => onFilterChanged(e.target.value)}
        placeholder={'Hledej...'}
        autoFocus // Přidá automatické zaměření na pole pro vyhledávání
        variant="outlined"
        size="small"
        ref={ref}
        style={{ width: '100%', maxWidth: '300px' }}
        autoComplete="off" // Zabrání automatickému vyplňování
      />
    </div>
  );
});


const DataTable = ({ tableEntita, pageP, data, headers, columnAccessors, onRowClick, mjs, inputFormat }) => {
  const gridRef = useRef(null);
  console.log('v DataTable - headers', headers, 'data', data, 'columnAccessors', columnAccessors, 'mjs', mjs, 'inputFormat', inputFormat);


  const onGridReady = params => {
    gridRef.current = params.api;
    params.api.sizeColumnsToFit();
    // Rozbalení všech skupin po načtení dat
    params.api.expandAll();
        
  };

  const gridOptions = {
    groupHeaderHeight: 20, // Nastaví výšku skupinových záhlaví
    headerHeight: 80, // Nastaví výšku standardních záhlaví
    suppressRowClickSelection: true,
    rowSelection: 'multiple',
    // Přidejte další potřebná nastavení
  };

  //console.log('v DataTable - headers', headers);
  const columnDefs = headers.map((header, index) => ({
    headerName: header,
    field: columnAccessors[index],
    filter: 'agSetColumnFilter',
    minWidth: header.includes('Název') ? 300
    : header.includes('Cena') || header.includes('marže') || header.includes('Náklady') || header.includes('Hodnota') || header.includes('Fáze') || header.includes('proces') ? 160
    : 130,
    headerClass: 'ag-header-cell-wrap-text',
    wrapHeaderText: true, // Zapne zalamování textu
    cellRenderer: (params) => {
      const value = params.value;
      const mj = mjs[index];
      const format = inputFormat[index];
      if (!value) return '';
      if (mj === 'Kč') return FormatCZK(value);
      if (format === 'date') return dayjs(value).format('DD.MM.YYYY');
      if (format === 'dateTime') return dayjs(value).format('DD.MM.YYYY HH:mm');
      return value.toString();
    },
    aggFunc: (mjs[index] === 'Kč' || mjs[index] === 'h') ? 'sum' : null, // Přidání vlastnosti aggFunc pro součet
    enableRowGroup: true, // Povolení seskupování pro tento sloupec
    enablePivot: true,
    pinned: 'top', // Připnutí sloupce na začátek tabulky
    headerClass: 'ag-header-cell-wrap-text',
    autoHeight: false,
    
  }));

  columnDefs.push({
    headerName: 'xxx',
    field: 'xxx', // Předpokládáme, že máte pole 'kategorie' ve vašich datech pro seskupování
    hide: true, // Tento sloupec může být skrytý, pokud slouží pouze pro seskupování
    rowGroup: false, // Tímto sloupec nastavíte jako základ pro seskupování
    showRowGroup: true, // Zobrazí seskupené řádky
  });

  // Upravená část: předání dat řádku do funkce onRowClick
  const handleRowClicked = event => {
    const { data } = event;
    if (onRowClick) {
      // Předpokládáme, že onRowClick očekává parametry jako (tableEntita, entita, pageP, id)
      // Upravte následující volání funkce podle vašich potřeb
      console.log('v DataTable - handleRowClicked - data', data);
      onRowClick(tableEntita, data.entita, pageP, data.id);
      
    }
  };

  const exportToExcel = () => {
    gridRef.current.exportDataAsExcel();
  };

  // adds subtotals
  const groupIncludeFooter = true;
  // includes grand total
  const groupIncludeTotalFooter = true;

  return (
    <div className="flex-container" style={{ width: '100%' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
              <div></div> {/* Prázdný div pro vyvážení */}
              <GlobalFilter onFilterChanged={value => gridRef.current && gridRef.current.setGridOption('quickFilterText', value)} />
              <IconButton
                  title="Export do Excelu"
                  size="large"
                  edge="end"
                  aria-haspopup="true"
                  onClick={exportToExcel}
                  color="inherit"
                  sx={{ mr: 2, marginRight: '0px',  }}
              >
                  <ExitToAppIcon />
              </IconButton>
          </div>
              
          <div className="ag-theme-alpine" style={{ height: '77vh', width: '100%' }}>
            <AgGridReact
              columnDefs={columnDefs}
              rowData={data}
              onGridReady={onGridReady}
              gridOptions={gridOptions} // Připojte definované možnosti k tabulce
              animateRows={true}
              onRowClicked={handleRowClicked}
              groupIncludeFooter={groupIncludeFooter} // Zobrazí agregované hodnoty v zápatí skupiny
              enableRangeSelection={true} // Povolí výběr rozsahu pro výpočty agregací
              autoHeaderHeight={true}
              autoGroupColumnDef={{ // Konfigurace vzhledu pro sloupec auto skupiny
                headerName: 'Skupina',
                minWidth: 150,
                cellRendererParams: {
                  checkbox: true,
                },
                cellStyle: { fontWeight: 'bold' } // Tučný text pro skupinové řádky
              }}
              
              //domLayout='autoHeight' // Automatická výška podle obsahu
              localeText={localeText} // Nastavení lokalizačních řetězců
              rowSelection="multiple" // Povolení výběru více řádků
              groupIncludeTotalFooter={groupIncludeTotalFooter}
             
              
            />
          </div>
     
    </div>
  );
};

export default DataTable;
