//app.jsx je hlavní komponenta, která obaluje všechny ostatní komponenty
import './App.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Header from './pages/header';
import HomePage from './pages/homePage'; 
import Login from './pages/login';
import Logout from './pages/logout';
import Relogin from './pages/relogin';
import BoardView from './pages/boardView';
import { StoreProvider } from './store/baseData';
import { GenProvider } from './store/genData';
import { DetailDataProvider } from './store/detailDataContext';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './theme';
import ListView from './pages/listView';
import DetailView from './pages/detailView';
import { ToastContainer } from 'react-toastify';
import SimpleContainer from './atoms/SimpleContainer';
import 'react-toastify/dist/ReactToastify.css';
import Box from '@mui/material/Box';
import Register from './pages/register';
import CustomPage from './pages/customPage';
//import QuickUkol from './pages/quickUkol';


function ProtectedRoutes() {
  return (
    <GenProvider>
     
        <StoreProvider>
          <Box sx={{ position: 'sticky', top: 0, zIndex: 100, background: 'white' }}>
            <Header />
          </Box>
          <Routes>
            <Route path="/logout" element={<Logout />} />
            <Route path="/home" element={<HomePage />} />
            <Route path="/board-view" element={<BoardView />} />
            <Route path="/list-view" element={<ListView />} />
            <Route path="/detail-view" element={
              <DetailDataProvider>
                <DetailView />
              </DetailDataProvider>
            } />
            <Route path="/customPage" element={
              <DetailDataProvider>
                <CustomPage />
              </DetailDataProvider>
            } />
            {/* Další routy */}
          </Routes>
        </StoreProvider>
     
    </GenProvider>
  );
}

function App() {
  return (
    
      <Router>
        <ThemeProvider theme={theme}>
          
              <div className="App">
                <SimpleContainer>
                  <Routes>
                    <Route path="/register" element={<Register />} />
                    <Route path="/login" element={<Login />} />
                                      
                    <Route path="/" element={<Navigate to="/board-view" />} />
                    <Route path="*" element={<ProtectedRoutes />} />
                  </Routes>
                </SimpleContainer>
              </div>
           
        </ThemeProvider>
      </Router>
    
  );
}

export default App;

/*
function ProtectedRoutes() {
  return (
    <StoreProvider>
      
        <Routes>
          <Route path="/home" element={<HomePage />} />
          <Route path="/board-view" element={<BoardView />} />
          <Route path="/list-view" element={<ListView />} />
          <Route path="/detail-view" element={
            <DetailDataProvider>
              <DetailView />
            </DetailDataProvider>
          } />
          <Route path="/customPage" element={
            <DetailDataProvider>
              <CustomPage />
            </DetailDataProvider>
          } />
          
          </Routes>
      
      
          </StoreProvider>
        );
      }
      
      function App() {
        return (
          <Router>
            <ThemeProvider theme={theme}>
              <GenProvider>
              <QuickDataProvider>
                  <div className="App">
                    <Box sx={{ position: 'sticky', top: 0, zIndex: 100, background: 'white' }}>
                      <Header />
                    </Box>
                    <SimpleContainer>
                      <Routes>
                        <Route path="/register" element={<Register />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/logout" element={<Logout />} />
                        <Route path="/quickUkol" element={<QuickUkol />} />
                        <Route path="/" element={<Navigate to="/login" />} />
                        <Route path="*" element={<ProtectedRoutes />} />
                      </Routes>
                    </SimpleContainer>
                  </div>
              </QuickDataProvider>
              </GenProvider>
            </ThemeProvider>
          </Router>
        );
      }
      */
