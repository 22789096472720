//HledatComponent.jsx
import React, {useContext, useState, useEffect} from 'react';
import { DataContext } from '../store/baseData';

import LinearIndeterminate from '../atoms/LinearIndeterminate';
import { Box, TextField, Button, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import theme from '../theme';
import DateTimeForm1 from '../atoms/DateTimeForm1';
import LookupQuick from './LookupQuick';
import dayjs from 'dayjs';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import moment from 'moment';



//createDefault(paramsL[j], header, dataDefaultGlobOne, placement, paramsL[j].zdroj, paramsL[j].zdrojParametr);
//param, header, rowData, placementRef 
const UkolQuickComponent = ({ userEmail, tableEntita, idUn, page, onBodyChange }) => {
    //console.log('v UkolQuickComponent - userEmail', userEmail, 'tableEntita', tableEntita, 'idUn', idUn, 'page', page);
    
    const { contactsGlob } = useContext(DataContext);
    
    
    //console.log('v UkolQuickComponent - contactsGlob', contactsQuick);

    const [creater, setCreater] = useState(userEmail);
    const [name, setName] = useState('');
    const navigate = useNavigate();
    const [message, setMessage] = useState(null);
    const [severity, setSeverity] = useState(null);
    const [checked, setChecked] = useState(false);
    
    const [resitel, SetResitel] = useState(userEmail);
    const [tableEntitaState, setTableEntitaState] = useState(tableEntita? tableEntita : '');
    const [idUnState, setIdUnState] = useState(idUn? idUn : '')
    const [popisUkol, setPopisUkol] = useState('');
    
    const [isChanged, setIsChanged] = useState(false);
    let contactsEmailList = [];
    if(contactsGlob){        
        contactsEmailList = contactsGlob.map(contact => contact.Email);
        //console.log('v UkolQuickComponent - contactsEmailList', contactsEmailList);
    }

    
    const paramDeadline = {
        id: '1653',
        parametr: 'dateDeadline',
        vizu: 'Deadline',
        input: 'date',
        zdroj: 'user',
        zdrojParametr: 'dateDeadline',
        zdrojParRidicihoPar: '',
        ridiciParametr: '',

    }

    const paramResitel = {
        id: '1656',
        parametr: 'resitel',
        vizu: 'email řešitele',
        input: 'lookup',
        zdroj: 'user',
        zdrojParametr: 'email',
        zdrojParRidicihoPar: '',
        ridiciParametr: '',

    }

    const paramDateCreate = {
        id: '1652',
        parametr: 'dateCreate',
        vizu: 'Datum vytvoření',
        input: 'date',
        zdroj: 'user',
        zdrojParametr: 'dateCreate',
        zdrojParRidicihoPar: '',
        ridiciParametr: '',
    }

    const [deadLine, setDeadLine] = useState({[paramDeadline.id]:dayjs().add(7, 'day').format('YYYY-MM-DD')});
    const [dateCreate, setDateCreate] = useState({[paramDateCreate.id]: dayjs().format('YYYY-MM-DD')});
    //console.log('v UkolQuickComponent - dateCreate', dateCreate);


    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log('v UkolQuickComponent - handleSubmit - deadLine', deadLine[paramDeadline.id], 'dateCreate', dateCreate[paramDateCreate.id], 'tableEntitaState', tableEntitaState, 'idUnState', idUnState, 'resitel', resitel, 'checked', checked, 'creater', creater, 'name', name, 'popisUkol', popisUkol);

        //let dateDeadlineM = moment(deadLine[paramDeadline.id]);
        //let formattedDateDeadline = dateDeadlineM.format('YYYY-MM-DD HH:mm:ss');
        //let dateCreateM = moment(dateCreate[paramDateCreate.id]);
        //let formattedDateCreate = dateCreateM.format('YYYY-MM-DD HH:mm:ss');
        

        const body = { 
            name: name, 
            creater: creater, 
            dateDeadline: dayjs(deadLine[paramDeadline.id]).format('YYYY-MM-DD HH:mm:ss'),
            resitel: resitel, 
            sendEmail: checked, 
            dateCreate: dayjs(dateCreate[paramDateCreate.id]).format('YYYY-MM-DD HH:mm:ss'), 
            entitaUkol: tableEntitaState, 
            idUnUkol: idUnState,
            procesUkol: '01 Zadáno',
            popisUkol: popisUkol
        }
        onBodyChange(body);
      

    };

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };
        
    return (
        <Box
            sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                justifyContent: 'center', 
                alignItems: 'center', 
                
            }}
        >
            <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{
                    
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    bgcolor: 'background.paper',
                    boxShadow: 1,
                    borderRadius: 1,
                    p: 2,
                    
                }}
            >

                <Button
                type="submit" variant="contained" color="primary"
                sx={{ 
                    backgroundColor: theme.palette.secondary.dark,
                    fontWeight: 700,
                    margin: '20px',
                    '&:hover': {
                      backgroundColor: theme.palette.secondary.main, // Nastaví barvu pozadí při najetí myší na stejnou barvu jako je výchozí
                    },
                  }}
                >
                    Zadat úkol
                </Button>
                {message && (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <Alert variant="outlined" severity={severity} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 70, width: 350, overflow: 'auto', backgroundColor: 'white' }}>
                            {message}
                        </Alert>
                        </div>
                )}
                
                <TextField
                    type="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    label="Název úkolu"
                    variant="outlined"
                    sx={{ 
                        width: 300,
                        height: 43,
                        margin: '5px 0 10px 0',
                    }}
                    InputProps={{
                        sx: {
                            width: '300px',
                            '.MuiInputBase-input': {
                            height: '10px',
                            },
                        },
                    }}
                />

                <DateTimeForm1
                    param={paramDeadline} // hodnota ze stavu
                    selectedDates={deadLine}
                    setSelectedDates={setDeadLine}
                    setIsChanged={setIsChanged}
                            
                />

                <LookupQuick
                    type="resitel"
                    param={paramResitel}
                    lookupList={contactsEmailList}
                    selectedInput={resitel}
                    setSelectedInput={SetResitel}
                    
                />

                <TextField
                    type="popis"
                    value={popisUkol}
                    onChange={(e) => setPopisUkol(e.target.value)}
                    label="Popis úkolu"
                    variant="outlined"
                    sx={{ 
                        width: 300,
                       
                        margin: '5px 0 10px 0',
                    }}
                    multiline
                    rows={4}
                    
                />

                <FormControlLabel
                control={
                    <Switch
                    checked={checked}
                    onChange={handleChange}
                    color="secondary" // Toto způsobí, že Switch použije barvu sekundárního tématu
                    />
                }
                label="Poslat také emailem"
                />

                <DateTimeForm1
                    param={paramDateCreate} // hodnota ze stavu
                    selectedDates={dateCreate}
                    setSelectedDates={setDateCreate}
                    setIsChanged={setIsChanged}
                            
                />
                
                <TextField
                    type="tableEntita"
                    value={tableEntitaState}
                    onChange={(e) => setTableEntitaState(e.target.value)}
                    label="Řešená entita"
                    variant="outlined"
                    
                    sx={{ 
                        width: 300,
                        height: 43,
                        margin: '0px 0 10px 0',
                    }}
                    InputProps={{
                        sx: {
                            width: '300px',
                            '.MuiInputBase-input': {
                            height: '10px',
                            },
                        },
                    }}
                />

                <TextField
                    type="idUn"
                    value={idUnState}
                    onChange={(e) => setIdUnState(e.target.value)}
                    label="Řešená položka"
                    variant="outlined"
                    
                    sx={{ 
                        width: 300,
                        height: 43,
                        margin: '10px 0 10px 0',
                    }}
                    InputProps={{
                        sx: {
                            width: '300px',
                            '.MuiInputBase-input': {
                            height: '10px',
                            },
                        },
                    }}
                />
                
                <TextField
                    type="creater"
                    value={creater}
                    onChange={(e) => setCreater(e.target.value)}
                    label="Zadavatel"
                    variant="outlined"
                    
                    sx={{ 
                        width: 300,
                        height: 43,
                        margin: '10px 0 20px 0',
                    }}
                    InputProps={{
                        sx: {
                            width: '300px',
                            '.MuiInputBase-input': {
                            height: '10px',
                            },
                        },
                    }}
                />


                                                     
                
                
            </Box>
        </Box>
    ); 

    
        
    
    
};

export default UkolQuickComponent;
