// DateComponent.jsx
import React, { useState, useEffect, useContext } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { DetailDataContext } from '../store/detailDataContext';
import 'react-datetime/css/react-datetime.css';
import Button from '@mui/material/Button';
import DateTimeForm1 from '../atoms/DateTimeForm1';

import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';


const DateComponent = ({ param, placementRef }) => {
    //const { selectedDates, setSelectedDates } = useContext(DetailDataContext);
    
    const {
        selectedDates,
        setSelectedDates,
        detailDataNutne,
        isChanged,
        setIsChanged,
        
    } = useContext(DetailDataContext); // Získání hodnot z kontextu

    const selectedDate = selectedDates?.[param.id];
    


    //const selectedDate = selectedDates?.[param.id];
    
    const rowData = detailDataNutne.dataGlob[0]; // Získání dat z detailDataContext
    const paramsL = detailDataNutne.paramsLGlob; // Získání dat z detailDataContext
    const headers = detailDataNutne.headers; // Získání dat z detailDataContext
    const header = param.parametr;

    //console.log('v DateComponent - rowData[param.parametr]', param.id, param.vizu, rowData[param.parametr]);  
    //console.log('v DateComponent - pred useEffect - selectedDates', param.id, selectedDates);
   //console.log('v DateComponent - pred useEffect - selectedDate', param.id, selectedDate, rowData[param.parametr]);
        
    useEffect(() => {
        //console.log('000000 - v DateComponent - useEffect: ', param.vizu);
        if (detailDataNutne) {

            if (selectedDate == null && param && param.parametr && rowData && rowData[param.parametr]) {
            
                setSelectedDates(prevValues => ({
                    ...prevValues,
                    [param.id]: rowData[param.parametr],
                }));
            
            }
        }
        
        
        //console.log('v DateComponent - selectedDate:', selectedDate, param.vizu, param.id, param.parametr, rowData[param.parametr]);
                
    }, [param, placementRef, detailDataNutne]);

    return (
        <DateTimeForm1
        param={param} // hodnota ze stavu
        selectedDates={selectedDates}
        setSelectedDates={setSelectedDates}
        setIsChanged={setIsChanged}
                
      />
      );

};

export default DateComponent;

