//RestComponent.jsx
import React, { useState, useEffect, useContext, useCallback } from 'react';
import { DetailDataContext } from '../store/detailDataContext';
import RestForm from '../atoms/RestForm';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { set } from 'date-fns';
import FormatCZK from '../atoms/FormatCZK';
import unformatCZK from '../atoms/UnFormatCZK';
import dayjs from 'dayjs'; 
import { styled } from '@mui/material/styles';
import { FormControlLabel, Switch } from '@mui/material';
import { is } from 'date-fns/locale';

//jen koment

const BoolComponent = ({ param, placementRef }) => {
  //console.log('v BoolComponent - param', param);
    
    const {
        inputRests,
        setInputRests,
        detailDataNutne,
        isChanged,
        setIsChanged
    } = useContext(DetailDataContext); // Získání hodnot z kontextu

    const [edited, setEdited] = useState(false);
    
    const [inputValue, setInputValue] = useState(''); // Neformátovaná hodnota
    const [formattedInputValue, setFormattedInputValue] = useState(''); // Formátovaná hodnota pro zobrazení

    const rowData = detailDataNutne.dataGlob[0]; // Získání dat z detailDataContext
    //console.log('000 - v BoolComponent - rowData[param.parametr]', param.id, param.vizu, param.parametr, rowData[param.parametr]);
    
    
    //inputRest = inputRests?.[param.id] || true;  
   
    const inputRest = inputRests?.[param.id] || false;
   
    
    //console.log('00 - v BoolComponent - inputRests?.[param.id]', inputRests?.[param.id], 'param.id', param.id, 'pro parametr: ', param.vizu, 'paramsL.input', param.input, 'inputRests', inputRests)
    
    useEffect(() => {
      //console.log('000000 - v DateComponent - useEffect: ', param.vizu);
      
        console.log('01 - v RestComponent - useEffect', 'inputRests?.[param.id]', inputRests?.[param.id], 'inputRest', inputRest);
        if (!inputRests?.[param.id] && rowData[param.parametr] !== undefined && rowData[param.parametr] !== null && !isChanged[param.id]) {
            //console.log('02 - v RestComponent - useEffect - po if', 'inputRests?.[param.id]', inputRests?.[param.id], 'inputRest', inputRest, 'rowData[param.parametr]', rowData[param.parametr]);
            
            setInputRests(prevValues => ({
                  ...prevValues,
                  [param.id]: rowData[param.parametr] === 1 ? true : false,
              }));
              setIsChanged(prevState => ({ ...prevState, [param.id]: false }));
        }
      
              
    }, [param, placementRef, rowData, detailDataNutne]);
    
    //console.log('03 - v BoolComponent - inputRests', inputRests);
  
  const handleInputChange = (event) => {
    let rawValue = event.target.checked;

       
      setInputRests(prevValues => ({
          ...prevValues,
          [param.id]: rawValue,
      }));
      setEdited(true); // Resetujte editedDef při editaci
      setIsChanged(prevState => ({ ...prevState, [param.id]: true }));
      //console.log('04 - v BoolComponent - rawValue', rawValue, 'pro parametr: ', param.vizu, 'paramsL.input', param.input, inputRests[param.id] )
  };
  
  //console.log('05 - v BoolComponent - inputRest', inputRest);
  //console.log('06 - v BoolComponent - inputRests', inputRests);
    //----------------------------
  
  //console.log('detailDataNutne.dataGlob[0][param.vizuSlave]', detailDataNutne.dataGlob[0]?.[param.vizuSlave])
  //console.log('param.vizuSlaveValue', param.vizuSlaveValue)
    
    return (
      
      (detailDataNutne.dataGlob[0]?.['zobrazVse'] || !param.vizuSlave || param.vizuSlaveValue == detailDataNutne.dataGlob[0]?.[param.vizuSlave] || (detailDataNutne.dataGlob[0]?.[param.vizuSlave] == null && param.vizuSlaveValue == 0)) ? (
      <FormControlLabel
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: 'calc(100% - 0px)', // odebere 10px ze šířky, 5px pro každou stranu
          padding: '0 18px', // přidá padding 5px na obě strany
          boxSizing: 'border-box', // zaručí, že padding a border jsou zahrnuty do celkové šířky
        }}
        control={
          <Switch
            checked={inputRest}
            onChange={handleInputChange}
            color="secondary"
          />
        }
        label={param.vizu}
      />
      ) : (null)


    );
};

export default BoolComponent;