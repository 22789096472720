// V souboru detailView.jsx
import React, { useState, useEffect, useContext, useRef, createRef, useCallback} from 'react';
import { useNavigate } from 'react-router-dom';
//import { detailData } from '../api/detailData';
//import DetailRenderInput from '../organisms/detailRenderInput';
import PlacementComponent from '../molecules/PlacementComponent';
import DetailSectionComponent from '../molecules/DetailSectionComponent';
import { DataContext } from '../store/baseData';
import { GenContext } from '../store/genData';
import { DetailDataContext } from '../store/detailDataContext'; // Import DetailDataProvider
import { styled } from '@mui/material/styles';

import { save } from '../api/save';

import Button from '@mui/material/Button';

import SimpleContainer from '../atoms/SimpleContainer';

import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import  LinearIndeterminate from '../atoms/LinearIndeterminate';
import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import { addItem } from '../api/apiRest';
import Alert from '@mui/material/Alert';
import { webhook } from '../api/webhook';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { getDataList, checkTaskStatus } from '../api/apiRest';
import DataTable from '../organisms/listTableMui1'; // Importujte komponentu DataTable
import SaveIcon from '@mui/icons-material/Save';
import KalkulaceTable from '../atoms/KalkulaceTable';
import UpdateIcon from '@mui/icons-material/Update';
import ProcesGantt from '../atoms/ProcesGantt';


const DetailView = () => {
    
    const divbtnsRef = useRef(null);
    const [entityTitle, setEntityTitle] = useState('Načítám data...');
    const [placementRefs, setPlacementRefs] = useState({});
    const [message, setMessage] = useState(null);
    const [messageWebhook, setMessageWebhook] = useState(null);
    const [severity, setSeverity] = useState('success');
    const [severityWebhook, setSeverityWebhook] = useState('success');  
   
    //console.log('00 - v detailView - paramsLAllAll', paramsLAllAll);
    const isHeaderMenuVisible = useContext(GenContext).isHeaderMenuVisible;
    const setIsHeaderMenuVisible = useContext(GenContext).setIsHeaderMenuVisible;
    const [loading, setLoading] = useState(false);
    const [modalUkolListIsOpen, setModaUkolListlIsOpen] = useState(false);
    const [dataUkol, setDataUkol] = useState([]);
    const [dataKalkulace, setDataKalkulace] = useState([]);

    //kvuli volani funkce spocitej v KalulaceTable
    const [showTable, setShowTable] = useState(false);
    const [shouldCalculate, setShouldCalculate] = useState(false);
    const [calculationDone, setCalculationDone] = useState(false);

    //const [headersKalkulace, setHeadersKalkulace] = useState([]);
    //const [command, setCommand] = useState(''); const token = localStorage.getItem('token');
    
    const [headersUkol, setHeadersUkol] = useState([]);
    const [mjsUkol, setMjsUkol] = useState([]);
    const [inputFormatUkol, setInputFormatUkol] = useState([]);
    const [headersParamUkol, setHeadersParamUkol] = useState([]);
    const [dataEntitaUkol, setDataEntitaUkol] = useState([]);
    const [dataEntitaUkolIdUn, setDataEntitaUkolIdUn] = useState([]);
    
    useEffect(() => {
        setIsHeaderMenuVisible(true);
    }, [setIsHeaderMenuVisible]);
    
    const navigate = useNavigate(); // Inicializace useNavigate
    const theme = useTheme();
            
    const {
        saveSuccessful,
        setSaveSuccessful,
        webhookSuccessful,
        setWebhookSuccessful,
        tableEntita,
        page,
        idUn,
        entita,
        aktualDetailSection,
        detailDataNutne,
        detailDataRest,
        inputDefaults,
        inputEditLongs,
        inputRests,
        inputCalc,
        inputLookups,
        inputFiles,
        selectedDates,
        isChanged,
        setIsChanged,
        inputChanged,
        setInputChanged,
        pole1Data,
        setPole1Data,
        idKalkulace,
        inputsAll,
        setInputsAll
    } = useContext(DetailDataContext); // Získání hodnot z kontextu
    const { sectionsGlob, structureGlob, contactsGlob, ciselnikGlob, paramsLAllAll, ukol} = useContext(DataContext);

    

    const dataNutne = detailDataNutne; // Získání dat z detailDataContext
    console.log('00000 - v detailView - detailDataNutne', detailDataNutne);
    //console.log('00000 - v detailView - detailDataRest', detailDataRest);
    const rowData = detailDataNutne?.dataGlob?.[0]; // Získání dat z detailDataContext
    const paramsL = detailDataNutne?.paramsLGlob; // Získání dat z detailDataContext
    const headers = detailDataNutne?.headers; // Získání dat z detailDataContext
    const dataSablona = detailDataRest?.sablonaListGlob; // Získání dat z detailDataContext
    const detailSectionsGlob = detailDataNutne?.detailSectionsGlob; // Získání dat z detailDataContext
    const dataDefaultGlob = detailDataRest?.dataDefaultGlob; // Získání dat z detailDataContext
    const paramsLKalkulace = paramsLAllAll.filter(param => param.tableEntita == 'Kalkulace');
    const paramsLProcesMapa = paramsLAllAll.filter(param => param.tableEntita == 'ProcesMapa');
    const rowDataAllKalkulace = detailDataRest?.dataDefaultGlob.default.Kalkulace || '';
    const rowDataAllProcesMapa = detailDataRest?.dataDefaultGlob.default.ProcesMapa || '';
    //console.log('00000 - v detailView - rowDataAllKalkulace: ', rowDataAllKalkulace );
    
    //----parametry pro Ukol------
    /*
    const fetchData = useCallback(async () => {
        try {
            const token = localStorage.getItem('token');
            
            //let dataUkolRes = await getDataList('Ukol', 'intranet', token);

            let response1 = await getDataList('Ukol', 'intranet', token);
            const taskId1 = response1.taskId;
            const dataUkolRes = await checkTaskStatus(taskId1, 'detailView');
            
            setDataUkol(dataUkolRes.data);
            //console.log('v listView dataList', dataList);
            
    
        } catch (error) {
            console.error('Chyba při načítání dat:', error);
            console.log('nastal session problem na serveru, user bude odhlasen ', error.message);
            if (error.message.includes('/logout') || error.message.includes('Invalid Token')) {
                window.location.href = '/logout';
            }
        }
    
      }, [aktualDetailSection]); // Závislosti pro useCallback
    
          
      useEffect(() => {
          fetchData();
    }, [fetchData, ]);
    */

    useEffect(() => {
        if(paramsLAllAll.length > 0 && ukol.length > 0) {
            const paramsLUkol = paramsLAllAll.filter(param => param.tableEntita == 'Ukol');
            setHeadersUkol(paramsLUkol.map(objekt => objekt.vizu));
            setMjsUkol(paramsLUkol.map(objekt => objekt.mj));
            setInputFormatUkol(paramsLUkol.map(objekt => objekt.input));
            setHeadersParamUkol(paramsLUkol.map(objekt => objekt.parametr));
            const dataEntitaUkolTemp = ukol.filter((ukol1) => ukol1.entitaUkol === tableEntita);
            setDataEntitaUkol(dataEntitaUkolTemp);
            setDataEntitaUkolIdUn(dataEntitaUkolTemp.filter((ukol2) => ukol2.idUnUkol == idUn)); 
        }
    }, [paramsLAllAll, ukol]); // Dependencies for useEffect
    
    //console.log('999999999999 - v DetailView - parametry pro Ukol', headersUkol, mjsUkol, inputFormatUkol, headersParamUkol, dataEntitaUkol, idUn, dataEntitaUkolIdUn);

    //----konec parametry pro Ukol------------------------------------


    

    
    useEffect(() => {
        
        setInputsAll({ 
            ...(inputDefaults || {}), 
            ...(inputEditLongs || {}), 
            ...(inputLookups || {}), 
            ...(inputRests || {}),
            ...(pole1Data || []),
            ...(inputCalc || [])
            //...(inputFiles || {}), 
            //...(selectedDates || {}) 
        });
        console.log('111111 v DetailView - inputsALL', inputsAll);
        console.log('111111 v DetailView - inputRests', inputRests);
        console.log('111111 v DetailView - inputLookups', inputLookups);
        console.log('111111 v DetailView - inputCalc', inputCalc)
        console.log('111111 v DetailView - pole1Data', pole1Data);
        console.log('111111 v DetailView - inputDefaults', inputDefaults);


        //console.log('111111 v DetailView - k ulozeni?', isChanged);
    }, [inputDefaults, inputEditLongs, inputLookups, inputFiles, selectedDates, inputRests, inputCalc, pole1Data, isChanged]);
    




console.log ('222222222 v detailView - inputsAll', inputsAll);    
// Tento kód projde všechny refy v newPlacementRefs, získá jejich aktuální hodnoty a uloží je do objektu data. Poté můžete data uložit jak potřebujete.
    async function handleSave(command) {
        //command = 'save';
        try {
            console.log('v detailView - handleSave - kliknuto');
            console.log('v detailView - handleSave - shromazdena data z cele detailView - inputsAll', inputsAll);
            console.log('v detailView - handleSave - shromazdena data z cele detailView - inputFiles', inputFiles);
            console.log('v detailView - handleSave - shromazdena data z cele detailView - selectedDates', selectedDates);
            console.log('v detailView - handleSave - shromazdena data z cele detailView - command', command);
            setIsChanged(false);
            setInputChanged(false);
            

            //-----------------ulozeni dat-------------

            const saved = await save(tableEntita, idUn, inputsAll, paramsL, inputFiles, selectedDates, command, idKalkulace, paramsLKalkulace, rowDataAllKalkulace, rowDataAllProcesMapa, paramsLProcesMapa);
            console.log('00000 - v detailView - handleSave - vraceno z save procesu:', saved)
            if (saved.error) {
                console.error('Error: ', saved.error);
                setMessage(saved.error);
                setSeverity('error');
            } else if(command=='save' && saved) {
                console.log('kontrola ulozeni: ', saved)
                setMessage(`Uloženo ${saved.insertId}`);
                setSeverity('success');
                setSaveSuccessful(true);
                console.log('v detailView - prepl jsem saveSuccessful', saveSuccessful)
            }else if(command=='dupl' && saved) {
                console.log('kontrola duplikace: ', saved)
                console.log(`/detail-view?tableEntita=${tableEntita}&idUn=${saved.insertId}&entita=${tableEntita}&page=${page}`);
                window.open(`/detail-view?tableEntita=${tableEntita}&idUn=${saved.insertId}&entita=${tableEntita}&page=${page}`, '_blank');


            }

            // Close the alert after 5 seconds
            setTimeout(() => {
                setMessage(null);
            }, 1000);
            
            //-----------------ulozeni dat-------------

            //-----------------webhook-------------
            setLoading(true);
            const webhookRes = await webhook(tableEntita, idUn, paramsL, inputRests);
            setLoading(false);

            console.log(webhookRes)
            if (webhookRes.result.success==false || webhookRes.error) {
                console.error('Error: ', webhookRes.result.message, webhookRes.result.success);
                setMessageWebhook(['Chyba:', webhookRes.result.message]);
                setSeverityWebhook('error');
            } else if( webhookRes.result.success==true ){
                console.log('kontrola: ', webhookRes)
                setMessageWebhook([
                    `Outlook: ${webhookRes.result.message.MS}`,
                    `Google: ${webhookRes.result.message.GC}`
                ]);
                setSeverityWebhook('success');
                setWebhookSuccessful(true);
                console.log('v detailView - webhookSuccessful', webhookSuccessful)
            } else if(webhookRes.result.success=='NA'){
                console.log('kontrola: ', webhookRes)
                setMessageWebhook(['Oznámení:', webhookRes.result.message]);
                setSeverityWebhook('success');
                setWebhookSuccessful(true);
                // Close the alert after 5 seconds
                setTimeout(() => {
                    setMessageWebhook(null);
                }, 1000);
            }

            // Close the alert after 5 seconds
            setTimeout(() => {
                setMessageWebhook(null);
            }, 7000);

            //-----------------webhook-------------

        } catch (error) {
            console.error(`Error: ${error.message}`);
            console.log('nastal session problem na serveru, user bude odhlasen ', error.message);
            if (error.message.includes('/logout') || error.message.includes('Invalid Token')) {
                window.location.href = '/logout';
            }
        }
      
    }

    async function handleAddItem() {
        
        try {
            console.log('v detailView - addItem - kliknuto', tableEntita);
            const token = localStorage.getItem('token');
            const saved = await addItem(tableEntita, token);
            console.log('v detailView - addItem - saved')

            if (saved.error) {
                console.error('Error: ', saved.error);
                setMessage(saved.error);
                setSeverity('error');
            } else {
                console.log('kontrola: ', saved);
                //setMessage(`Založena nová položka ${saved}`);
                setSeverity('success');
                console.log(`/detail-view?tableEntita=${tableEntita}&idUn=${saved}&entita=${tableEntita}&page=${page}`);
                window.open(`/detail-view?tableEntita=${tableEntita}&idUn=${saved}&entita=${tableEntita}&page=${page}`, '_blank');
            }
             // Close the alert after 5 seconds
             setTimeout(() => {
                setMessage(null);
            }, 5000);
           
        } catch (error) {
            console.error(`Error: ${error.message}`);
            setMessage(error.message);
            setSeverity('error');
        }
      
    }

    const handleEntityClick = () => {
        // Implementace navigace
        // Můžete použít 'useNavigate' z 'react-router-dom' nebo jiný způsob navigace
        //window.location.href = `/list-view?tableEntita=${tableEntita}&entita=${entita}&page=${page}`;
        navigate(`/list-view?tableEntita=${tableEntita}&entita=${entita}&page=${page}`);
    };

    
    
    const favicon = '/enadoSlunicko.png'

    useEffect(() => {
        
    }, []);

    useEffect(() => {
        if (dataNutne) {
            //console.log('000000 - v DetailView - useEffect: ', rowData.name);
            //console.log('v detailView - detailData', detailData);   
            const entityTitleResult = dataNutne.structurEntGlob[0].entitaOne + ' - ' + rowData.id + ' - ' + rowData.name;
   
            const newPlacementRefs = {}; //Vytvoří prázdný objekt newPlacementRefs. Do tohoto objektu se budou ukládat reference na jednotlivé placementy.
            let headIndex = 0; //Inkrementuje hodnotu headIndex o jedna v každé iteraci hlavního cyklu
            for (let header of headers) {
                headIndex++;
                for (let j = 0; j < paramsL.length; j++) {
                    if (paramsL[j].parametr == header) {
                        const placementy = paramsL[j].idPlacement; //ytvoří konstantu placementy, která je nastavena na hodnotu idPlacement aktuálního prvku v poli paramsL.
                        newPlacementRefs[placementy] = createRef(); //Vytvoří novou referenci pomocí React.createRef() a přiřadí ji do objektu newPlacementRefs pod klíčem placementy
                    }
                }
            }

            setEntityTitle(entityTitleResult);
            setPlacementRefs(newPlacementRefs);

            document.title = entityTitle;
            // Set favicon
            const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
            link.type = 'image/x-icon';
            link.rel = 'shortcut icon';
            link.href = favicon;
            document.getElementsByTagName('head')[0].appendChild(link);


        }
        

        if (page !== 'intranet') {
            if (divbtnsRef.current) {
                divbtnsRef.current.setAttribute('hidden', 'true');
            }
        }

        

        
    }, [dataNutne, favicon, entityTitle]);

       
    function ButtonFce({ isChanged, handleClick, children }) {
        return (
            <Button
                variant="contained"
                type="button"
                sx={{ 
                    fontWeight: 700,
                    height: '34px',
                    ':hover': {
                        backgroundColor: theme.palette.secondary.main
                    },
                    backgroundColor: theme.palette.secondary.dark,
                    margin: theme.spacing(2), // Nastaví odstup na všechny strany tlačítka
                    
                }}
                style={{ backgroundColor: isChanged ? theme.palette.secondary.dark : theme.palette.primary.main }} // Použijte barvy z theme
                onClick={handleClick}
            >
                {children}
            </Button>
        );
    }

    const spocitejVKalkulaceTable = () => {
        if (tableEntita === 'Kalkulace') {
          setShowTable(true);
          setShouldCalculate(true);
        }
    };

    const handleCalculationDone = () => {
        setCalculationDone(true);
        setShowTable(false);
        if(message === null) {
            setMessage(`Přepočítání proběhlo úspěšně`);
            setSeverity('success');
            // Close the alert after 5 seconds
            setTimeout(() => {
                setMessage(null);
            }, 1000);
        }
      };
   
    
    
   // In your render function
if (!dataNutne) {
    return <LinearIndeterminate/>
} else {
    return (
        <SimpleContainer>
            <Box 
                sx={{ 
                    display: 'flex', 
                    flexDirection: { xs: 'column', md: 'row' }, 
                    justifyContent: 'center', 
                    alignItems: 'center',
                    borderBottom: `1px solid ${theme.palette.secondary.dark}`,
                    '&:hover': {
                        borderBottom: `2px solid ${theme.palette.secondary.main}`,
                    },
                }}
            >
                <Box 
                    sx={{ 
                        order: { xs: 2, md: 1 }, 
                        marginRight: { md: 2 }
                    }}
                >
                    <Box 
                        sx={{ 
                            display: 'flex', 
                            justifyContent: 'space-between', 
                            alignItems: 'center',
                            width: '220px',
                        }}
                    >
                        {/*
                        <ButtonFce 
                            isChanged={isChanged} 
                            handleClick={() => {
                                handleSave('save');
                            }}
                        >
                            Uložit
                        </ButtonFce>
                        */}

                        <IconButton
                            
                            onClick={() => {
                                handleSave('save');
                            }}
                            sx={{ 
                                margin: '15px',
                                marginLeft: '20px',
                                backgroundColor: isChanged ? theme.palette.secondary.dark : theme.palette.primary.main,
                                fontWeight: 700,
                                '&:hover': {
                                    backgroundColor: isChanged ? theme.palette.secondary.main : theme.palette.primary.dark,
                                },
                            }}
                        >
                            <SaveIcon
                                sx={{ 
                                    color: 'white', 
                                    fontSize: '22px'
                                }}
                            />
                        </IconButton>

                        <IconButton
                            onClick={() => {
                                handleSave('dupl');
                            }}
                            sx={{ 
                                margin: '15px',
                                backgroundColor: theme.palette.secondary.dark,
                                fontWeight: 700,
                                '&:hover': {
                                    backgroundColor: theme.palette.secondary.main,
                                },
                            }}
                        >
                            <FileCopyIcon
                                sx={{ 
                                    color: 'white', 
                                    fontSize: '22px'
                                }}
                            />
                        </IconButton>
                        
                        <IconButton
                            onClick={handleAddItem}
                            sx={{ 
                                margin: '15px',
                                backgroundColor: theme.palette.secondary.dark,
                                fontWeight: 700,
                                '&:hover': {
                                    backgroundColor: theme.palette.secondary.main,
                                },
                            }}
                        >
                            <AddIcon
                                sx={{ 
                                    color: 'white', 
                                    fontSize: '22px'
                                }}
                            />
                        </IconButton>

                        {aktualDetailSection !== 'List' && tableEntita === 'Kalkulace' && (
                            <IconButton
                                title="Přepočítej"
                                onClick={spocitejVKalkulaceTable}
                                sx={{ 
                                margin: '15px',
                                marginLeft: '12px',
                                backgroundColor: theme.palette.secondary.dark,
                                fontWeight: 700,
                                '&:hover': {
                                    backgroundColor: theme.palette.secondary.main,
                                },
                                }}
                            >
                                <UpdateIcon
                                sx={{ 
                                    color: 'white', 
                                    fontSize: '22px'
                                }}
                                />
                            </IconButton>
                        )}
                        {showTable && <KalkulaceTable shouldCalculate={shouldCalculate} onCalculationDone={handleCalculationDone} />}
                    </Box>
                    {loading && <LinearIndeterminate />}
                    {message && (
                        <Alert variant="outlined" severity={severity} style={{ position: 'fixed', top: 10, left: `50%`,
                        transform: 'translate(-50%, -0%)', zIndex: 9999, height: 50, width: 300, overflow: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'white' }}>
                            {message}
                        </Alert>
                    )}
                    {messageWebhook && (
                        <Alert variant="outlined" severity={severityWebhook} style={{ position: 'fixed', top: 80, left: `50%`,
                        transform: 'translate(-50%, -0%)', zIndex: 9999, height: 100, width: 350, overflow: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'white' }}>
                            {messageWebhook.map((item, index) => (
                                <React.Fragment key={index}>
                                    {item}
                                    <br />
                                </React.Fragment>
                            ))}
                        </Alert>
                    )}
                </Box>
                <Box 
                    sx={{ 
                        order: { xs: 1, md: 2 }, 
                        textAlign: 'center', 
                        width: '100%',
                        marginRight: { xs: '0px', md: '400px' },
                    }}
                >
                    <Typography 
                        variant="h6" 
                        onClick={handleEntityClick}
                        sx={{
                            backgroundColor: 'none',
                            fontSize: '18px',
                            color: 'black',
                            fontWeight: 'bold',
                            padding: '2px 0px 8px 0',
                            cursor: 'pointer',
                            zIndex: '100',
                            textDecoration: 'none',
                            marginTop: '0px',
                            marginBottom: '0px',
                            width: '100%',
                        }}
                    >
                        {entityTitle}
                    </Typography>
                </Box>
            </Box>
                    
            <DetailSectionComponent className="detail-section"
                dataLookup={detailSectionsGlob}
            />
        
            <div style={{ position: 'sticky', top: 0 }}>
                {aktualDetailSection !== 'Úkoly' && aktualDetailSection !== 'List' && aktualDetailSection !== 'Mapa' &&
                <PlacementComponent 
                    placementRefs={placementRefs}
                />
                }
                {aktualDetailSection == 'Úkoly' && 
                    <DataTable tableEntita='Ukol' pageP={page} data={dataEntitaUkolIdUn} headers={headersUkol} columnAccessors={headersParamUkol} onRowClick={handleEntityClick} mjs={mjsUkol} inputFormat={inputFormatUkol} />
                }
                {aktualDetailSection == 'List' && dataKalkulace &&
                    <KalkulaceTable/>
                }
                {aktualDetailSection === 'Mapa' && 
                <ProcesGantt tableEntita={tableEntita} entryPoint={'detailProcesMapa'} />
                }
            </div>
            
                
           
        </SimpleContainer>
    );
}

}

export default DetailView;

