//HledatComponent.jsx
import React, {useContext, useState, useEffect} from 'react';
import { DetailDataContext } from '../store/detailDataContext';
import { DataContext } from '../store/baseData';
import { getData4, checkTaskStatus, paramsLLimit} from '../api/apiRest';
import DataTable from '../organisms/listTableMui1' // Importujte komponentu DataTable
import LinearIndeterminate from '../atoms/LinearIndeterminate';


//createDefault(paramsL[j], header, dataDefaultGlobOne, placement, paramsL[j].zdroj, paramsL[j].zdrojParametr);
//param, header, rowData, placementRef 
const HledatComponent = ({ param, onEntityClick }) => {
    // Přiřazení hodnoty inputu na základě dostupných dat
    
    const {
        tableEntita,
        page,
        idUn,
        detailDataNutne,
        detailDataRest
    } = useContext(DetailDataContext); // Získání hodnot z kontextu

    const { dataLookupOP, paramsLAllAll } = useContext(DataContext);
    
    const [headersParam, setHeadersParam] = useState(''); // definice stavu
    const [dataLookupKomplGlob, setDataLookupKomplGlob] = useState(''); // definice stavu
    const [zdroj, setZdroj] = useState(param.zdroj); // definice stavu
    const [dataZdroj, setDataZdroj] = useState('');
    const [mjs, setMjs] = useState([]); // jednotky
    const [inputFormat, setInputFormat] = useState([]); // format vstupu [date, number
    const [headers, setHeaders] = useState([]); // hlavicka tabulky
    
    const rowData = detailDataNutne.dataGlob[0]; // Získání dat z detailDataContext
    const paramsL = detailDataNutne.paramsLGlob; // Získání dat z detailDataContext
    const dataDefaultGlob = detailDataRest?.dataDefaultGlob; // Získání dat z detailDataContext
       
    const zdrojPar = param.zdrojParametr;
    const ridiciParametr = param.ridiciParametr;
    const zdrojParRidicihoPar = param.zdrojParRidicihoPar;
    const header = param.parametr;
    const inputName = header;
    //const dataZdroj = dataDefaultGlob.default[zdroj];// v dataDefaultGlob jsou pole dat vsech, ktere jsou zdrojovymi parametry

    var inputNameX
        if (ridiciParametr) { //pripad, kdy existuje vice variant vyberu z ciselniku, napr procesu Distribuce podle velikosti FVE
            const hodnRidpar = rowData[ridiciParametr]
            //console.log('dataGlob od ridiciho par:', hodnRidpar);
            inputNameX = header + '-' + hodnRidpar
            
        } else {
            inputNameX = header
        }
       
    useEffect(() => {
        const fetchData = async () => {
            let dataZdrojRes
            let dataLookupKomplGlobRes
            try {
                if(zdroj === 'OP') { // v pripade lookup kde je zdroj OP, tak se data nacitaji z detailDataRest, tj z dat ktera jsou jiz nastena 
                    dataLookupKomplGlobRes = dataLookupOP;
                }else{
                    const token = localStorage.getItem('token');
                    //console.log('v HledatComponent - start, vstupy: ', paramsL, idUn,'zdroj:', zdroj, inputNameX);
                    const response3 = await getData4(paramsL, 'lookup', idUn, zdroj, inputNameX, '', token); 
                    const taskId3 = response3.taskId
                    //console.log('v HledatComponent - taskId3', taskId3);
                    const dataLookupKomplGlobX = await checkTaskStatus(taskId3);
                    //console.log('v HledatComponent - dataLookupKomplGlobX', dataLookupKomplGlobX);
                    dataLookupKomplGlobRes = dataLookupKomplGlobX?.data;
                    //console.log('v HledatComponent - dataLookupKomplGlob', dataLookupKomplGlobRes);
                    //console.log('v HledatComponent - dataDefaultGlob', dataDefaultGlob, dataDefaultGlob.default);
                }
 
                if (dataDefaultGlob.default.length > 0) {
                    dataZdrojRes = dataDefaultGlob.default[zdroj];
                    //console.log('jedna se default plneni dalsich promenych', dataZdrojRes);
                } else {
                    dataZdrojRes = dataLookupKomplGlobRes.lookup[zdroj];
                    //console.log('jedna se jen lookup bez plneni dalsich default', dataZdrojRes);
                }
                
                const headersParamRes = Object.keys(dataZdrojRes[0]);
                //console.log('v HledatComponent - headersParamRes', headersParamRes);
                //const headersRes = paramsL.filter(item => item.parametr === headersParamRes).map(objekt => objekt.vizu);
                //console.log('v HledatComponent - headersRes', headersRes);
                
                //console.log('v HledatComponent - dataZdrojRes', dataZdrojRes);
    
                setHeadersParam(headersParamRes);
                //setHeaders(headersRes);
                setDataLookupKomplGlob(dataLookupKomplGlobRes);
                setZdroj(zdroj);
                setDataZdroj(dataZdrojRes);
            } catch (error) {
                console.error('Chyba při načítání dat:', error);
            }
        };
    
        fetchData();

        if (paramsLAllAll) {
            
            const paramsL = paramsLAllAll.filter(obj => obj.tableEntita === param.zdroj);    
            const paramsLL = paramsLLimit(paramsL, 'listViewTab');
            const headers = paramsLL.map(objekt => objekt.vizu);
            const mjs = paramsLL.map(objekt => objekt.mj);
            //const inputFormat = paramsLL.map(objekt => objekt.input);
            const inputFormat = paramsLL.map(objekt => objekt.format);
            const headersParam = paramsLL.map(objekt => objekt.parametr);
            
            setHeaders(headers);
            setMjs(mjs);
            setInputFormat(inputFormat);
            //setHeadersParam(headersParam);
        }
        
    }, [param]);

    // Vytvoření stavu pro id

    // Funkce ktera prijima id z tabulky a vraci funkci ktera nastavi stav
    const handleEntityClick = (tableEntita, entita, page, id) => {
        //console.log('v HledatComponent - handleEntityClick', tableEntita, entita, page, id);
        const val1 = dataZdroj.filter(item => id == item.id)[0][zdrojPar]; // filtruje data podle id a vraci hodnotu zdrojPar, zdrojPar je parametr, ktery se ma vratit do inputu
        //console.log('v HledatComponent - handleEntityClick - vstup do onEntityClick - val1', val1, 'id', id );

        
        onEntityClick(val1, id); // Toto zavolá handleEntityClick v LookupComp
        const value = id;
        
    };


    if (!dataLookupKomplGlob) {
        return <LinearIndeterminate/>;
    } else {
        return (
            <div>
                
                <DataTable 
                    tableEntita={tableEntita}
                    pageP={page}
                    data={dataZdroj}
                    headers={headersParam}
                    columnAccessors={headersParam}
                    onRowClick={handleEntityClick}
                    mjs={''} inputFormat={''}
                />
                
            </div>
        );
    }
    
};

export default HledatComponent;
