// PlacementComponent.jsx
import React, { useEffect, useRef, useState, useContext } from 'react';
import DateComponent from './DateComponent';
import DefaultComponent from './DefaultComponent';
import LookupComponent from './LookupComponent';
import FileComponent from './FileComponent';
import PoleComponent from './PoleComponent1';
import RestComponent from './RestComponent';
import LookupCiselnikComponent from './LookupCiselnikComponent';
import { styled } from '@mui/material/styles';
import { DetailDataContext } from '../store/detailDataContext'; // Import DetailDataProvider
import { Grid, Paper } from '@mui/material';
import { Box } from '@mui/system';
import BoolComponent from './BoolComponent';
import KalkulaceTable from '../atoms/KalkulaceTable';
import ProcesGantt from '../atoms/ProcesGantt';


const PlacementDiv = styled(Paper)(({ theme }) => ({
    m: 1,
    backgroundColor: theme.palette.primary.light,
    color: 'black',
    margin: '10px',
    padding: '5px',
    width: '330px',
    //boxShadow: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: 'white',
    },
  }));

  const PlacementHead = styled('div')(({ theme }) => ({
    fontWeight: 'bold',
    paddingBottom: '10px',
}));

const Item = styled(Paper)(({ theme }) => ({
    
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.primary,
    boxShadow: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));


const PlacementComponent = ({ placementRefs}) => {
    //console.log('00000 v PlacementComponent - aktualDetailSection', aktualDetailSection);
    const [placementsId, setPlacementsId] = useState([]);
    const [paramsLAkt, setParamsLAkt] = useState([]);
    //const { selectedDate, setSelectedDate } = useContext(DetailDataContext); 
    
    
    const {
        saveSuccessful,
        setSaveSuccessful,
        detailDataNutne,
        aktualDetailSection,
        setAktualDetailSection,
        inputDefaults,
        setInputDefaults,
        inputEditLongs,
        inputLookups,
        inputFiles,
        selectedDates,
        isChanged,
        inputsAll,
        setInputsAll
    } = useContext(DetailDataContext); // Získání hodnot z kontextu
    const data1 = detailDataNutne?.dataGlob; // Získání dat z detailDataContext
    const paramsL = detailDataNutne?.paramsLGlob; // Získání dat z detailDataContext
    const headers = detailDataNutne?.headers; // Získání dat z detailDataContext
    //console.log('v PlacementComponent - paramsL', paramsL);
    //console.log('v PlacementComponent - detailDataNutne', detailDataNutne);
    
    
    //console.log('v PlacementComponent - saveSuccessful', saveSuccessful)
    //console.log('v PlacementComponent - detailData', detailData, 'aktualDetailSection', aktualDetailSection);   

    useEffect(() => {
        if (detailDataNutne) {
            //console.log('000000 - v PlacementComponent - useEffect: ', aktualDetailSection);
            //console.log('33333 v PlacementComponent - detailData', detailData, 'aktualDetailSection', aktualDetailSection);
            if(aktualDetailSection == null){ setAktualDetailSection(detailDataNutne?.detailSectionsGlob[0])} // kdyz je v context null }nebulo jeste kliknuto, vlozim prvni z dat
            const paramsLAktRes = paramsL?.filter(polozka => polozka.detailSection === aktualDetailSection); // vybere jen ty parametry, ktere jsou v aktualni detailSection
            //console.log('v PlacementComponent - aktualDetailSection', aktualDetailSection);
                
            const headers = paramsL?.map(objekt => objekt.parametr);
            let placementIdAll = paramsLAktRes?.map(param => param.idPlacement);
            let uniquePlacementsId = [...new Set(placementIdAll)]; // Odstranění duplicit
            //console.log('v PlacementComponent - placementsId', uniquePlacementsId);
                       
                        
    
            setPlacementsId(uniquePlacementsId);
            setParamsLAkt(paramsLAktRes);
            //setLocalPlacementRefs(newPlacementRefs);
            setSaveSuccessful(false);

        }        
        
    }, [detailDataNutne, aktualDetailSection, JSON.stringify(inputLookups), placementRefs]);

    const ScrollableGrid = styled(Grid)({
        height: '2000px', // Nastavte výšku kontejneru
        overflowY: 'auto', // Povolte vertikální scrollování
        
    });

    //console.log('paramsL', paramsL)
    if (!detailDataNutne) {
        //console.log('4444 v PlacementComponent - detailData, rendrovani ', detailData);
        return <div>Loading Placement Component...</div>;
    } else {
        return (
            <Box display="flex" flexWrap="wrap" sx={{ overflow: 'auto', height: '80vh' }}>
              
              {placementsId.map(id => {
                const name = paramsL.find(param => param.idPlacement === id)?.placement;
                const input = paramsL.find(param => param.idPlacement === id)?.input;
                const vizu = paramsL.find(param => param.idPlacement === id)?.vizu;
                const vizuSlave = paramsL.find(param => param.idPlacement === id)?.vizuSlave;
                const vizuSlaveValue = paramsL.find(param => param.idPlacement === id)?.vizuSlaveValue;
                const parametr = paramsL.find(param => param.idPlacement === id)?.parametr;
                //console.log('v PlacementComponent - parametr: ', parametr, 'vizuSlave: ', vizuSlave, 'vizuSlaveValue: ', vizuSlaveValue);
                return (
                 
                  name !== 'Gantt' && name !== 'List' && input !== 'pole' ? ( // Pokud je název placementu List, zobrazíme tabulku
                    <Box key={id} sx={{ width: { xs: '100%', sm: '350px' }, flexShrink: 0 }}>
                      <Item>
                        <PlacementDiv key={id}>
                          <div id={`placement_${id}`} ref={placementRefs[id]}>
                            <PlacementHead>
                              {name}
                            </PlacementHead>
                            {paramsLAkt.map((param, index) => {
                              if (param.idPlacement === id && (param.input === 'date' || param.input === 'dateTime')) {
                                return <DateComponent
                                  key={index}
                                  param={param}
                                  placementRef={placementRefs[param.idPlacement]}
                                />;
                              } else if (param.idPlacement === id && param.input === 'default') {
                                return <DefaultComponent
                                  key={index}
                                  param={param}
                                  placementRef={placementRefs[param.idPlacement]}
                                />;
                              } else if (param.idPlacement === id && param.input === 'lookup' && param.zdroj !== '_ciselnik') {
                                return <LookupComponent
                                  key={index}
                                  param={param}
                                  placementRef={placementRefs[param.idPlacement]}
                                />;
                              } else if (param.idPlacement === id && param.input === 'lookup' && param.zdroj === '_ciselnik') {
                                return <LookupCiselnikComponent
                                  key={index}
                                  param={param}
                                  placementRef={placementRefs[param.idPlacement]}
                                />;
                              } else if (param.idPlacement === id && param.input === 'file') {
                                return <FileComponent
                                  key={index}
                                  param={param}
                                  placementRef={placementRefs[param.idPlacement]}
                                />;
                              } else if (param.idPlacement === id && (param.input === 'auto' || param.input === 'edit' || param.input === 'editLong' || param.input === 'pole1')) {
                                return <RestComponent
                                  key={index}
                                  param={param}
                                  placementRef={placementRefs[param.idPlacement]}
                                />;
                              } else if (param.idPlacement === id && param.input === 'bool') {
                                return <BoolComponent
                                  key={index}
                                  param={param}
                                  placementRef={placementRefs[param.idPlacement]}
                                />;
                              }
                              return null;
                            })}
                          </div>
                        </PlacementDiv>
                      </Item>
                    </Box>
                  ) : name === 'List' ? (
                    <Box key={`list_${id}`} sx={{ width: { xs: '100%', sm: '2500px' }, height: '1000px', flexShrink: 0 }}>
                      <Item>
                         <PlacementDiv key={id} style={{ width: '2500px', height: '1000px'}}>
                          <div id={`placement_${id}`} ref={placementRefs[id]}>
                            <PlacementHead>
                              {name}
                            </PlacementHead>
                            {paramsLAkt.map((param, index) => {
                              if (param.idPlacement === id && (param.zdrojParametr === 'pole1Data')) {
                                return <KalkulaceTable
                                  key={index}
                                  param={param}
                                  placementRef={placementRefs[param.idPlacement]}
                                />
                              }
                              return null; // Vracíme null, pokud podmínka if není splněna
                            })}
                          </div>
                        </PlacementDiv>
                      </Item>
                    </Box>
                  ): name === 'Gantt' ? (
                    <Box key={`gantt_${id}`} sx={{ width: { xs: '100%', sm: '2500px' }, height: '1000px', flexShrink: 0 }}>
                      <Item>
                         <PlacementDiv key={id} style={{ width: '2500px', height: '1000px'}}>
                          <div id={`placement_${id}`} ref={placementRefs[id]}>
                            <PlacementHead>
                              {name}
                            </PlacementHead>
                            {paramsLAkt.map((param, index) => {
                              console.log('v PlacementComponent - param: ', param);
                              if (param.idPlacement === id && (param.zdrojParametr === 'pole1Data')) {
                                return <ProcesGantt
                                  key={index}
                                  param={param}
                                  placementRef={placementRefs[param.idPlacement]}
                                  entryPoint={'detailNoProcesMapa'}
                                />
                              }
                              return null; // Vracíme null, pokud podmínka if není splněna
                            })}
                          </div>
                        </PlacementDiv>
                      </Item>
                    </Box>
                  ) : input === 'pole' ? (
                    <Box key={`pole_${id}`} sx={{ width: { xs: '100%', sm: '600px' }, height: '500px', flexShrink: 0 }}>
                      <Item>
                      <PlacementDiv key={id} style={{ width: '600px', height: '500px'}}>
                          <div id={`placement_${id}`} ref={placementRefs[id]}>
                            <PlacementHead>
                              {name}
                            </PlacementHead>
                            {paramsLAkt.map((param, index) => {
                              if (param.idPlacement === id && param.input === 'pole') {
                                return <PoleComponent
                                  key={index}
                                  param={param}
                                  placementRef={placementRefs[param.idPlacement]}
                                />;
                              }
                              return null;
                            })}
                          </div>
                        </PlacementDiv>
                      </Item>
                    </Box>
                  ) : null
                  
                );
              })}
            </Box>
          );
    };
};

export default PlacementComponent;
