import React, { useState, useEffect, useContext, useCallback } from 'react';
import { DataContext } from '../store/baseData';
import { DetailDataContext } from '../store/detailDataContext';

import Gantt from '../organisms/listGraf4';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import LinearIndeterminate from '../atoms/LinearIndeterminate';
import { getDataList, checkTaskStatus, getKalkulaceList, getProcesMapaZdroj } from '../api/apiRest';
import { dataInputDefault1 } from '../api/dataInputDefault1';
import Alert from '@mui/material/Alert';
import { Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';



export default function Proces({ params, entryPoint }) {
    //kdyz je pole1Data prazdne !initValue, tak se spusti taskBuilding('init') a vytvori se zakladni struktura mapy
    //kdyz je pole1Data naplnene, tak se nacte do vstupDataX a zobrazi se v ganttGrafu
    //kdyz uzivatel edituje mapu v ganttGrafu, tak se spusti taskBuilding('appMapa') a upravi se mapaTask a mapaLink
    //kdyz neni tableEntita ProcesMapa, tak se nactou data z pole1Data konretni entity ProcesMapa podle shody idOPB; vizuProcesMapa = true;

    //tableEntita={tableEntita} pageP={page} data={data} headers={headers} columnAccessors={headersParam} onRowClick={handleEntityClick} mjs={mjs} inputFormat={inputFormat}
    const { sectionsGlob, structureGlob, contactsGlob, ciselnikGlob, setCiselnikGlob, procesAll, aktualViewSection, paramsLAllAll, procesList  } = useContext(DataContext);
    const {pole1Data, setPole1Data, mapaData, setMapaData, detailDataNutne, detailDataRest, inputLookups, inputDefaults,  pole1DataAppView, setPole1DataAppView, aktualDetailSection, tableEntita} = useContext(DetailDataContext); // Získání hodnot z kontextu
    //console.log('00 - v KalkulaceTable - paramsLAllAll', paramsLAllAll);

    const theme = useTheme();
    const [data, setData] = useState([]);      // Data pro zobrazení v listu
    const [headers, setHeaders] = useState([]); // hlavicka tabulky
    const [mjs, setMjs] = useState([]); // jednotky
    const [inputFormat, setInputFormat] = useState([]); // format vstupu [date, number
    const [headersParam, setHeadersParam] = useState([]); // parametry radku
    const [entityTitle, setEntityTitle] = useState('Načítám data...');
          
    
    const [addIconId, setAddIconId] = useState(null);
    
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const navigate = useNavigate(); // Inicializace useNavigate
    const [message, setMessage] = useState(null);
    const [severity, setSeverity] = useState('success');
    const [message1, setMessage1] = useState(null);
    const [severity1, setSeverity1] = useState('success');
    const [smlouvaMilnik, setSmlouvaMilnik] = useState([]); //pole milniku 
    const [kalkulaceList, setKalkulaceList] = useState([]); //pole kalkulaci
    const [procesMapaZdroj, setProcesMapaZdroj] = useState([]); //pole zdroju pro procesMapa
    const [vstupDataX, setVstupDataX] = useState(null);
    const [procesMapaList, setProcesMapaList] = useState([]); //pole vsech ProcesMapa
      
    let idIdent;
    const vizuProcesMapa = entryPoint; //listProcesMapa, detailProcesMapa, detailNoProcesMapa
    let idOP;
    let nameOP;
    const idParPole1Data = 3492
    const idParMilnkik = 355
    const idParOPB = 3515
        
    //zjistuji odkud se pristupuje k ProcesMapa, zda z jine entity nez ProcesMapa, zda z listView, zda z ProcesMapa
    if(tableEntita !== 'ProcesMapa' && vizuProcesMapa === 'detailNoProcesMapa') { // plati jen kdyz neni entita ProcesMapa 
        idIdent = '3492' // id pro pole1Data entity ProcesMapa
        //vizuProcesMapa = 'detailNoProcesMapa' // vizualizace ProcesMapa v jine entite nez ProcesMapa;
        idOP = tableEntita !== 'OPB' ? detailDataNutne.dataGlob[0]['idOPB'] :  detailDataNutne.dataGlob[0]['id']
        nameOP = tableEntita !== 'OPB' ?  detailDataNutne.dataGlob[0]['nameOPB'] :  detailDataNutne.dataGlob[0]['name']
        //console.log('00 - v ProcesGantt - zobrazeni Procesni mapy v jine entite nez ProcesMapa - idIdent', idIdent, 'vizuProcesMapa', vizuProcesMapa, 'idOP', idOP, 'nameOP', nameOP, 'tableEntita: ', tableEntita);
    }else if(tableEntita === 'ProcesMapa' && vizuProcesMapa === 'detailProcesMapa'){
        //vizuProcesMapa = 'detailProcesMapa'; // vizualizace ProcesMapa v entite ProcesMapa
        idOP = detailDataNutne.dataGlob[0]?.idOPB ? detailDataNutne.dataGlob[0].idOPB : inputLookups?.[3515];
        idIdent = '3492'
        nameOP = detailDataNutne.dataGlob[0]?.nameOPB !== null ? detailDataNutne.dataGlob[0]?.nameOPB : (inputDefaults[3516] !== null ? inputDefaults[3516] : 'default value');
        //console.log('00 - v ProcesGantt - zobrazeni Procesni mapy v entite ProcesMapa - idIdent', idIdent, 'vizuProcesMapa', vizuProcesMapa, 'idOP', idOP, 'nameOP', nameOP);
    }else if(aktualViewSection === 'listProcesMapa' && vizuProcesMapa === 'listProcesMapa') { // plati jen kdyz pristupuji z ListView
        idIdent = '3492' // id pro pole1Data entity ProcesMapa
        //vizuProcesMapa = 'listViewProcesMapa' // vizualizace ProcesMapa v jine entite nez ProcesMapa;
        //console.log('00 - v ProcesGantt - zobrazeni Procesni mapy v listView - idIdent', idIdent);
    }
    
    console.log('00 - v ProcesGantt - detailDataRest', detailDataRest);
    const idMi = 1;
    const rowDataAllProcesMapa = detailDataRest?.dataDefaultGlob.default.ProcesMapa || ''; // data z db vsech ProcesMapa 
    //console.log('-------------------- v Proces - vstupDataX', vstupDataX);
    //console.log('v Proces - idOP', idOP, 'nameOP', nameOP, 'inputDefaults[3516]: ', inputDefaults[3516]);
    //console.log('v Proces - procesList', procesList);
    //console.log('v Proces - ciselnikGlob', ciselnikGlob);
    //console.log('v Proces - detailDataRest', detailDataRest);
    //console.log('v Proces - detailDataNutne', detailDataNutne);
    //console.log('v Proces - idOPB', idOPB);
    //console.log('v Proces - rowDataAllProcesMapa', rowDataAllProcesMapa);

    // ------ zakladni nacteni po otevreni, pouzito jen v otevreni Procesni mapa v jinych elementech nez ProcesMapa, v detailSection Procesní mapa ---------------------------------------------------------------
    //console.log('0000000000 - v ProcesGantt - rowDataAllProcesMapa:', rowDataAllProcesMapa, 'vstupDataX:', vstupDataX);
    useEffect(() => {
        if(rowDataAllProcesMapa && vizuProcesMapa === 'detailNoProcesMapa' && !vstupDataX) {
            //console.log('v zakladnim nacteni pri aplikaci Kalkulace')
            console.log('A0 - v zakladnim nacteni pri aplikaci ProcesMapa do jine entity nez ProcesMapa - detailDataRest', detailDataRest)
            console.log('A0 - v zakladnim nacteni pri aplikaci ProcesMapa do jine entity nez ProcesMapa - rowDataAllProcesMapa', rowDataAllProcesMapa)
            
            //--------vyhladam ty ProcesMapa, ktere maji v pole1Data idOPB shodne s idOPB z detailDataNutne.dataGlob[0] a z nich vyberu pole1Data a vlozim do pole initValue 

            let initValue = {
                data: [],
                links: []
            };
                      
            console.log('A0 - v zakladnim nacteni pri otevreni ProcesMapa do jine entity - tableEntita: ', tableEntita)
            rowDataAllProcesMapa.forEach(item => {
                console.log('A0 - v zakladnim nacteni pri otevreni ProcesMapa do jine entity - item:', item)
                // v pripade tableEntita jine nez OPB se musi porovnavat idOPB, v pripade OPB se musi porovnavat id
                if(item['idOPB'] == idOP && item.pole1Data) { 
                    //console.log('A0 - v zakladnim nacteni pri otevreni ProcesMapa do jine entity - item.pole1Data', item.pole1Data)
                    try {
                        let parsedData = JSON.parse(item.pole1Data);
                        console.log('A0 - parsedData.data', parsedData)
                        initValue.data.push(...parsedData.data);
                        initValue.links.push(...parsedData.links);
                      
                    } catch(e) {
                        console.error("Error parsing JSON for item:", item, "Error:", e);
                    }
                }

            });
            console.log('A0 - v zakladnim nacteni pri aplikaci ProcesMapa do jine entity nez ProcesMapa - newData', initValue)
                        
            //let initValue = rowData?.pole1Data;
           
            //console.log('0000000000 - v KalkulaceTable - initValue', initValue);
            if (initValue.length !== 0) {
                //initValue = JSON.parse(initValue);
                //let appFilter = initValue.filter(item => item[appParamId] === idUn);
                //console.log('001 - v KalkulaceTable - initValue s appFilter', appFilter); 
                const dataJson = JSON.stringify(initValue)
                setPole1Data({[idIdent]: dataJson});
                
                //console.log('x1 - v KalkulaceTable - po setPole1Data', initValue);
                //setPole1DataAppView({[idIdent]: initValue}); //sem dam jen data pro konkretni aplikaci
                const mapaData = {[idIdent]: initValue};
                if(pole1Data){
                    taskActual(mapaData.data) 
                }
              
            }
           
        }
         
    }, [detailDataRest, aktualDetailSection]);

    // uvodni nacteni dat, kdyz vstupData jeste neni naplnen; jen v pripade otevirani v entite ProcesMapa - vizuProcesMapa je detailProcesMapa
    useEffect(() => {
        
        if (detailDataNutne && !vstupDataX && procesList && procesList.length !== 0 && vizuProcesMapa === 'detailProcesMapa' && procesMapaZdroj.length !== 0) {
            console.log('------------ v Proces - uvodni nacteni - vstupDataX: ', vstupDataX, 'procesList: ', procesList, 'smlouvaMilnik: ', smlouvaMilnik, 'nameOP: ', nameOP, 'vizuProcesMapa: ', vizuProcesMapa, 'detailDataNutne:', detailDataNutne, 'procesMapaZdroj:', procesMapaZdroj)
            const initValue = detailDataNutne.dataGlob[0].pole1Data; //hodnota je v json
            //console.log('------ v Proces - uvodni nacteni - initValue: ', initValue)

            // jen kdyz nacitana proces mapa neobsahuje zadna data provede se taskBuilding, jinak se jen dosadi tasky z db
            if(!initValue){
                console.log('-------- vytvarim novou strukturu mapaTask a mapaLink ze zakladnich procesu v db, protoze pole1Data v ProcesMapa je prazdne')
                try {
                    const fetchData = async () => {
                    taskBuilding('init'); // 'init' varianta pouzita pri prvnim vytvareni. existuje jeste 'mapaApp', ktera je volana z ganttGrafu pri editaci zakladni mapy

                    }
                    fetchData();
                    
                } catch (error) {
                    console.error('Chyba: ', error);
                }
                return
            }else{
                setPole1Data({['3492']: initValue});
                
            }
                         
        }
    }, [detailDataNutne, smlouvaMilnik, procesList, nameOP, vizuProcesMapa, procesMapaZdroj ]);
    
    //zakladni vkladani dat do vstupDataX, je to vstupni bod pro zobrazzeni v ganttGrafu
    useEffect(() => {
        console.log('v Proces - naplneni vstupDataXData z pole1Data', pole1Data);
        if (pole1Data?.['3492']) {
          try {
            const data = JSON.parse(pole1Data['3492']);
            console.log('v Proces - naplneni vstupDataXData z pole1Data - data', data);
            setVstupDataX(data);
            
          } catch (error) {
            console.error("Chyba při parsování dat", error);
          }
        }
    }, [pole1Data]); // Tento hook se spustí pokaždé, když se změní pole1Data
    
    // nacteni SmlouvaMilnik k danemu OP   
    const fetchData = useCallback(async () => {
        try {
            const token = localStorage.getItem('token');
           //--- SmlouvaMilnik --------
            let response1 = await getDataList('SmlouvaMilnik', 'intranet', token);
            
            const taskId1 = response1.taskId;
            const dataList = await checkTaskStatus(taskId1);

            const milnikList = dataList.data.filter(obj => obj.idOPB == idOP);
            console.log('v Proces - milnikList: ', milnikList); 
            
            setSmlouvaMilnik(milnikList);

            //console.log('v listView dataList', dataList);
            console.log('v Proces - smlouvaMilnik', milnikList);
            //---------------------------

            
            //------- Kalkulace - necham si poslat jen od tohoto kkonkretniho OP a jen aktivni --------
            let response2 = await getKalkulaceList('Kalkulace', 'intranet', token, idOP);
            const taskId2 = response2.taskId;
            const dataList2 = await checkTaskStatus(taskId2);
            console.log('v Proces - kalkulaceList', dataList2.data);
            setKalkulaceList(dataList2.data);

            //------Datumy a kapacity z entit uvedenych v procesList-------------
            const entitaList = procesList.map(item => item.entita).filter((value, index, self) => self.indexOf(value) === index); //unikatni hodnoty z parametru entita v procesList
            console.log('v Proces - entitaList: ', entitaList);
            if(entitaList.length !== 0){
                const response3 = await getProcesMapaZdroj(entitaList, 'intranet', idOP, token); 
                const taskId3 = response3.taskId;
                const dataList3 = await checkTaskStatus(taskId3);
                console.log('v Proces - getProcesMapaZdroj: ', dataList3.data);
                setProcesMapaZdroj(dataList3.data);
            }
            
            
    
        } catch (error) {
            console.error('Chyba při načítání dat:', error);
            console.log('nastal session problem na serveru, user bude odhlasen ', error.message);
            if (error.message.includes('/logout') || error.message.includes('Invalid Token')) {
                window.location.href = '/logout';
            }
        }
    
        }, [idOP, procesList]); // Závislosti pro useCallback
        useEffect(() => {
          fetchData();
    }, [fetchData, ]);

    // ----------------------------- zakladni nacteni pro listProcesMapa -------------------------------------
    useEffect(() => {
        if(procesMapaList.length !== 0 && vizuProcesMapa === 'listProcesMapa' && !vstupDataX) {
           
           console.log('A0 - v zakladni nacteni pro listProcesMapa, procesMapaList: ', procesMapaList)
        
            let initValue = {
                data: [],
                links: [],
              
            };
                      
            console.log('A0 - v zakladnim nacteni pri otevreni ProcesMapa do jine entity - tableEntita: ', tableEntita)
            procesMapaList.forEach(item => {
                console.log('A0 - v zakladnim nacteni pri otevreni ProcesMapa do jine entity - item:', item)
                // v pripade tableEntita jine nez OPB se musi porovnavat idOPB, v pripade OPB se musi porovnavat id
                if(item.pole1Data) { 
                    //console.log('A0 - v zakladnim nacteni pri otevreni ProcesMapa do jine entity - item.pole1Data', item.pole1Data)
                    try {
                        let parsedData = JSON.parse(item.pole1Data);
                        console.log('A0 - parsedData.data', parsedData)
                        initValue.data.push(...parsedData.data);
                        initValue.links.push(...parsedData.links);
                       
                      
                    } catch(e) {
                        console.error("Error parsing JSON for item:", item, "Error:", e);
                    }
                }

            });
            console.log('A0 - v zakladnim nacteni pri aplikaci Kalkulace do jine entity nez Objednavky - newData', initValue)
                        
            //let initValue = rowData?.pole1Data;
            setVstupDataX(initValue);
           /*
            //console.log('0000000000 - v KalkulaceTable - initValue', initValue);
            if (initValue.length !== 0) {
                //initValue = JSON.parse(initValue);
                //let appFilter = initValue.filter(item => item[appParamId] === idUn);
                //console.log('001 - v KalkulaceTable - initValue s appFilter', appFilter); 
                const dataJson = JSON.stringify(initValue)
                setPole1Data(prevValues => ({
                    ...prevValues,
                    [idIdent]: dataJson,
                    
                }));
                //console.log('x1 - v KalkulaceTable - po setPole1Data', initValue);
                
            }
            */
           
        }
         
    }, [procesMapaList, vizuProcesMapa]);

    // nacteni vsech ProcesMapa
    const fetchData1 = useCallback(async () => {
        try {
            const token = localStorage.getItem('token');
           
            let response1 = await getDataList('ProcesMapa', 'intranet', token);
            const taskId1 = response1.taskId;
            const dataList = await checkTaskStatus(taskId1);

            const procesMapaListRes = dataList.data;
            console.log('v Proces - procesMapaListRes: ', procesMapaListRes); 
            
            setProcesMapaList(procesMapaListRes);
            //console.log('v listView dataList', dataList);
            //---------------------------
    
        } catch (error) {
            console.error('Chyba při načítání dat:', error);
            console.log('nastal session problem na serveru, user bude odhlasen ', error.message);
            if (error.message.includes('/logout') || error.message.includes('Invalid Token')) {
                window.location.href = '/logout';
            }
        }
    
        }, []); 
        useEffect(() => {
          fetchData1();
    }, [fetchData1, ]);

    // ----------------------------- Funkce pro vytvoreni tasku a linku -------------------------------------
    function taskBuilding(mistoSpusteni) {
        // vytvorim novy mapaTask
        console.log('v taskBuilding - mistoSpusteni: ', mistoSpusteni);
        console.log('v taskBuilding - kalkulaceList: ', kalkulaceList);
        console.log('v taskBuilding - smlouvaMilnik: ', smlouvaMilnik);
        console.log('v taskBuilding - ProcesMapaZdroj: ', procesMapaZdroj);
        let mapaLink = [];
        let mapaTask = [];
        try {
            // ----------------- pro pripad prvniho nacteni - do mapaTask pridam každý prvek z procesList podle jeho rodiče a samotne rodice
            if(mistoSpusteni === 'init'){
                mapaTask = mapaProject;
                
                console.log('v taskBuilding - init - procesList', procesList);
                procesList.forEach(item => {
                    
                    // Najděte odpovídající prvek v mapaProject
                    const parent = mapaProject.find(project => project.id === item.parent);
                    // Pokud existuje odpovídající parent
                    if (parent) {
                    // { resource: 101, state: "model", mapaApp: true, hidden: false, id: "80-1-1-1", text: "PDE D2", start_date: "2024-05-01", duration: 3, parent: "80-1-1", capacity: 2 },
                    const task = { id: parent.id + "-" + item.id, parent: parent.id, state: parent.state, mapaApp: parent.mapaApp, hidden: parent.hidden, text: item.name,
                    duration: item.duration, capacity: item.capacity, resource: item.resource, type: item.duration === 0 ? "milestone" : ""};
                    mapaTask.push({ ...task });
                    } 
                    
                });

                //---------------------------- Links - do prazdneho pole pridam linky ze vsech procesList z db
                procesList.forEach(item => {
                    if (item.idTargetTask){
                        const linksOfItem = item.idTargetTask ? item.idTargetTask.split(', ').map(Number) : null; // vyparsuji text z bunky idTargetTask daneho item
                        //console.log('v taskBuilding - linksOfItem', linksOfItem);
                        for (let a of linksOfItem) {
                            //console.log('v taskBuilding - item.id', item.id, 'mapaTask', mapaTask);
                            const idTask = mapaTask.find(b => b.id.split("-").pop() == item.id && b.type !== 'project')?.id; // hleda v mapaTask id, ktere konci na id daneho item, ktere neni typu project
                            //console.log('v taskBuilding - idTask', idTask);
                            //console.log('v taskBuilding - a', a);
                            const idTargetTask = mapaTask.find(c => c.id.split("-").pop() == a && c.type !== 'project')?.id;     // hleda v mapaTask id, ktere konci na id daneho a
                            //console.log('v taskBuilding - idTargetTask', idTargetTask);
                            //{ id: "80-1-1-2-80-1-1-3", source: "80-1-1-2", target: "80-1-1-3", type: "0" },
                            const linkOne = { id: idTask + "-" + idTargetTask, source: idTask, target: idTargetTask, type: item.typPropojeni};
                            mapaLink.push({ ...linkOne });
                        }
                        
                    }
                    
                });

             // ---------------- pro pripad user editace mapy procesu - odstrani polozky project a polozky mapaApp: false, vlozi nutne parent
            }else if(mistoSpusteni === 'appMapa'){
                //console.log('v taskBuilding - appMapa - mapaData.data', mapaData.data);
                //let jsonData = JSON.stringify(mapaData);
                //let restoredData = JSON.parse(jsonData);
                //console.log('v taskBuilding - appMapa - restoredData.data', restoredData.data);
                const mapaDataTrue = mapaData.data.filter(item => item.mapaApp === true);
                const mapaDataTrueNoProject = mapaDataTrue.filter(item => item.type !== 'project');
                //const mapaDataStateModel = mapaDataTrueNoProject.filter(item => item.state === 'model');
                
                let updatedMapaDataTrueNoProject = mapaDataTrueNoProject.map(({ start_date, end_date, duration, ...rest }) => {
                    return { ...rest, duration: duration * 1.4 }; // gantt vratil hodnoty zmensene na pocet pracovnich dnu, proto musim dosadit hodnotu kalendarnich dnu
                });

                //kontroluje, zda pro každou položku s state: 'model' existuje odpovídající položka s state: 'actual'. Pokud taková položka neexistuje, vytvoří se duplikát modelové položky s state: 'actual' a id končící na -A.
                updatedMapaDataTrueNoProject.forEach((item, index) => {
                    if (item.state === 'model') {
                        const actualId = item.id + '-A';
                        const actualItem = updatedMapaDataTrueNoProject.find(i => i.state === 'actual' && i.id === actualId);
                        if (!actualItem) {
                            const duplicate = { ...item, state: 'actual', id: actualId };
                            updatedMapaDataTrueNoProject.push(duplicate);
                        }
                    }
                });
               

                //musim do mapaProject dosadit konkretni idOP a nameOP a idMi, protoze tasky v mapaData maji uz konkretni projekt
                //console.log('v taskBuilding - appMapa - doplnuji idOP, idMi, nameOP', idOP, idMi, nameOP);
                const mapaProjectKonkretni = mapaProject.map(item => { // prejmenuji id a text a parent vsech projektovych tasku v mapaProject, tj. v zakladnim zdroji
                    return {
                        ...item, 
                        id: typeof item.id === 'string' ? item.id.replace('idOP', idOP).replace('idMi', idMi) : item.id,
                        text: typeof item.text === 'string' ? item.text.replace('idOP', idOP).replace('idMi', idMi).replace('nameOP', nameOP) : item.text, 
                        parent: typeof item.parent === 'string' ? item.parent.replace('idOP', idOP).replace('idMi', idMi) : item.parent,
                    };
                });

                let added;
              
                //cyklus se opakuje, dokud je splněna určitá podmínka. V tomto případě by podmínkou bylo, že se v každém průchodu cyklem najde nový rodičovský prvek.
                do {
                    added = false; // Resetujeme příznak pro každý průchod cyklem
                
                    updatedMapaDataTrueNoProject.forEach(item => {
                        // Najděte odpovídající prvek v mapaProject
                        const parent = mapaProjectKonkretni.find(project => project.id === item.parent);
                        
                        
                        // Pokud existuje odpovídající parent a ještě nebyl přidán do updatedMapaDataTrueNoProject
                        if (parent && !updatedMapaDataTrueNoProject.some(task => task.id === parent.id)) {
                            updatedMapaDataTrueNoProject.push(parent); // Přidáme parenta do updatedMapaDataTrueNoProject
                            added = true; // Nastavíme příznak, že byl přidán nový prvek
                        }
                        
 
                    });
                
                } while (added); // Opakujeme cyklus, dokud se přidávají nové prvky
               
                mapaTask = updatedMapaDataTrueNoProject;
                console.log('v taskBuilding - appMapa - vystup - mapaTask', mapaTask);
                

                //--------------------- Links - stavajici pole pridam linky ze vsech procesList z db-----------------------
                //preberu links z mapaData z ganttGrafu
                //console.log('v taskBuilding - appMapa - mapaData.data', mapaData.links);
                //console.log('v taskBuilding - appMapa - doplnuji idOP, idMi, nameOP', idOP, idMi, nameOP);

                //kontroluje, zda pro každou položku, jejíž id nekončí na -A, existuje odpovídající položka s id končícím na -A. Pokud taková položka neexistuje, vytvoří se nový objekt s id, source a target končícími na -A.
                mapaData.links.forEach((item, index) => {
                    if (!item.id.endsWith('-A')) {
                        const actualId = item.id + '-A';
                        const actualItem = mapaData.links.find(i => i.id === actualId);
                        if (!actualItem) {
                            const duplicate = { ...item, id: actualId, source: item.source + '-A', target: item.target + '-A' };
                            mapaData.links.push(duplicate);
                        }
                    }
                });


                const mapaLinkKonkretni = mapaData.links.map(item => {
                    return {
                        ...item, 
                        id: typeof item.id === 'string' ? item.id.replace('idOP', idOP).replace('idMi', idMi) : item.id,
                        source: typeof item.source === 'string' ? item.source.replace('idOP', idOP).replace('idMi', idMi) : item.source,
                        target: typeof item.target === 'string' ? item.target.replace('idOP', idOP).replace('idMi', idMi) : item.target,
                    };
                });
                mapaLink = mapaLinkKonkretni;
                //console.log('v taskBuilding - appMapa - po prejmenovani na konkretni projekt - mapaLink', mapaLink);
               
            }
            //--------------------- dale uz maji obe var. spolecnou cestu---------------------
            const buildingRes = taskAktualizuj(mapaTask, mapaLink)
            
            /*
            //sparovani s nalinkovanymi SmlouvaMilnik - SmlouvaMilnik jiz obsahuje jen prolinkovane k tomuto projektu
            console.log('v taskBuilding - po sparovani s SmlouvaMilnik - mapaTask: ', mapaTask, 'mapaLink: ', mapaLink);
            const kotveni = kotveniSmlouvaMilnik(mapaTask, mapaLink);
            mapaTask = kotveni.mapaTask;
            mapaLink = kotveni.mapaLink;
            console.log('v taskBuilding - po kotveni - naplneni datumu do zvazbenych tasku od milniku - mapaTask: ', mapaTask, 'mapaLink: ', mapaLink);

            //vlozeni capacity do tasku Realizace Instalace FVP a Instalace Elektro - z kalkulaceList
            mapaTask = capacityRealizace(mapaTask)

            //prirazeni datumu do tasku, ktere maji vazbu na jiny task pomoci linku, kde jsou jako source
            //prirazeni datumu do tasku, ktere maji vazbu na jiny task pomoci linku, kde jsou jako target
            mapaTask =  dateLinks(mapaTask, mapaLink)
            
            //console.log('v taskBuilding - po naplneni datumu - mapaTask: ', mapaTask);
            //console.log('v taskBuilding - po naplneni datumu - doplnuji idOP, idMi, nameOP', idOP, idMi, nameOP);

            // projde vsechny tasky a pokud nemaji start_date, doplni je aktualnim datumem
            mapaTask = dnesniDatum(mapaTask)
                       
            // znova projdu mapaTask a polozkam state: actual vyhledam skutecne hodnoty z procesMapaZdroj a doplnim je do tasku
            mapaTask = taskActual(mapaTask);
            
            //console.log('v taskBuilding - mapaTask', mapaTask);
            //return { mapaTask, mapaLink };
            const buildingRes = { data: mapaTask, links: mapaLink, resource: resource, priority: priority }
            //const buildingResRed = { data: mapaTask }
           
            const dataJson = JSON.stringify(buildingRes)
            
            //console.log('v taskBuilding - plnim pole1Data - dataJson', dataJson);
            setPole1Data(prevValues => ({
                ...prevValues,
                ['3492']: dataJson,
                
            }));
            //setPole1DataAppView({[idIdent]: dataJson}); //sem dam jen data pro konkretni aplikaci
            */
            return buildingRes; // vraci do listGraf kde se vlaozi do mapaData

        } catch (error) {
            console.error('Chyba při vytváření úkolů:', error);
        }
        
        
    }
    
    // volano z listGraf nebo z taskBuilding, pozadavek na aktualizaci tasku podle datumu a kapacity v entitach; kdyz z listGraf, tak chybi vstupy do fce
    function taskAktualizuj(mapaTask, mapaLink) {
        if(!mapaTask){
            mapaTask = mapaData.data;
        }
        if(!mapaLink){
            mapaLink = mapaData.links;
        }
        
        try{
             //sparovani s nalinkovanymi SmlouvaMilnik - SmlouvaMilnik jiz obsahuje jen prolinkovane k tomuto projektu
             console.log('00--------------------------- v ProcesGantt - taskAktualizuj - mapaTask: ', mapaTask, 'mapaLink: ', mapaLink);
             const kotveni = kotveniSmlouvaMilnik(mapaTask, mapaLink);
             mapaTask = kotveni.mapaTask;
             mapaLink = kotveni.mapaLink;
             console.log('01 --------------------------- v procesgant po taskBuilding - po kotveni - naplneni datumu do zvazbenych tasku od milniku - mapaTask: ', mapaTask, 'mapaLink: ', mapaLink);
            
             mapaTask = kotveniMilnik(mapaTask)
             console.log('01A --------------------------- v procesgant po kotveniMilnik - naplneni datumu do zvazbenych tasku od milnikovych promennych - mapaTask: ', mapaTask)

             //vlozeni capacity do tasku Realizace Instalace FVP a Instalace Elektro - z kalkulaceList
             mapaTask = capacityRealizace(mapaTask)
             console.log('02-----------------------------v ProcesGant po capacityRealizace, mapaTask: ', mapaTask)
 
             //prirazeni datumu do tasku, ktere maji vazbu na jiny task pomoci linku, kde jsou jako source
             //prirazeni datumu do tasku, ktere maji vazbu na jiny task pomoci linku, kde jsou jako target
             mapaTask =  dateLinks(mapaTask, mapaLink)
             console.log('03-----------------------------v ProcesGant po dateLinks, mapaTask: ', mapaTask, 'mapaLink: ', mapaLink)
              
             // projde vsechny tasky a pokud nemaji start_date, doplni je aktualnim datumem
             mapaTask = dnesniDatum(mapaTask)
             console.log('04-----------------------------v ProcesGant po dnesniDatum, mapaTask: ', mapaTask)
                        
             // znova projdu mapaTask a polozkam state: actual vyhledam skutecne hodnoty z procesMapaZdroj a doplnim je do tasku
             mapaTask = taskActual(mapaTask);
             console.log('05-----------------------------v ProcesGant po taskActual, mapaTask: ', mapaTask);
             
             mapaTask = seraditTask(mapaTask)
             console.log('06-----------------------------v ProcesGant po seraditTask, mapaTask: ', mapaTask);
             //return { mapaTask, mapaLink };
             const buildingRes = { data: mapaTask, links: mapaLink, resource: resource, priority: priority }
             //const buildingResRed = { data: mapaTask }
            
             const dataJson = JSON.stringify(buildingRes)
             
             //console.log('v taskBuilding - plnim pole1Data - dataJson', dataJson);
             setPole1Data(prevValues => ({
                 ...prevValues,
                 ['3492']: dataJson,
                 
             }));
             //setPole1DataAppView({[idIdent]: dataJson}); //sem dam jen data pro konkretni aplikaci
             return buildingRes; // vraci do listGraf kde se vlaozi do mapaData

            /* 
            const mapaTaskAkt = taskActual(mapaData.data)
            const buildingRes = { data: mapaTaskAkt, links: mapaData.links, resource: mapaData.resource, priority: mapaData.priority }
            const dataJson = JSON.stringify(buildingRes)
            setPole1Data(prevValues => ({
                ...prevValues,
                ['3492']: dataJson,
            }));
            //setPole1DataAppView({[idIdent]: dataJson}); //sem dam jen data pro konkretni aplikaci
            return buildingRes; // vraci do listGraf kde se vlaozi do mapaData
            */
        }catch (error) {
            console.error('Chyba při aktualizaci úkolů:', error);
        }
        
    }

    function capacityRealizace(mapaTask){
        if(kalkulaceList){
            kalkulaceList.forEach(kal => {
                const zhFvp = kal.pocetPanelu ? Number(kal.pocetPanelu) * 1.8 : 0; // kapacita pro FVP je 1.8h na kazdy panel
                mapaTask = mapaTask.map(item => {
                    if(item.text.includes('Instalace FVP')){
                        console.log('v taskBuilding - Realizace Instalace FVP - item', item, 'zhFvp', zhFvp);
                        return {
                            ...item,
                            capacity: zhFvp,
                            duration: 2 * 1.4 * zhFvp / 30 //pocet dnu trvani - pocitam jednu partu po 3 lidech a 10 hodinach na pracovni den, 1.4 - nutno vlozit i vikendy, 1.4 - nutno vlozit i vikendy
                        };
                    } else  if(item.text.includes('Instalace Elektro')){
                        console.log('v taskBuilding - Instalace Elektro - item', item, 'Number(kal.zakazkoveHodiny) - zhFvp', Number(kal.zakazkoveHodiny) - zhFvp);
                        return {
                            ...item,
                            capacity: Number(kal.zakazkoveHodiny) - zhFvp,
                            duration: 2 * 1.4 * (Number(kal.zakazkoveHodiny) - zhFvp) / 24 //pocet dnu trvani - pocitam jednu partu po 3 lidech a 8 hodinach na pracovni den, 1.4 - nutno vlozit i vikendy, 1.4 - nutno vlozit i vikendy
                        };
                    } else {
                        return item;
                    }
                });
            });
            return mapaTask;
        }
    }

    function dateLinks(mapaTask, mapaLink){
        let isUpdated;
            //prirazeni datumu do tasku, ktere maji vazbu na jiny task pomoci linku, kde jsou jako source
            do {
                isUpdated = false;
            
                mapaTask = mapaTask.map(item => {
                     // Pokud položka již má nastavené start_date a end_date, vrátíme ji beze změny
                    if (item.start_date && item.end_date) {
                        return item;
                    }
                    // Najdeme všechny položky v mapaLink, které mají v source aktuální id
                    const linkItems = mapaLink.filter(i => i.source === item.id);
            
                    // Procházíme všechny nalezené linkItems
                    for (const linkItem of linkItems) {
                        // Najdeme odpovídající položku v mapaTask podle target z linkItem
                        const targetItem = mapaTask.find(i => i.id === linkItem.target);
            
                        // Pokud taková položka existuje a má start_date
                        if (targetItem && targetItem.start_date) {
                            // Vypočteme end_date jako start_date target položky
                            let endDate = dayjs(targetItem.start_date);
            
                            // Vypočteme start_date jako rozdíl end_date a duration
                            let startDate = endDate.subtract(item.duration, 'day');
            
                            // Pokud je startDate větší než item.start_date, vrátíme nový objekt s doplněnými daty
                            console.log('v taskBuilding - hledano podle source - item.id: ', item.id, 'startDate: ', startDate, 'item.start_date', item.start_date)
                            if (!item.start_date || startDate.isAfter(dayjs(item.start_date))) {
                                isUpdated = true;
                                return {...item, start_date: startDate.toISOString(), end_date: endDate.toISOString()};
                            }
                        }
                    }
            
                    // Pokud nebyla nalezena žádná odpovídající link položka nebo target položka, nebo target položka nemá start_date, vrátíme původní položku
                    //console.log('v taskBuilding - dateLinks - hledano podle source - item: ', item)
                    return item;
                });
            } while (isUpdated);
            
            //prirazeni datumu do tasku, ktere maji vazbu na jiny task pomoci linku, kde jsou jako target
            do {
                isUpdated = false;
            
                mapaTask = mapaTask.map(item => {
                    // Pokud položka již má nastavené start_date a end_date, vrátíme ji beze změny
                    if (item.start_date && item.end_date) {
                        return item;
                    }
                    // Najdeme všechny položky v mapaLink, které mají v target aktuální id
                    const linkItems = mapaLink.filter(i => i.target === item.id);
            
                    // Procházíme všechny nalezené linkItems
                    for (const linkItem of linkItems) {
                        // Najdeme odpovídající položku v mapaTask podle source z linkItem
                        const sourceItem = mapaTask.find(i => i.id === linkItem.source);
            
                        // Pokud taková položka existuje a má start_date
                        if (sourceItem && sourceItem.start_date) {
                            // Vypočteme end_date jako start_date source položky
                            let startDate = dayjs(sourceItem.end_date);
            
                            // Vypočteme start_date jako rozdíl end_date a duration
                            let endDate = startDate.add(item.duration, 'day');
            
                            // Pokud je startDate větší než item.start_date, vrátíme nový objekt s doplněnými daty
                            //console.log('v taskBuilding - hledano podle target - item.id: ', item.id, 'startDate: ', startDate, 'item.start_date', item.start_date)    
                            if (!item.start_date || startDate.isAfter(dayjs(item.start_date))) {
                                isUpdated = true;
                                return {...item, start_date: startDate.toISOString(), end_date: endDate.toISOString()};
                            }
                        }
                    }
            
                    // Pokud nebyla nalezena žádná odpovídající link položka nebo source položka, nebo source položka nemá start_date, vrátíme původní položku
                    return item;
                });
            } while (isUpdated);
            return mapaTask;
    }

    function dnesniDatum(mapaTask){
        // projde vsechny tasky a pokud nemaji start_date, doplni je aktualnim datumem
        mapaTask = mapaTask.map(item => {
            // Pokud položka nemá start_date a její typ není 'project'
            if (!item.start_date && item.type !== 'project') {
                // Nastavíme start_date na aktuální datum
                let startDate = dayjs();
                // Vypočteme end_date jako start_date + duration
                let endDate = startDate.add(item.duration, 'day');
                // Vrátíme nový objekt s doplněnými daty
                
                return {
                    ...item, 
                    id: typeof item.id === 'string' ? item.id.replace('idOP', idOP).replace('idMi', idMi) : item.id,
                    text: typeof item.text === 'string' ? item.text.replace('idOP', idOP).replace('idMi', idMi) : item.text,
                    parent: typeof item.parent === 'string' ? item.parent.replace('idOP', idOP).replace('idMi', idMi) : item.parent,
                    start_date: item.start_date ? item.start_date : startDate.toISOString(), 
                    end_date: item.end_date ? item.end_date : endDate.toISOString(),
                };
            }
            // Pokud položka má start_date nebo její typ je 'project', vrátíme ji beze změny, jen prejmenuje id, text a parent
            return {
                ...item, 
                id: typeof item.id === 'string' ? item.id.replace('idOP', idOP).replace('idMi', idMi) : item.id,
                text: typeof item.text === 'string' ? item.text.replace('idOP', idOP).replace('idMi', idMi).replace('nameOP', nameOP) : item.text, 
                parent: typeof item.parent === 'string' ? item.parent.replace('idOP', idOP).replace('idMi', idMi) : item.parent,
            };
        });
        return mapaTask;
    }

    function kotveniSmlouvaMilnik(mapaTask, mapaLink){
        if(smlouvaMilnik){
            //metoda map() projde každý prvek v poli mapaTask. Pokud prvek splňuje podmínku (tj. odpovídající fixTask existuje), vrátí se nový objekt s aktualizovanými hodnotami end_date a start_date
            let zalohaFakturaceTasks = [];
            let zalohaFakturaceLinks = [];
            let fixTaskList = [];
            let fixTaskWithoutZalohaFakturace = [];
            mapaTask = mapaTask.map(item => {
                //console.log('v taskBuilding - item', item);
                //nejprve zkontroluje, zda id položky končí na -A, a pokud ano, odstraní poslední dva znaky z id pomocí metody slice. Poté se idWithoutSuffix použije v porovnání s idProces.
                const fixTask = smlouvaMilnik.find(obj => {
                    const idWithoutSuffix = item.id.endsWith('-A') ? item.id.slice(0, -2) : item.id;
                    return (obj.idProces == idWithoutSuffix.split("-").pop() && item.type !== 'project');
                }); // hleda v smlouvaMilnik idProces, ktere je rovno id daneho item
                
                if (fixTask) {
                    if (fixTask.dateLimit){
                        console.log('--------------------------------------------------------- v taskBuilding - fixTask', fixTask);
                        let endDate = dayjs(fixTask.dateLimit).add(1, 'day'); // datum milniku prida na casovy konec Tasku
                        let startDate = endDate.subtract(item.duration, 'day'); // odecte cas trvani Tasku

                        
                        //definice tasku pro zalohu fakturace
                        //resource: 101, state: "model", mapaApp: true, hidden: false, id: "80-1-1-1", text: "PDE D2", start_date: "2024-05-01", duration: 3, parent: "80-1-1", capacity: 2
                        let zalohaFakturaceLink = null;
                        let zalohaFakturaceTask = null;
                        if(procesMapaZdroj?.ZalohaFakturace.length !== 0){
                            const zalohaFakturaceItem = procesMapaZdroj?.ZalohaFakturace.find(obj => obj.idSmlouvaMilnik == fixTask.id);
                            console.log('v kotveniSmlouvaMilnik - zalohaFakturaceItem', zalohaFakturaceItem);
                            const idZalohaFakturaceTask = item.state === 'actual' ? idOP + '-103-3001-' + zalohaFakturaceItem.id + '-A' : idOP + '-103-3001-' + zalohaFakturaceItem.id;
                            console.log('v kotveniSmlouvaMilnik - idZalohaFakturaceTask', idZalohaFakturaceTask);
                            zalohaFakturaceTask = { id: idZalohaFakturaceTask, text: zalohaFakturaceItem.druhFaktury + ' - ' + zalohaFakturaceItem.nameSmlouvaMilnik + ' - ' + zalohaFakturaceItem.fakturaValue + ' Kč',
                            resource: 113, state: item.state, mapaApp: item.mapaApp, hidden: item.hidden, duration: 0, parent: item.parent, type: 'milestone', startDate: endDate};
                            //{ id: "80-1-1-2-80-1-1-3", source: "80-1-1-2", target: "80-1-1-3", type: "0" },
                            zalohaFakturaceLink = { id: item.id + '-' + zalohaFakturaceTask.id, source: item.id, target: zalohaFakturaceTask.id, type: '0' };
                        }

                        if(item.type === 'milestone'){ // pokud je item typu milestone, tak start date nepotrebuji
                            startDate = endDate;
                        }
                        
                        if(item.state === 'model'){
                            item = {...item, end_date: endDate.toISOString(), start_date: startDate.toISOString(), color: 'green', type1: 'smlouvaMilnik' };
                            console.log('v kotveniSmlouvaMilnik - item je model - shoda s SmlouvaMilnik - menim item: ', item);
                        }else{
                            item = {...item, end_date: endDate.toISOString(), start_date: startDate.toISOString(), color: 'rgb(255, 128, 0)', type1: 'smlouvaMilnik' };
                            console.log('v kotveniSmlouvaMilnik - item je actual - shoda s SmlouvaMilnik - menim item: ', item);
                        }
                        
                        // Najdeme všechny položky v mapaLink, které mají source shodné s item.id
                        const linkedItems = mapaLink.filter(link => link.source == item.id);

                        // Pro každou takovou položku najdeme odpovídající položku v mapaTask a doplníme do ní start_date a end_date
                        linkedItems.forEach(link => {
                            const targetItem = mapaTask.find(task => task.id == link.target && task.type !== 'project');
                            if (targetItem) {
                                let startDate = dayjs(item.end_date);
                                let endDate = startDate.add(targetItem.duration, 'day');
                                targetItem.start_date = startDate.toISOString();
                                targetItem.end_date = endDate.toISOString();
                            }
                            
                        });
                        if(zalohaFakturaceTask && zalohaFakturaceLink){
                            zalohaFakturaceTasks.push(zalohaFakturaceTask);
                            zalohaFakturaceLinks.push(zalohaFakturaceLink);
                        }else{
                            fixTaskWithoutZalohaFakturace.push(fixTask);
                            console.error('v taskBuilding - chybi zalohaFakturaceTask nebo zalohaFakturaceLink, milnik: ', fixTask);
                        }
                        fixTaskList.push(fixTask);

                        //zalohaFakturaceTasks.push(zalohaFakturaceTask);
                        //zalohaFakturaceLinks.push(zalohaFakturaceLink);
                    }
                    setSeverity1('error');
                    setMessage1(
                        <div>
                            Našel se SmlouvaMilnik, ale není vyplněn datum
                            <br />
                            <Button style={{backgroundColor: theme.palette.secondary.dark, color: 'white'}} onClick={() => setMessage1(null)}>Doplním</Button>
                        </div>
                    );
                    
                    
                }
                return item  
            })
            mapaTask = [...mapaTask, ...zalohaFakturaceTasks]
            mapaLink = [...mapaLink, ...zalohaFakturaceLinks]
            
            if(fixTaskList.length === 0){
                setSeverity1('error');
                setMessage1(
                    <div>
                        Nenašla se žádná vazba na SmlouvaMilnik
                        <br />
                        <Button style={{backgroundColor: theme.palette.secondary.dark, color: 'white'}} onClick={() => setMessage1(null)}>Polepším se</Button>
                    </div>
                );
            }else if (fixTaskWithoutZalohaFakturace.length > 0){
                const missingTasks = fixTaskWithoutZalohaFakturace.map(task => `${task.id} ${task.name}`).join(', ');
                setSeverity1('error');
                setMessage1(
                    <div>
                        Chybí vazba na Fakturace pro SmlouvaMilnik: {missingTasks}
                        <br />
                        <Button style={{backgroundColor: theme.palette.secondary.dark, color: 'white'}} onClick={() => setMessage1(null)}>Polepším se</Button>
                    </div>
                );
            }else{
                setSeverity1('success');
                setMessage1('Milníky a Fakturace byly ukotveny');
                 // Close the alert after 5 seconds
                setTimeout(() => {
                    setMessage1(null);
                }, 2000);
            }

            return {mapaTask, mapaLink};
            
        }
        //console.log('v taskBuilding - po naplneni datumu do zvazbenych tasku od milniku - mapaTask: ', mapaTask);
    }

    function kotveniMilnik(mapaTask){
        if(procesMapaZdroj && procesList){
            console.log('v kotveniMilnik - procesMapaZdroj', procesMapaZdroj);
            //metoda map() projde každý prvek v poli mapaTask. Pokud prvek splňuje podmínku (tj. odpovídající fixTask existuje), vrátí se nový objekt s aktualizovanými hodnotami end_date a start_date
            let pocetPrirazenych = 0;
            mapaTask = mapaTask.map(item => {
                //console.log('v taskBuilding - item', item);
                //nejprve zkontroluje, zda id položky končí na -A, a pokud ano, odstraní poslední dva znaky z id pomocí metody slice. Poté se idWithoutSuffix použije v porovnání s idProces.
                const fixTask = procesList.find(obj => {
                    const idWithoutSuffix = item.id.endsWith('-A') ? item.id.slice(0, -2) : item.id;
                    return (obj.id == idWithoutSuffix.split("-").pop() && obj.typTask === 'milnik');
                }); // hleda v procesList id procesu aktualne prochazeneho v mapaTask, ktery je v procesList typu milnik
                
                if (fixTask) {
                    
                    console.log('v kotveniObchod - fixTask', fixTask);
                    let endDate = null
                    let startDate = null
                    const sourceTask = procesMapaZdroj?.[fixTask.entita]?.[0];
                    if (sourceTask) {
                        console.log('v kotveniObchod - procesMapaZdroj?.[fixTask.entita]?.[fixTask.exeStartDate]', sourceTask[fixTask.exeStartDate]);
                        console.log('v kotveniObchod - procesMapaZdroj?.[fixTask.entita]?.[fixTask.exeEndDate]', sourceTask[fixTask.exeEndDate]);
                        if(sourceTask[fixTask.exeStartDate]){
                            console.log('v kotveniObchod - procesMapaZdroj?.[fixTask.entita]?.exeStartDate', sourceTask[fixTask.exeStartDate]);
                            console.log('v kotveniObchod - item: ', item);
                        }
                    }

                    //definice tasku pro zalohu fakturace
                    //resource: 101, state: "model", mapaApp: true, hidden: false, id: "80-1-1-1", text: "PDE D2", start_date: "2024-05-01", duration: 3, parent: "80-1-1", capacity: 2
                    //console.log('v kotveniObchod - procesMapaZdroj?.[fixTask.entita]?.[fixTask.exeStartDate]', procesMapaZdroj?.[fixTask.entita]?.[0][fixTask.exeStartDate]);
                    //console.log('v kotveniObchod - procesMapaZdroj?.[fixTask.entita]?.[fixTask.exeEndDate]', procesMapaZdroj?.[fixTask.entita]?.[0][fixTask.exeEndDate]);
                    if(procesMapaZdroj?.[fixTask.entita]?.[0][fixTask.exeStartDate]){
                        console.log('v kotveniObchod - procesMapaZdroj?.[fixTask.entita]?.exeStartDate', procesMapaZdroj?.[fixTask.entita]?.[0][fixTask.exeStartDate]);
                        console.log('v kotveniObchod - item: ', item);
                        startDate = dayjs(procesMapaZdroj?.[fixTask.entita]?.[0][fixTask.exeStartDate]).add(0, 'day'); // datum milniku prida na casovy zacatek Tasku
                        endDate = startDate.add(item.duration, 'day'); // odecte cas trvani Tasku
                        if(startDate){pocetPrirazenych++}
                    }else if(procesMapaZdroj?.[fixTask.entita]?.[0][fixTask.exeEndDate]){
                        console.log('v kotveniObchod - procesMapaZdroj?.[fixTask.entita]?[fixTask.exeEndDate]', procesMapaZdroj?.[fixTask.entita]?.[0][fixTask.exeEndDate]);
                        console.log('v kotveniObchod - item: ', item);
                        endDate = dayjs(procesMapaZdroj?.[fixTask.entita]?.[0][fixTask.exeEndDate]).add(1, 'day'); // datum milniku prida na casovy konec Tasku
                        startDate = endDate.subtract(item.duration, 'day'); // odecte cas trvani Tasku
                        if(endDate){pocetPrirazenych++}
                    }
                    
                    
                    if(item.state === 'model'){
                        item = {...item, end_date: endDate ? endDate.toISOString() : null, start_date: startDate ? startDate.toISOString() : null, color: 'green', type1: 'smlouvaMilnik' };
                        console.log('v kotveniObchod - item je model - shoda s SmlouvaMilnik - menim item: ', item);
                    }else{
                        item = {...item, end_date: endDate ? endDate.toISOString() : null, start_date: startDate ? startDate.toISOString() : null, color: 'rgb(255, 128, 0)', type1: 'smlouvaMilnik' };
                    }
                    
                }
                return item  
            })
                      
            if(pocetPrirazenych === 0){
                setSeverity('error');
                setMessage
                (
                    <div>
                        Nenašlo se žádné pevné datum pro zakotvení obchodního procesu
                        <br />
                        <Button style={{backgroundColor: theme.palette.secondary.dark, color: 'white'}} onClick={() => setMessage(null)}>Polepším se</Button>
                    </div>
                );
            }else{
                setSeverity('success');
                setMessage('Obchodní proces byl ukotven');
                 // Close the alert after 5 seconds
                setTimeout(() => {
                    setMessage(null);
                }, 2000);
            }

            return mapaTask;
            
        }
        //console.log('v taskBuilding - po naplneni datumu do zvazbenych tasku od milniku - mapaTask: ', mapaTask);
    }

    function taskActual(mapaTask) {
        // projdu mapaTask a polozkam state: actual vyhledam skutecne hodnoty z procesMapaZdroj a doplnim je do tasku, a to vcetne tasku ktere jsou oznaceny z SmlouvaMilnik - z oranz se zmeni na blue
        if(!mapaTask){
            console.log('v taskActual - mapaTask je prazdny');
            return;
        }
        if(procesMapaZdroj){
            console.log('v taskActual - procesMapaZdroj: ', procesMapaZdroj);
            console.log('v taskActual - mapaTask: ', mapaTask);
            mapaTask = mapaTask.map(item => { // item je jeden task
                //console.log('v taskActual - item', item); 
                if(item.state === 'actual' && isNaN(item.id)){ //isNaN vrátí true, pokud hodnota není číslo (pripad Task ktery vznikl manualne v ganttGrafu)
                    let numberPart
                    let idMilnik
                    const idParts = item.id.split('-');
                    if(item.id.includes('3001')){ // pokud je item ZalohaFakturace ... 3001 je id ZalohaFakturace
                        numberPart = 3001;
                        idMilnik = idParts[idParts.length - 2];
                    }else{
                        
                        numberPart = Number(idParts[idParts.length - 2]);
                        
                    }
                    const itemParam = procesList.find(obj => obj.id === numberPart); // jeden radek z procesList daneho item
                    //console.log('v taskActual - itemParam: ', itemParam);
                    //console.log('v taskActual - numberPart: ', numberPart, 'idMilnik: ', idMilnik );
                    // ---------- oblast plneni dat da realizce task ------------------
                    if(itemParam && itemParam.entita === 'RealizacniCinnost'){

                        const fixTask = procesMapaZdroj[itemParam.entita].find(obj => {
                            return (obj.idProces == numberPart);
                        }); // hleda v smlouvaMilnik idProces, ktere je rovno id daneho item
                        //console.log('v taskActual - fixTask', fixTask);
                        if (fixTask) {
                            if(fixTask.dateStart && fixTask.dateKonec){
                                return {
                                    ...item, 
                                    start_date: dayjs(fixTask.dateStart).add(1, 'day').toISOString(),
                                    end_date: dayjs(fixTask.dateKonec).add(1, 'day').toISOString(),
                                    progress: 1,
                                    color: '#0b6bf1',
                                    capacity: fixTask.zakazkoveHodiny ? Number(fixTask.zakazkoveHodiny) : item.capacity,
                                    resource: fixTask.idPracovniZdroj ? fixTask.idPracovniZdroj : item.resource,
                                };
                            }else if(fixTask.dateStart && !fixTask.dateKonec){
                                return {
                                    ...item, 
                                    start_date: dayjs(fixTask.dateStart).add(1, 'day').toISOString(),
                                    end_date: dayjs().toISOString(),
                                    progress: 0.5,
                                    color: '#0b6bf1',
                                    capacity: fixTask.zakazkoveHodiny ? Number(fixTask.zakazkoveHodiny) : item.capacity,
                                };
                            }else if(!fixTask.dateStart && !fixTask.dateKonec){
                                return {
                                    ...item, 
                                    progress: 0,
                                    capacity: fixTask.zakazkoveHodiny ? Number(fixTask.zakazkoveHodiny) : item.capacity,
                                };
                            }else{
                                return item;
                            }
                        }

                    // ---------- oblast plneni dat na jine tasky nez Realizacni cinnost------------------
                    }else if (itemParam){
                        let zdroj
                        if(numberPart === 3001){ // pokud je item ZalohaFakturace
                            zdroj = procesMapaZdroj.ZalohaFakturace.find(obj => obj.id == idMilnik);
                        }else{
                            zdroj = itemParam?.druh 
                            ? procesMapaZdroj[itemParam.entita].find(obj => obj[itemParam.druh] === itemParam.druhValue)
                            : procesMapaZdroj[itemParam.entita][0]; // plati, kdyz neni vice inst. mist POZOR
                        }
                        console.log('v taskActual - nalezeny zdrojovy paramatetr v entite, z ktereho se plni date v task', zdroj, 'itemParam', itemParam);
                        if(zdroj){
                            if(zdroj[itemParam.exeStartDate] && zdroj[itemParam.exeEndDate]){
                                return {
                                    ...item, 
                                    start_date: dayjs(zdroj[itemParam.exeStartDate]).add(1, 'day').toISOString(),
                                    end_date: dayjs(zdroj[itemParam.exeEndDate]).add(1, 'day').toISOString(),
                                    progress: 100,
                                    color: '#0b6bf1',
                                    
                                };
                            }else if(zdroj[itemParam.exeStartDate] && !zdroj[itemParam.exeEndDate]){
                                return {
                                    ...item, 
                                    start_date: dayjs(zdroj[itemParam.exeStartDate]).add(1, 'day').toISOString(),
                                    end_date: dayjs().toISOString(),
                                    progress: 50,
                                    color: '#0b6bf1',
                                    
                                };
                            }else if(!zdroj[itemParam.exeStartDate] && zdroj[itemParam.exeEndDate]){
                                return {
                                    ...item, 
                                    end_date: dayjs(zdroj[itemParam.exeEndDate]).add(0, 'day').toISOString(),
                                    start_date: dayjs(zdroj[itemParam.exeEndDate]).subtract(item.duration, 'day').toISOString(), // odecte cas trvani Tasku
                                    progress: 50,
                                    color: '#0b6bf1',
                                    
                                };
                            }else if(!zdroj[itemParam.exeStartDate] && !zdroj[itemParam.exeEndDate]){
                                return {
                                    ...item, 
                                    progress: 0,
                                    
                                };
                            }else{
                                return item;
                            }
                    
                        }
                    
                    }
                }
                return item;
            });
            return mapaTask;
        }
    }
    
    function seraditTask(mapaTask){
        // seradi tasky podle parent, ktere jsou v poli
        return mapaTask.sort((a, b) => {
            // kontroluje, zda parent zacina na '100' az '199' nebo '1' az '9'
            const aStarts100to199 = /^84-1[0-9]{2}/.test(a.parent);
            const bStarts100to199 = /^84-1[0-9]{2}/.test(b.parent);
            const aStarts1to9 = /^84-[1-9]$/.test(a.parent);
            const bStarts1to9 = /^84-[1-9]$/.test(b.parent);
    
            // pokud oba zacinaji na '100' az '199' nebo oba na '1' az '9', porovname je jako obvykle
            if ((aStarts100to199 && bStarts100to199) || (aStarts1to9 && bStarts1to9)) {
                if (a.parent < b.parent) {
                    return -1;
                }
                if (a.parent > b.parent) {
                    return 1;
                }
                return 0;
            }
    
            // pokud a zacina na '100' az '199' a b ne, a by melo byt prvni
            if (aStarts100to199 && !bStarts100to199) {
                return -1;
            }
    
            // pokud b zacina na '100' az '199' a a ne, b by melo byt prvni
            if (bStarts100to199 && !aStarts100to199) {
                return 1;
            }
    
            // pokud a zacina na '1' az '9' a b ne, a by melo byt posledni
            if (aStarts1to9 && !bStarts1to9) {
                return 1;
            }
    
            // pokud b zacina na '1' az '9' a a ne, b by melo byt posledni
            if (bStarts1to9 && !aStarts1to9) {
                return -1;
            }
    
            // pokud ani a ani b nespadají do výše uvedených kategorií, porovnejte je jako obvykle
            if (a.parent < b.parent) {
                return -1;
            }
            if (a.parent > b.parent) {
                return 1;
            }
            return 0;
        });
    }
    const mapaProject =  [
        { state: "model", mapaApp: true, hidden: false, id: "idOP", text: "OP idOP nameOP", type: "project", open: true, color: "#1a1a1a" },
                        
        { state: "model", mapaApp: true, hidden: false, id: "idOP-100", text: "Obchod idOP nameOP", type: "project", open: true, parent: "idOP", color: "#666666" },
        { state: "model", mapaApp: true, hidden: false, id: "idOP-101", text: "Dotace idOP nameOP", type: "project", open: true, parent: "idOP", color: "#666666" },
        { state: "model", mapaApp: true, hidden: false, id: "idOP-102", text: "Nákup idOP nameOP", type: "project", open: true, parent: "idOP", color: "#666666" },
        { state: "model", mapaApp: true, hidden: false, id: "idOP-103", text: "Fakturace idOP nameOP", type: "project", open: true, parent: "idOP", color: "#666666" },
        { state: "model", mapaApp: true, hidden: false, id: "idOP-idMi", text: "idOP nameOP Místo instalace - idMi", type: "project", open: true, parent: "idOP", color: "#666666" },
                
        { state: "model", mapaApp: true, hidden: false, id: "idOP-idMi-1", text: "Projektová činnost - BEZ SP idOP nameOP", type: "project", open: true, parent: "idOP-idMi", color: "#999999" },
        { state: "model", mapaApp: true, hidden: false, id: "idOP-idMi-2", text: "Projektová činnost - SP idOP nameOP", type: "project", open: true, parent: "idOP-idMi", color: "#999999" },
        { state: "model", mapaApp: true, hidden: false, id: "idOP-idMi-3", text: "Stavební řízení idOP nameOP", type: "project", open: true, parent: "idOP-idMi", capacity: 40, color: "#999999" },
        { state: "model", mapaApp: true, hidden: false, id: "idOP-idMi-4", text: "Distribuce idOP nameOP", type: "project", open: true, parent: "idOP-idMi", color: "#999999" },
        { state: "model", mapaApp: true, hidden: false, id: "idOP-idMi-5", text: "Realizace idOP nameOP", type: "project", open: true, parent: "idOP-idMi", color: "#999999" },
        
        { state: "model", mapaApp: true, hidden: false, id: "idOP-idMi-4-1", text: "Distribuce - Výrobna 100+ kWp (NN/VN/VVN)", type: "project", open: true, parent: "idOP-idMi-4", color: "#999999" },
        { state: "model", mapaApp: true, hidden: false, id: "idOP-idMi-4-2", text: "Distribuce - Výrobna do 100 kWp (VN/VVN)", type: "project", open: true, parent: "idOP-idMi-4", color: "#999999" },
        { state: "model", mapaApp: true, hidden: false, id: "idOP-idMi-4-3", text: "Distribuce - Výrobna do 100 kWp (NN)", type: "project", open: true, parent: "idOP-idMi-4", color: "#999999" },
        { state: "model", mapaApp: true, hidden: false, id: "idOP-idMi-4-4", text: "Distribuce - Výrobna do 50 kWp", type: "project", open: true, parent: "idOP-idMi-4", color: "#999999" },
      
    ]
   
    const dataX =  [
        { state: "model", mapaApp: true, hidden: false, id: "78", text: "OP 78", type: "project", open: true, color: "#1a1a1a" },
        { state: "model", mapaApp: true, hidden: false, id: "79", text: "OP 79", type: "project", open: true, color: "#1a1a1a" },
        { state: "model", mapaApp: true, hidden: false, id: "80", text: "OP 80", type: "project", open: true, color: "#1a1a1a" },
                
        { state: "model", mapaApp: true, hidden: false, id: "80-100", text: "Obchod", type: "project", open: true, parent: "idOP", color: "#666666" },
        { state: "model", mapaApp: true, hidden: false, id: "80-101", text: "Dotace", type: "project", open: true, parent: "80", color: "#666666" },
        { state: "model", mapaApp: true, hidden: false, id: "80-102", text: "Nákup", type: "project", open: true, parent: "80", color: "#666666" },
        { state: "model", mapaApp: true, hidden: false, id: "80-103", text: "Fakturace", type: "project", open: true, parent: "80", color: "#666666" },
        { state: "model", mapaApp: true, hidden: false, id: "80-1", text: "Místo instalace - idMi", type: "project", open: true, parent: "80", color: "#666666" },
                
        { state: "model", mapaApp: true, hidden: false, id: "80-101-1", text: "Potvrzení tech. parametrů", start_date: "2024-05-06", duration: 4, parent: "80-101", capacity: 15 },
        { state: "model", mapaApp: true, hidden: false, id: "80-101-4", text: "Zaslání nabídky", start_date: "2024-05-08", duration: 0, parent: "80-101", type: "milestone", capacity: 0,  },
        { state: "model", mapaApp: true, hidden: false, id: "80-101-10", text: "Dotace vyřízena", start_date: "2024-05-10", duration: 0, parent: "80-101", type: "milestone", capacity: 0,  },
        
        
        
        { state: "model", mapaApp: true, hidden: false, id: "80-1-1", text: "Projektová činnost - Stavební povolení", type: "project", open: true, parent: "80-1", color: "#999999" },
        { state: "model", mapaApp: true, hidden: false, id: "80-1-2", text: "Projektová činnost - BEZ Stavebního povolení", type: "project", open: true, parent: "80-1", color: "#999999" },
        { state: "model", mapaApp: true, hidden: false, id: "80-1-3", text: "Stavební řízení", type: "project", open: true, parent: "80-1", capacity: 40, color: "#999999" },
        { state: "model", mapaApp: true, hidden: false, id: "80-1-4", text: "Distribuce", type: "project", open: true, parent: "80-1", color: "#999999" },
        { state: "model", mapaApp: true, hidden: false, id: "80-1-5", text: "Realizace", type: "project", open: true, parent: "80-1", color: "#999999" },
        
        { state: "model", mapaApp: true, hidden: false, id: "80-1-4-1", text: "Distribuce - Výrobna 100+kWp (NN/VN/VVN)", type: "project", open: true, parent: "80-1-4", color: "#999999" },
        { state: "model", mapaApp: true, hidden: false, id: "80-1-4-2", text: "Distribuce - Výrobna do 100kWp (VN/VVN)", type: "project", open: true, parent: "80-1-4", color: "#999999" },
        { state: "model", mapaApp: true, hidden: false, id: "80-1-4-3", text: "Distribuce - Výrobna do 100kWp (NN)", type: "project", open: true, parent: "80-1-4", color: "#999999" },
        { state: "model", mapaApp: true, hidden: false, id: "80-1-4-4", text: "Distribuce - Mikrozdroj", type: "project", open: true, parent: "80-1-4", color: "#999999" },
               
        { resource: 101, state: "model", mapaApp: true, hidden: false, id: "80-1-1-1", text: "PDE D2", start_date: "2024-05-01", duration: 3, parent: "80-1-1", capacity: 2 },
        { resource: 101, state: "actual", mapaApp: true, hidden: false, id: "80-1-1-1-A", text: "PDE D2", start_date: "2024-05-03", end_date: "2024-05-10", parent: "80-1-1", capacity: 2 },
        
        { resource: 102, state: "model", mapaApp: true, hidden: false, id: "80-1-1-2", text: "PBŘ", start_date: "2024-05-01", duration: 2, parent: "80-1-1", capacity: 2 },
        { resource: 102, state: "actual", mapaApp: true, hidden: false, id: "80-1-1-2-A", text: "PBŘ", start_date: "2024-05-05", end_date: "2024-05-12", parent: "80-1-1", capacity: 2 },

        { resource: 103, state: "model", mapaApp: true, hidden: false, id: "80-1-1-3", text: "Stanovisko hasičů", start_date: "2024-05-03", duration: 2, parent: "80-1-1", capacity: 2 },
        { resource: 103, state: "actual", mapaApp: true, hidden: false, id: "80-1-1-3-A", text: "Stanovisko hasičů", start_date: "2024-05-12", end_date: "", parent: "80-1-1", capacity: 2 },

        { resource: 102, state: "model", mapaApp: true, hidden: false, id: "80-1-1-4", text: "Statický posudek", start_date: "2024-05-01", duration: 2, parent: "80-1-1", capacity: 2 },
        { resource: 102, state: "model", mapaApp: true, hidden: false, id: "80-1-1-5", text: "DSP (dokumentace stav.povolení)", start_date: "2024-05-01", duration: 2, parent: "80-1-1", capacity: 4 },
        { resource: 102, state: "model", mapaApp: true, hidden: false, id: "80-1-1-6", text: "Stanovisko Životního prostředí", start_date: "2024-05-03", duration: 2, parent: "80-1-1", capacity: 2 },
        { resource: 102, state: "model", mapaApp: true, hidden: false, id: "80-1-1-8", text: "PD Ostatní", start_date: "2024-05-01", duration: 4, parent: "80-1-1", capacity: 2 },
        { resource: 102, state: "model", mapaApp: true, hidden: false, id: "80-1-1-9", text: "Stanoviska Ostatní", start_date: "2024-05-05", duration: 2, parent: "80-1-1", capacity: 2 },
        { resource: 102, state: "model", mapaApp: true, hidden: false, id: "80-1-1-20", text: "Kompletní PD ke stavebnímu povolení", start_date: "2024-05-10", duration: 0, parent: "80-1-1", type: "milestone",  capacity: 0 },
        { resource: 102, state: "actual", mapaApp: true, hidden: false, id: "80-1-1-20-A", text: "Kompletní PD ke stavebnímu povolení", start_date: "2024-05-15", duration: 0, parent: "80-1-1", type: "milestone", capacity: 0 },

        
  
        { resource: 103, state: "model", mapaApp: true, hidden: false, id: "80-1-3-5", text: "Podání žádosti o SP", start_date: "2024-05-10", parent: "80-1-3", type: "milestone", capacity: 1 },
        { resource: 103, state: "model", mapaApp: true, hidden: false, id: "80-1-3-9", text: "Vydání stavebního povolení", start_date: "2024-05-15", parent: "80-1-3", type: "milestone", capacity: 1 },
        { resource: 103, state: "model", mapaApp: true, hidden: false, id: "80-1-3-10", text: "Nabytí právní moci", start_date: "2024-05-18", parent: "80-1-3", type: "milestone", capacity: 1 },

        { resource: 112, state: "model", mapaApp: true, hidden: false, id: "80-1-4-1", text: "00 Příprava dat", start_date: "2024-05-06", duration: 3, parent: "80-1-4", capacity: 2 },
        { resource: 112, state: "model", mapaApp: true, hidden: false, id: "80-1-4-2", text: "01 JPS Zadáno", start_date: "2024-05-08", duration: 3, parent: "80-1-4", capacity: 1 },
        { resource: 112, state: "model", mapaApp: true, hidden: false, id: "80-1-4-3", text: "02 JPS Přijato", start_date: "2024-05-09", duration: 0, parent: "80-1-4", type: "milestone", capacity: 0 },
        { resource: 112, state: "model", mapaApp: true, hidden: false, id: "80-1-4-10", text: "Stanovisko", start_date: "2024-05-09", duration: 10, parent: "80-1-4", capacity: 1 },
        { resource: 112, state: "actual", mapaApp: true, hidden: false, id: "80-1-4-10-A", text: "Stanovisko", start_date: "2024-05-10", duration: 10, parent: "80-1-4", capacity: 1 },
        { resource: 112, state: "model", mapaApp: true, hidden: false, id: "80-1-4-4", text: "15 Zažádáno o UTP", start_date: "2024-05-08", duration: 5, parent: "80-1-4", capacity: 2},
        { resource: 112, state: "model", mapaApp: true, hidden: false, id: "80-1-4-5", text: "16 PPP - protokol přijat", start_date: "2024-05-12", parent: "80-1-4", type: "milestone", capacity: 0},

        { resource: 104, state: "model", mapaApp: true, hidden: false, id: "80-1-5-1", text: "Obhlídka místa", start_date: "2024-05-12", duration: 1, parent: "80-1-5",  capacity: 8},
        { resource: 16, state: "model", mapaApp: true, hidden: false, id: "80-1-5-2", text: "Instalace FVP", start_date: "2024-05-12", duration: 14, parent: "80-1-5",  capacity: 200},
        { resource: 11, state: "model", mapaApp: true, hidden: false, id: "80-1-5-3", text: "Instalace Elektro", start_date: "2024-05-12", duration: 14, parent: "80-1-5",  capacity: 200},
        { resource: 104, state: "model", mapaApp: true, hidden: false, id: "80-1-5-1", text: "Předání instalace", start_date: "2024-05-26", duration: 1, parent: "80-1-5",  capacity: 8},

    ]
   
    const links = [
        
        { id: "80-1-1-2-80-1-1-3", source: "80-1-1-2", target: "80-1-1-3", type: "0" },
        { id: "80-1-1-5-80-1-1-6", source: "80-1-1-5", target: "80-1-1-6", type: "0" },
        { id: "80-1-1-8-80-1-1-9", source: "80-1-1-8", target: "80-1-1-9", type: "0" },
        { id: "80-1-1-1-80-1-1-20", source: "80-1-1-1", target: "80-1-1-20", type: "0" },
        { id: "80-1-1-2-80-1-1-20", source: "80-1-1-2", target: "80-1-1-20", type: "0" },
        { id: "80-1-1-3-80-1-1-20", source: "80-1-1-3", target: "80-1-1-20", type: "0" },
        { id: "80-1-1-4-80-1-1-20", source: "80-1-1-4", target: "80-1-1-20", type: "0" },
        { id: "80-1-1-5-80-1-1-20", source: "80-1-1-5", target: "80-1-1-20", type: "0" },
        { id: "80-1-1-6-80-1-1-20", source: "80-1-1-6", target: "80-1-1-20", type: "0" },
        { id: "80-1-1-8-80-1-1-20", source: "80-1-1-8", target: "80-1-1-20", type: "0" },
        { id: "80-1-1-9-80-1-1-20", source: "80-1-1-9", target: "80-1-1-20", type: "0" },

        { id: "80-1-1-20-80-1-3-5", source: "80-1-1-20", target: "80-1-3-5", type: "0" },

        { id: "80-1-3-5-80-1-3-9", source: "80-1-3-5", target: "80-1-3-9", type: "0" },

        { id: "80-1-1-1-80-1-4-10", source: "80-1-1-1", target: "80-1-4-10", type: "0" },

        { id: "80-1-3-9-80-1-3-10", source: "80-1-3-9", target: "80-1-3-10", type: "0" },

        { id: "80-1-1-1-A-80-1-1-20-A", source: "80-1-1-1-A", target: "80-1-1-20-A", type: "0" },
        { id: "80-1-1-1-A-80-1-4-10-A", source: "80-1-1-1-A", target: "80-1-4-10-A", type: "0" },
        { id: "80-1-1-2-A-80-1-1-3-A", source: "80-1-1-2-A", target: "80-1-1-3-A", type: "0" },

        
   ]

    const resource = [
        { id: 10, text: "Realizace", parent: null, capacity: 54 },
        { id: 16, text: "Montéři1",  backgroundColor: "#03A9F4", textColor: "#FFF", parent: 10, capacity: 30  },
        { id: 17, text: "Montéři2",  backgroundColor: "#03A9F4", textColor: "#FFF", parent: 10, capacity: 30  },
        { id: 11, text: "Realizace Staňkov", backgroundColor: "#03A9F4", textColor: "#FFF", parent: 10, capacity: 24 },
        { id: 12, text: "Realizace Cheb", backgroundColor: "#03A9F4", textColor: "#FFF", parent: 10, capacity: 24 },
        { id: 13, text: "Realizace FM",  backgroundColor: "#03A9F4", textColor: "#FFF", parent: 10, capacity: 24  },
        { id: 14, text: "Realizace Roudnice",  backgroundColor: "#03A9F4", textColor: "#FFF", parent: 10, capacity: 16  },
        { id: 15, text: "Realizace Kyjov",  backgroundColor: "#03A9F4", textColor: "#FFF", parent: 10, capacity: 16  },
        
        { id: 20, text: "Obchod", parent: null },
        { id: 30, text: "Technické oddělení", parent: null },
        { id: 40, text: "Vedení projektu", parent: null },
        { id: 50, text: "Nákup", parent: null },
        { id: 101, text: "Pepa V.", backgroundColor: "#03A9F4", textColor: "#FFF", parent: 30 },
        { id: 102, text: "Jirka H.", backgroundColor: "#f57730", textColor: "#FFF", parent: 40 },
        { id: 103, text: "Milan H.", backgroundColor: "#e157de", textColor: "#FFF", parent: 40 },
        { id: 104, text: "David R.", backgroundColor: "#78909C", textColor: "#FFF", parent: 11 },
        { id: 105, text: "Jarda V.", backgroundColor: "#8D6E63", textColor: "#FFF", parent: 13 },
        { id: 106, text: "Beky", backgroundColor: "#03A9F4", textColor: "#FFF", parent: 12 },
        { id: 107, text: "Roman", backgroundColor: "#03A9F4", textColor: "#FFF", parent: 14 },
        { id: 108, text: "Pavel K.", backgroundColor: "#03A9F4", textColor: "#FFF", parent: 15 },
        { id: 109, text: "Katka", backgroundColor: "#f57730", textColor: "#FFF", parent: 50 },
        { id: 110, text: "Michal Majer", backgroundColor: "#f57730", textColor: "#FFF", parent: 20 },
        { id: 111, text: "Pavel Nosko", backgroundColor: "#f57730", textColor: "#FFF", parent: 20 },
        { id: 112, text: "Simona", backgroundColor: "#f57730", textColor: "#FFF", parent: 40 },
        { id: 113, text: "Lucka", backgroundColor: "#f57730", textColor: "#FFF", parent: 40 },
        { id: 199, text: "Revize", backgroundColor: "#f57730", textColor: "#FFF", parent: 10 },
        { id: 1000, text: "Nepřiřazeno", parent: null, default: true },
    ]

    const priority = [
		{key: 1, label: "High"},
		{key: 2, label: "Normal"},
		{key: 3, label: "Low"}
	];

    const type1 = [
		{id: 1, text: 'smlouvaMilnik'},
	];
    
   
    const vstupDataXData = vstupDataX?.data;
    const vstupDataXLinks = vstupDataX?.links;

    //console.log('data', dataX);
    
    if (!vstupDataXData || vstupDataXData.length === 0 || !vstupDataXLinks || vstupDataXLinks.length === 0) {
        return <LinearIndeterminate/>
    } else {    
    return (
            
            <div>
                { message && (
                        <Alert variant="outlined" severity={severity} style={{ position: 'fixed', top: 90, left: `50%`,
                        transform: 'translate(-50%, -0%)', zIndex: 9999, height: 120, width: 500, overflow: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'white' }}>
                            {message}
                        </Alert>
                    )}
                    { message1 && (
                        <Alert variant="outlined" severity={severity1} style={{ position: 'fixed', top: 220, left: `50%`,
                        transform: 'translate(-50%, -0%)', zIndex: 9999, height: 120, width: 500, overflow: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'white' }}>
                            {message1}
                        </Alert>
                    )}
                <Gantt tableEntita={tableEntita} dataVstup={vstupDataXData} links={vstupDataXLinks} resource={resource} priority={priority} type1={type1} taskBuilding={taskBuilding} taskAktualizuj={taskAktualizuj} vizuProcesMapa={vizuProcesMapa}/>
                                 
            </div>
            
                
        );
    }
   

      
};

