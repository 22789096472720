// detailData.js
import { getDataStructurEnt, getData1, paramsList, authDetailSection, paramsListSelected, getData4, checkTaskStatus, getSablonaList} from './apiRest'; // Předpokládám, že tyto funkce jsou importovány z './api'
export const detailDataNutne = async (tableEntita, idUn, page, structureGlob, paramsLAllAll) => {
    try {
        const token = localStorage.getItem('token');
        //console.log('jsem v detailData: ', tableEntita, idUn, page);
        //const structurEntGlob = await getDataStructurEnt(tableEntita, page, token);
        const structurEntGlob = structureGlob.filter(item => item.tableEntita === tableEntita);
        //console.log('structurEntGlob: ', structurEntGlob);


        //const dataGlob = await getData1(tableEntita, idUn, page, token);
        //console.log('vracim z detailData: ', structurEntGlob, dataGlob);
        const response1 = await getData1(tableEntita, idUn, page, token);
        const taskId1 = response1.taskId;
        const result1 = await checkTaskStatus(taskId1, 'detailData');
        const dataGlob = result1.data;
        
        /*
        //const paramsLAllGlob = await paramsList(tableEntita, page, token);
        const response2 = await paramsList(tableEntita, page, token);
        const taskId2 = response2.taskId;
        const result2 = await checkTaskStatus(taskId2, 'detailData');
        const paramsLAllGlob = result2.data;
        */

        const paramsLAllGlob = paramsLAllAll.filter(item => item.tableEntita === tableEntita);
        console.log('paramsLAllGlob: ', paramsLAllGlob);

        const headers = paramsLAllGlob.map(objekt => objekt.parametr);
        const detailSectionsGlob = await authDetailSection(paramsLAllGlob);
        
        const paramsLGlob = await paramsListSelected(paramsLAllGlob, detailSectionsGlob);

        //console.log('vracim z detailData Store: ', structurEntGlob, dataGlob, paramsLAllGlob, detailSectionsGlob, paramsLGlob, response1, taskId1, dataEntitaListGlob, dataDefaultGlob, sablonaListGlob);
        return { structurEntGlob, dataGlob, paramsLAllGlob, detailSectionsGlob, paramsLGlob, headers };
    } catch (error) {
        console.error(error);
        console.log('nastal session problem na serveru, user bude odhlasen ', error.message);
        if (error.message.includes('/logout')) {
            window.location.href = '/logout';
        }
    }
};

export const detailDataRest = async (tableEntita, idUn, page, paramsLGlob, dataGlob) => {
    try {
        const token = localStorage.getItem('token');
        
        const response1 = await getData4(paramsLGlob, 'pole', idUn, '', '', dataGlob, token);
        const taskId1 = response1.taskId;
        const result1 = await checkTaskStatus(taskId1, 'detailData-pole');
        const dataEntitaListGlob = result1;
        //console.log('dataEntitaListGlob: ', dataEntitaListGlob);

        const response2 = await getData4(paramsLGlob, 'default', idUn, '', '', '', token); //vrátí pole vsech default a jejich hodnot použitých v detailu entity
        const taskId2 = response2.taskId;
        const dataDefaultGlobX = await checkTaskStatus(taskId2, 'detailData-default') // volá funkci pro kontrolu stavu úkolu, odpoved dostane az po skonceni, v odpovedi jsou vysledna data taskId{ taskResults: {data: outputObj}, taskStatus: "Done" }
        //console.log('0000000000 - dataDefaultGlobX: ', dataDefaultGlobX);
        const dataDefaultGlob = dataDefaultGlobX.data;
        //console.log('----------------------------------------------------- - dataDefaultGlob: ', dataDefaultGlob);
        //console.log('----------------------------------------------------- - paramsLGlob: ', paramsLGlob);
        const paramsDefaultGlob = paramsLGlob.filter(item => item.input === 'default'); // vyfiltruje z pole jen ty položky, kde input: 'default'

        //const sablonaListGlob = await getSablonaList(tableEntita, token); //vrátí pole vsech sablon nalezejicich k entite
    

        console.log('vracim z detailData Store, dataDefaultGlob: ', dataDefaultGlob, 'dataEntitaListGlob: ', dataEntitaListGlob, 'paramsDefaultGlob: ', paramsDefaultGlob);
        return { response1, taskId1, dataEntitaListGlob, dataDefaultGlob, paramsDefaultGlob};
    } catch (error) {
        console.error(error);
        console.log('nastal session problem na serveru, user bude odhlasen ', error.message);
        if (error.message.includes('/logout')) {
            window.location.href = '/logout';
        }
    }
};

