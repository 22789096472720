import React, { useState, useRef, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { TextField } from '@mui/material';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
//import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import dayjs from 'dayjs';
import FormatCZK from '../atoms/FormatCZK';
import IconButton from '@mui/material/IconButton';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Checkbox from '@mui/material/Checkbox';
import CheckIcon from '@mui/icons-material/Check';
import theme from '../theme';

// Registrace potřebných modulů
ModuleRegistry.registerModules([ExcelExportModule, RowGroupingModule]);

const TableKalk = ({ tableEntita, data, headers, columnAccessors, onRowClick, mjs, inputFormat }) => {
  const gridRef = useRef(null);
  const [globalFilter, setGlobalFilter] = useState("");

  const onGridReady = params => {
    gridRef.current = params.api;
    params.api.sizeColumnsToFit();
  };
  
  const confirmSelection = () => {
    const allData = [];
    gridRef.current.forEachNode(node => allData.push(node.data));
    console.log("Všechna data:", allData);
    const selectedData = allData.filter(row => (row.kpMnAdd && row.kpMnAdd > 0) || (row.ks1MnAdd && row.ks1MnAdd > 0) || (row.ks2MnAdd && row.ks2MnAdd > 0));
    console.log("Vybrané řádky na základě 'Počet':", selectedData);
    selectedData.forEach((data, index) => {
      const reversedIndex = selectedData.length - index;
      onRowClick(tableEntita, data.id, reversedIndex);
    });
    // Tady můžete implementovat další logiku pro zpracování vybraných dat
  };

  let columnDefs = headers.map((header, index) => ({
    headerName: header,
    field: columnAccessors[index],
    filter: 'agTextColumnFilter',
    minWidth: header.includes('Název') ? 400
    : header.startsWith('id') ? 70
    : columnAccessors[index].includes('app') || columnAccessors[index].includes('def') ? 120
    : columnAccessors[index].includes('Mj') ? 70 
    : columnAccessors[index].includes('Marze') || columnAccessors[index].includes('Cena') ? 130
    : 90,
    editable: columnAccessors[index].includes('Add'),
    cellStyle: columnAccessors[index].includes('Add') ? { backgroundColor: theme.palette.secondary.light } : null,
    headerClass: 'headerClass-tableKalk',
    wrapHeaderText: true, // Zapne zalamování textu
    autoHeaderHeight: true, // Nastaví automatickou výšku
    cellRenderer: (params) => {
      const value = params.value;
      const mj = mjs[index];
      const format = inputFormat[index];
      let displayValue = value;
      if (mj === 'Kč') displayValue = FormatCZK(value);
      else if (format === 'date') displayValue = dayjs(value).format('DD.MM.YYYY');
      else if (format === 'dateTime') displayValue = dayjs(value).format('DD.MM.YYYY HH:mm');
      else if (format === 'percent') displayValue = `${value}%`;
      return displayValue;
    },
    aggFunc: mjs[index] === 'Kč' ? 'sum' : null,
    enableRowGroup: true,
    enablePivot: true,
  }));

  return (
    <div className="ag-theme-alpine" style={{ height: 600, width: '100%' }}>
      <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
      <IconButton
                      title="Přepočítej"
                      onClick={confirmSelection}
                      sx={{ 
                          margin: '15px',
                          marginLeft: '12px',
                          backgroundColor: theme.palette.secondary.dark,
                          fontWeight: 700,
                          '&:hover': {
                              backgroundColor: theme.palette.secondary.main,
                          },
                      }}
                  >
                      <CheckIcon
                          sx={{ 
                              color: 'white', 
                              fontSize: '22px'
                          }}
                      />
        </IconButton>
        <TextField
          type="search"
          onChange={e => setGlobalFilter(e.target.value)}
          placeholder="Hledej..."
          autoFocus // Přidá automatické zaměření na pole pro vyhledávání
          variant="outlined"
          size="small"
          style={{ width: '300px' }}
        />
               
      </div>

      <AgGridReact
        columnDefs={columnDefs}
        rowData={data}
        onGridReady={onGridReady}
        quickFilterText={globalFilter}
        domLayout='autoHeight'
      />
    </div>
  );
};

export default TableKalk;
