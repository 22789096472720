// LinearIndeterminate atom component
import * as React from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { theme } from '../theme';

export default function LinearIndeterminate() {
    
  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgress sx={{ bgcolor: (theme) => theme.palette.secondary.main }} />
    </Box>
  );
}
