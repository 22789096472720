//basedata.jsx
import React, { createContext, useState, useEffect } from 'react';

// Vytvoření kontextu
export const GenContext = createContext();

const token = localStorage.getItem('token');
//console.log('token ulozeny v localStorage: ', token);
const userEmail = localStorage.getItem('userEmail');
console.log('userEmail ulozeny v localStorage: ', userEmail);

// Provider komponenta
export const GenProvider = ({ children }) => {
    
    const [isHeaderMenuVisible, setIsHeaderMenuVisible] = useState(false);
    
    // Objekt s daty a funkcemi, které budou dostupné ve všech komponentách
    const store = {
        isHeaderMenuVisible,
        setIsHeaderMenuVisible,
       
    };
    //console.log('V DataContext.js - store', store);

    return (
        <GenContext.Provider value={store}>
            {children}
        </GenContext.Provider>
    );
};

