import moment from 'moment';
// api/apiRest.js
// getDataStructurEnt, getDataList, paramsList, paramsLLimit
//const token = localStorage.getItem('token');


export const getDataList = async (tableEntita, page, token) => {
    try {
        const response = await fetch('/get-data', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                tableEntita: tableEntita,
                page: page
            })
        });

        /*
        if (!response.ok) {
            const errorText = await response.text();
            throw new Error(errorText);
        }
        */

        if (!response.ok) {
            const errorData = await response.json();
            if (errorData.redirect) {
                window.location.href = errorData.redirect;
            } else {
                const errorText = JSON.stringify(errorData);
                throw new Error(errorText);
            }
        }

        return await response.json();
    } catch (error) {
        console.error("Error fetching data list:", error);
        
        throw error;
    }
};

export const fetchSections = async (token) => {
    const response = await fetch('/api/get-section', {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        
    });
    /*
    if (!response.ok) {
        return response.text().then(error => {
        throw new Error(error);
    });
    }
    */

    if (!response.ok) {
        const errorData = await response.json();
        if (errorData.redirect) {
            window.location.href = errorData.redirect;
        } else {
            const errorText = JSON.stringify(errorData);
            throw new Error(errorText);
        }
    }

    if (response.status === 401) {
        console.log('v fetchSections - chyba, response.status: ', response.status);
        // Session expired, redirect to relogin
        window.location.href = '/login';
        return;
    }else{
        console.log('v fetchSections, response: ', response);
        return response.json();
    }
 
};

export const fetchStructure = async (token) => {
    const response = await fetch('/api/get-structur', {
        method: 'POST',
        headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
    });
    if (response.status === 401) {
        // Session expired, redirect to relogin
        window.location.href = '/login';
        return;
    } else{
        return response.json();
    }
    //console.log('v fetchStructure, response:', response);
    
};

export const getDataListFromBigQuery = async (tableEntita, page, token) => {
    const response = await fetch('/get-bigquery', {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            tableEntita: tableEntita,
            page: page,
            
        })
    });
    /*
    if (!response.ok) {
        return response.text().then(error => {
        throw new Error(error);
    });
    }
    */

    if (!response.ok) {
        const errorData = await response.json();
        if (errorData.redirect) {
            window.location.href = errorData.redirect;
        } else {
            const errorText = JSON.stringify(errorData);
            throw new Error(errorText);
        }
    }

    //console.log('v getDataListFromBigQuery, response:', response);
    return response.json();
    
};

export const getKalkulaceList = async (tableEntita, page, token, idOP) => {
    const response = await fetch('/get-kalkulaceList', {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            tableEntita: tableEntita,
            page: page,
            idOPB: idOP
        })
    });
    /*
    if (!response.ok) {
        return response.text().then(error => {
        throw new Error(error);
    });
    }
    */
    if (!response.ok) {
        const errorData = await response.json();
        if (errorData.redirect) {
            window.location.href = errorData.redirect;
        } else {
            const errorText = JSON.stringify(errorData);
            throw new Error(errorText);
        }
    }
    return response.json();
};

//getProcesMapaZdroj(entitaList, page, idOP, token);
export const getProcesMapaZdroj = async (entitaList, page, idOP, token) => {
    const response = await fetch('/get-procesMapaZdroj', {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            tableEntita: entitaList,
            page: page,
            idOPB: idOP
        })
    });
    /*
    if (!response.ok) {
        return response.text().then(error => {
        throw new Error(error);
    });
    }
    */

    if (!response.ok) {
        const errorData = await response.json();
        if (errorData.redirect) {
            window.location.href = errorData.redirect;
        } else {
            const errorText = JSON.stringify(errorData);
            throw new Error(errorText);
        }
    }
    return response.json();
};



export const getDataStructurEnt = async (tableEntita, page, token) => {
    //console.log('v getDataStructurEnt, start api /get-structur-ent', tableEntita, page);
    const response = await fetch('/get-structur-ent', {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            tableEntita: tableEntita,
            page: page
        })
    });
    /*
    if (!response.ok) {
        return response.text().then(error => {
        throw new Error(error);
    });
    }
    */
    if (!response.ok) {
        const errorData = await response.json();
        if (errorData.redirect) {
            window.location.href = errorData.redirect;
        } else {
            const errorText = JSON.stringify(errorData);
            throw new Error(errorText);
        }
    }
    return response.json();
};

export const paramsList = async (tableEntita, page, token) => {
    const response = await fetch('/get-paramsList', {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            act: 'paramsList',
            table: '_params',
            page: page,
            tableEntita: tableEntita
        })
    });
    /*
    if (!response.ok) {
        return response.text().then(error => {
        throw new Error(error);
    });
    }
    */
    if (!response.ok) {
        const errorData = await response.json();
        if (errorData.redirect) {
            window.location.href = errorData.redirect;
        } else {
            const errorText = JSON.stringify(errorData);
            throw new Error(errorText);
        }
    }
    return response.json();
};

export const paramsListAll = async (token) => {
    const response = await fetch('/get-paramsListAll', {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            act: 'paramsList',
            table: '_params',
            
        })
    });
    /*
    if (!response.ok) {
        return response.text().then(error => {
        throw new Error(error);
    });
    }
    */
    if (!response.ok) {
        const errorData = await response.json();
        if (errorData.redirect) {
            window.location.href = errorData.redirect;
        } else {
            const errorText = JSON.stringify(errorData);
            throw new Error(errorText);
        }
    }
    return response.json();
};


export const paramsLLimit = (paramsList, visuVar) => {
    return paramsList.filter(objekt => objekt[visuVar] === 'ano');
};

// detailView ----------------------------------------------------------

export const getData1 = async (tableEntita, idUn, page,token) => {
    //console.log('getData1', tableEntita, idUn, page);
    const response = await fetch('/detailView/get-data', {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            idUn: idUn,
            tableEntita: tableEntita,
            page: page
        })
    });
    /*
    if (!response.ok) {
        return response.text().then(error => {
        throw new Error(error);
    });
    }
    */
    if (!response.ok) {
        const errorData = await response.json();
        if (errorData.redirect) {
            window.location.href = errorData.redirect;
        } else {
            const errorText = JSON.stringify(errorData);
            throw new Error(errorText);
        }
    }
    return response.json();
};

//getData3(paramsLGlob, 'default', idUn, '', '', '', token)
export const getData3 = async (paramsL, coX, idUn, zdroj, inputName, dataGlob, token) => {
    //console.log('getData3', paramsL, coX, idUn, zdroj, inputName, dataGlob);
    if (paramsL.length > 0) {
        const response = await fetch('/get-dataDefault', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                x: paramsL,
                y: coX,
                idUn: idUn,
                zdroj: zdroj,
                inputName: inputName,
                dataGlob: dataGlob
            })
        });
        /*
        if (!response.ok) {
            return response.text().then(error => {
            throw new Error(error);
        });
        }
        */
        if (!response.ok) {
            const errorData = await response.json();
            if (errorData.redirect) {
                window.location.href = errorData.redirect;
            } else {
                const errorText = JSON.stringify(errorData);
                throw new Error(errorText);
            }
        }
    
        if (response.status === 401) {
            console.log('v fetchSections - chyba, response.status: ', response.status);
            // Session expired, redirect to relogin
            window.location.href = '/login';
            return;
        }else{
            console.log('v getData3, response:', response);
            return response.json();
        }
    }
};

//getData4(paramsLAllAll, 'lookup', '', 'OP', 'idOPS', '', token)
//getData4(paramsLGlob, 'pole', idUn, '', '', dataGlob, token);
export const getData4 = async (paramsL, coX, idUn, zdroj, inputName, dataGlob, token) => {
    //console.log('getData4', paramsL, coX, idUn, zdroj, inputName, dataGlob);
    if (paramsL.length > 0) {
        const response = await fetch('/get-dataDefault1', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                x: paramsL,
                y: coX,
                idUn: idUn,
                zdroj: zdroj,
                inputName: inputName,
                dataGlob: dataGlob
            })
        });
        /*
        if (!response.ok) {
            return response.text().then(error => {
            throw new Error(error);
        });
        }
        */
        if (!response.ok) {
            const errorData = await response.json();
            if (errorData.redirect) {
                window.location.href = errorData.redirect;
            } else {
                const errorText = JSON.stringify(errorData);
                throw new Error(errorText);
            }
        }
    
        if (response.status === 401) {
            console.log('v fetchSections - chyba, response.status: ', response.status);
            // Session expired, redirect to relogin
            window.location.href = '/login';
            return;
        }else{
            console.log('v getData4, response:', response);
            return response.json();
        }
    }
};

export const getKalkulacePolozka = async (token) => {
    //console.log('kontrola1, /api/get-kalkulacePolozka');
    const response = await fetch('/api/get-kalkulacePolozka', {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
    });
    /*
    if (!response.ok) {
        return response.text().then(error => {
        throw new Error(error);
    });
    }
    */
    if (!response.ok) {
        const errorData = await response.json();
        if (errorData.redirect) {
            window.location.href = errorData.redirect;
        } else {
            const errorText = JSON.stringify(errorData);
            throw new Error(errorText);
        }
    }
    //console.log('v /get-ciselnik, response:', response);
    return response.json();
};

export const getKontakt = async (token) => {
    
    const response = await fetch('/api/get-kontakt', {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
    });
    /*
    if (!response.ok) {
        console.log('v getKontakt - chyba, response: ', response);
        return response.text().then(error => {
        throw new Error(error);
    });
    }
    */
    if (!response.ok) {
        const errorData = await response.json();
        if (errorData.redirect) {
            window.location.href = errorData.redirect;
        } else {
            const errorText = JSON.stringify(errorData);
            throw new Error(errorText);
        }
    }
    
    return response.json();
};

export const getSablonaList = async (tableEntita, token) => {
    //console.log('kontrola1', tableEntita);
    const response = await fetch('/get-sablona', {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            'tableEntita': tableEntita
        })
    });
    /*
    if (!response.ok) {
        return response.text().then(error => {
        throw new Error(error);
    });
    }
    */
    if (!response.ok) {
        const errorData = await response.json();
        if (errorData.redirect) {
            window.location.href = errorData.redirect;
        } else {
            const errorText = JSON.stringify(errorData);
            throw new Error(errorText);
        }
    }
    return response.json();
};

export const authDetailSection = (paramsL) => {
    const detailSectionX = paramsL.map(obj => obj.detailSection);
    let detailSection = detailSectionX.filter((item, index) => { // vycisti duplicity
        return detailSectionX.indexOf(item) === index;
    });
    return detailSection;
};

export const paramsListSelected = (paramsLAll, detailSections) => {
   
        //const userParamsLOne = paramsLAll.filter(polozka => detailSections[0].includes(polozka.detailSection)); // vybere jen ty parametry, kde se shoduje prvni auth detailSection a detailSection
        const userParamsLOne = paramsLAll.filter(polozka => detailSections.includes(polozka.detailSection)); // vybere jen ty parametry, ktere jsou autorizovane
        return userParamsLOne;
       /*
    if (!aktualDetailSection) {
        //const userParamsLOne = paramsLAll.filter(polozka => detailSections[0].includes(polozka.detailSection)); // vybere jen ty parametry, kde se shoduje prvni auth detailSection a detailSection
        const userParamsLOne = paramsLAll.filter(polozka => detailSections.includes(polozka.detailSection)); // vybere jen ty parametry, ktere jsou autorizovane
        return userParamsLOne;
    } else {
        const userParamsLOne = paramsLAll.filter(polozka => aktualDetailSection.includes(polozka.detailSection)); // vybere jen ty parametry, kde se shoduje auth user a idDetailSection
        return userParamsLOne;

    }
*/
};

export const checkTaskStatus = async (taskId, puvod) => {
    const timerName = `totalTime-${puvod}-${taskId}`;  // Create a unique timer name for each taskId
    console.time(timerName);  // Start timer before the loop
    let loopCount = 0;  // Initialize loop counter
    while (true) { //tento cyklus bude ukončen pri vykonani příkazu return
        loopCount++;  // Increment loop counter
        console.log('0 - v checkTaskStatus, taskId: ', taskId, 'pruchod: ', loopCount);
        try {
            //zde ceka na odpoved od serveru
            let response = await fetch(`/task-status/${taskId}`);
            
            if (!response.ok) {
                console.log('------------------error - v checkTaskStatus, response', response);
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            
            let status = await response.json();
            console.log('1 - v checkTaskStatus, taskId: ', taskId, 'status: ', status);
           

            // v pripade ze od /task-status dostanu status Done, tak se dotazuji na vysledek
            if (status === "Done") {
                const resultResponse = await fetch(`/get-task-result/${taskId}`);

                /*
                if (!resultResponse.ok) {
                    throw new Error(`HTTP error! status: ${resultResponse.status}`);
                }
                */
                if (!resultResponse.ok) {
                    const errorData = await response.json();
                    if (errorData.redirect) {
                        window.location.href = errorData.redirect;
                    } else {
                        const errorText = JSON.stringify(errorData);
                        throw new Error(errorText);
                    }
                }

                const resultData = await resultResponse.json();
                //console.log('2 - v checkTaskStatus - /get-task-result, taskId: ', taskId, 'resultData: ', resultData);   
                console.timeEnd(timerName);  // End timer after the loop
                return resultData;
                
            } else if (status === "Error") {
                console.error('------------- error - v checkTaskStatus, taskId: ', taskId, 'status: ', status);
                window.location.href = '/login';
                //throw new Error('Task encountered an error');

            } else {
                await new Promise(resolve => setTimeout(resolve, 1500));
            }
        } catch (error) {
            console.error('------------- error na konci - v checkTaskStatus, taskId: ', taskId, 'error: ', error);
            console.error("Error checking task status:", error);
            //throw error;  // Propagate the error instead of breaking the loop
            return;  // This will stop the execution of the function, příkaz return ukončí funkci a tím i cyklus while
        }
    }
};


/*
export const checkTaskStatus = async (taskId) => {
    //console.log('v checkTaskStatus', taskId);
    while (true) {
        try {
            let response = await fetch(`/task-status/${taskId}`, { timeout: 100 }); 
            let data = await response.json();
            //console.log('v checkTaskStatus, data', taskId, data);

            if (data.status === "Done") {
                const resultResponse = await fetch(`/get-task-result/${taskId}`);
                //console.log('v checkTaskStatus, resultResponse');
                const resultData = await resultResponse.json();
                //console.log('v checkTaskStatus, resultData', resultData);
                //hidePopup();
                return resultData;
            } else if (data.status === "Error") {
                //hidePopup();
                //console.log("Nastala chyba pri cekani na ukonceni behu fce");
                break;
            } else {
                await new Promise(resolve => setTimeout(resolve, 100)); //500
            }
        } catch (error) {
            console.log("Error checking task status:", error);
            break;
        }
    }
}
*/
export const addItemPole = async (tableEntita, paramLink, idLink, token) => {
    //console.log('odesilam data k pridani polozky', tableEntita, paramLink, idLink);
    const response = await fetch('/detailView/addItemPole', {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            idLink: idLink,
            tableEntita: tableEntita,
            paramLink: paramLink
        })
    });
    /*
    if (!response.ok) {
        return response.text().then(error => {
        throw new Error(error);
    });
    }
    */
    if (!response.ok) {
        const errorData = await response.json();
        if (errorData.redirect) {
            window.location.href = errorData.redirect;
        } else {
            const errorText = JSON.stringify(errorData);
            throw new Error(errorText);
        }
    }
    //console.log('vraceno z /detailView/addItem', response.json);
    return response.json();
};

export const addItem = async (tableEntita, token) => {
    console.log('zakladam novou polozku', tableEntita);
    const response = await fetch('/detailView/addItem', {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            tableEntita: tableEntita,
        })
    });
    /*
    if (!response.ok) {
        response.text().then((text) => {
            throw new Error(text || 'Network response was not ok');
        });
    }
    */
    if (!response.ok) {
        const errorData = await response.json();
        if (errorData.redirect) {
            window.location.href = errorData.redirect;
        } else {
            const errorText = JSON.stringify(errorData);
            throw new Error(errorText);
        }
    }
    //console.log('vraceno z /detailView/addItem', response.json);
    return response.json();
};

export const addQuickUkolApi = async (entita, body, command, token) => {
    //console.log('00 - zakladam quick ukol', entita, body, command, token);
    const body1 = JSON.stringify({
        ...body,
        command: command,
        tableEntita: entita
    });
    console.log('01 - zakladam quick ukol', body1);

    const response = await fetch('/addQuickUkol', {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        body: body1,
        
    });
    /*
    if (!response.ok) {
        response.text().then((text) => {
            throw new Error(text || 'Network response was not ok');
        });
    }
    */
    if (!response.ok) {
        const errorData = await response.json();
        if (errorData.redirect) {
            window.location.href = errorData.redirect;
        } else {
            const errorText = JSON.stringify(errorData);
            throw new Error(errorText);
        }
    }
    //console.log('vraceno z /detailView/addItem', response.json);
    return response.json();
};

export const saveData = async (tableEntita, idUn, data, paramsL, inputFiles, selectedDates, command, token) => {
    console.log('v api SaveData', tableEntita, idUn, data, paramsL, inputFiles, selectedDates, command);
    const formData = new FormData();
    
    formData.append('command', command);
    formData.append('tableEntita', tableEntita);
    formData.append('idUn', idUn);
    //formData.append('data', JSON.stringify(data));
    formData.append('paramsL', JSON.stringify(paramsL));
    //formData.append('selectedDates', JSON.stringify(selectedDates));
    let updatedData = {};
    let updatedData1 = {};
    
    for (let param in data) {
        if (data[param] === true) {
            updatedData1[param] = 1;
        } else if (data[param] === false) {
            updatedData1[param] = 0;
        } else if (param == '2066' || param == '2071' || param == '2088') { // id parametru v _params pro pole1
            console.log('111111 - v api SaveData pole1Data', data[param]);
            updatedData1[param] = JSON.stringify(data[param]);
        } else {
            updatedData1[param] = data[param];
        }
    }
    console.log('0000 - v api SaveData', updatedData1);
    formData.append('data', JSON.stringify(updatedData1));

    for (let param in selectedDates) {
        if (selectedDates[param] !== null) {
            let date = moment(selectedDates[param]);
            let formattedDate = date.format('YYYY-MM-DD HH:mm:ss');
            updatedData[param] = formattedDate;
        } else {
            updatedData[param] = null;
        }
    }
    console.log('v api SaveData selectedDates', updatedData);
    formData.append('selectedDates', JSON.stringify(updatedData));
    
    let fileObjects = {};
    let fileNull = [];
    console.log('v api SaveData inputFiles', inputFiles);
    if (inputFiles) {
        const fileEntries = Object.entries(inputFiles).filter(([key, value]) => value instanceof File || value === null);
        fileObjects = Object.fromEntries(fileEntries);

    }

    for (let paramId in fileObjects) {
        let file = fileObjects[paramId];
        if (file) { // Check if file is not null
            console.log('v api SaveData file', file.name);
            formData.append(paramId, file, file.name);
            console.log('v api SaveData formData', formData.get(paramId));

        } else {
            fileNull.push(paramId); // Add the paramId of null items to the fileNull array
        }
    }

    formData.append('fileNull', JSON.stringify(fileNull)); // Add the fileNull array to formData


    /*
    for (let pair of formData.entries()) {
        console.log(pair[0]+ ', '+ pair[1]); 
    }
*/
    const response = await fetch('/detailView/save', {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`,
        },
        body: formData
    });

    /*
    if (!response.ok) {
        return response.text().then(error => {
        throw new Error(error);
    });
    }
    */
    if (!response.ok) {
        const errorData = await response.json();
        if (errorData.redirect) {
            window.location.href = errorData.redirect;
        } else {
            const errorText = JSON.stringify(errorData);
            throw new Error(errorText);
        }
    }

    return response.json();
};


export const fcePopupWindow = (hlaska) => {
    const popupWindow = document.createElement('div');
    popupWindow.setAttribute('id', 'customPopupWindow');
    popupWindow.setAttribute('class', 'customPopupWindow');
    popupWindow.innerHTML = `<p>${hlaska}</p>`;
    document.body.appendChild(popupWindow);

    showPopup();
};

const showPopup = () => {
    let element = document.getElementById('customPopupWindow');
    element.style.display = 'flex';
};

const hidePopup= () => {
    let element = document.getElementById('customPopupWindow');
    element.style.display = 'none';
}

