export default function FormatCZK(value) {
    //console.log('FormatCZK', value, typeof value);
    if (value == null || value === undefined || value === '') {
        return '';
    }

    // Convert the formatted string back to a number
    //let numberValue = parseFloat(value.replace(/\s*Kč$/, '').replace(/\s/g, '').replace(',', '.'));
 
    
    let formatter = new Intl.NumberFormat('cs-CZ', {
        style: 'currency',
        currency: 'CZK',
        minimumFractionDigits: 0,  // No decimal places
    });
    
    //console.log('v formatCurrency',  formatter.format(value));

    return formatter.format(value);
}