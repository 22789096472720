import React, { useState, useRef, useEffect, useContext } from 'react';
import { localeText } from '../atoms/localeText'; // Nastavení lokalizačních řetězců
import { AgGridReact } from 'ag-grid-react';
import { TextField } from '@mui/material';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
//import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import dayjs from 'dayjs';
import FormatCZK from '../atoms/FormatCZK';
import IconButton from '@mui/material/IconButton';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Checkbox from '@mui/material/Checkbox';
import { DetailDataContext } from '../store/detailDataContext'; // Import detailDataContext
import UpdateIcon from '@mui/icons-material/Update';
import theme from '../theme';
import RecyclingIcon from '@mui/icons-material/Recycling';
import { FormControlLabel, Switch } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { SideBarModule } from '@ag-grid-enterprise/side-bar';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

import { LicenseManager } from 'ag-grid-enterprise';
import { AgChart } from 'ag-charts-community';
LicenseManager.setLicenseKey("Using_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-059104}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Enado_s.r.o.}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{enadodata}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{enadodata}_need_to_be_licensed___{enadodata}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Charts_and_AG_Grid}_Enterprise_versions_released_before_{25_April_2025}____[v3]_[0102]_MTc0NTUzNTYwMDAwMA==eb62c95c3603f7547d6b9fb444e34c51");
AgChart.licenceKey = "Using_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-059104}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Enado_s.r.o.}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{enadodata}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{enadodata}_need_to_be_licensed___{enadodata}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Charts_and_AG_Grid}_Enterprise_versions_released_before_{25_April_2025}____[v3]_[0102]_MTc0NTUzNTYwMDAwMA==eb62c95c3603f7547d6b9fb444e34c51";

ModuleRegistry.registerModules([ExcelExportModule, RowGroupingModule]);
ModuleRegistry.registerModules([ SideBarModule ]);




const GlobalFilter = React.forwardRef(({ onFilterChanged }, ref) => {
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      onFilterChanged(event.target.value);
    }
  }
  return (
    <div style={{ display: 'flex', justifyContent: 'center', margin: '10px 0' }}>
      <TextField
        type="search"
        onChange={e => onFilterChanged(e.target.value)}
        onKeyDown={handleKeyDown}
        placeholder={'Hledej...'}
        autoFocus // Přidá automatické zaměření na pole pro vyhledávání
        variant="outlined"
        size="small"
        ref={ref}
        style={{ width: '100%', maxWidth: '300px' }}
        autoComplete="off" // Zabrání automatickému vyplňování
      />
    </div>
  );
});


const TableKalk = ({ idIdent, tableEntita, pageP, data, headers, columnAccessors, onRowClick, onRowClick1, onRowClick2, mjs, inputFormat, inputBool, handleInputChange }) => {
  const gridRef = useRef(null);
  

  const {
    pole1Data,
    setPole1Data,
    isTabChanged,
    setIsTabChanged,
    aktualDetailSection,
  } = useContext(DetailDataContext); // Získání hodnot z kontextu
  const [inputValue, setInputValue] = useState('');
  
  //const [finalColumnDefs, setFinalColumnDefs] = useState([]);
  
  //console.log('0000 - v TableKalk - headers', headers, 'data', data, 'columnAccessors', columnAccessors, 'mjs', mjs, 'inputFormat', inputFormat, aktualDetailSection);
 
  /*
  const onGridReady = params => {
    gridRef.current = params.api;
    params.api.sizeColumnsToFit();
    // Rozbalení všech skupin po načtení dat
    params.api.expandAll();
        
    
  };
*/
const onGridReady = (params) => {
  gridRef.current = params.api;
  params.api.sizeColumnsToFit();
  //params.columnApi.autoSizeAllColumns();
  
};

const gridOptions = {
  groupHeaderHeight: 30, // Nastaví výšku skupinových záhlaví
  headerHeight: 60, // Nastaví výšku standardních záhlaví
  suppressRowClickSelection: true,
  rowSelection: 'multiple',
  onCellKeyDown: (event) => {
    if (event.event.key === "Enter") {
      setInputValue(event.data.value); // Předpokládáme, že hodnota je uložena v `event.data.value`
    }
  }
};


  //console.log('v TableKalk - headers', headers);

  
  let columnDefs = [
    
    {
      headerCheckboxSelection: true, // Povolí checkbox pro vybírání všechny řádky v záhlaví
      headerCheckboxSelectionFilteredOnly: true, // Toto zajistí, že hlavičkový checkbox ovlivní pouze filtrované řádky
      checkboxSelection: true, // Povolí checkbox pro vybírání řádky
      headerName: "", // Můžete nechat prázdné nebo nastavit název
      width: 45, 
      lockPosition: true, // Udrží sloupec na začátku při horizontálním scrollu
      editable: false, // Tento sloupec nebude editovatelný
      filter: false, // Vypne filtr pro tento sloupec
      resizable: true, // Vypne možnost změny šířky sloupce
      suppressMovable: true, // Udrží sloupec vždy na začátku
      colId: 'checkboxColumn', // Přidáno
      pinned: 'left',
      wrapText: true, // Přidá zalomení
      headerClass: 'ag-header-cell-wrap-text',
    },
    
    ...headers.map((header, index) => ({
      headerName: header,
      field: columnAccessors[index],
      filter: 'agSetColumnFilter',
      minWidth: header.includes('název') ? 300
        : header.startsWith('id') ? 70
        : columnAccessors[index].includes('app') || columnAccessors[index].includes('def') ? 120
        : columnAccessors[index].includes('Mj') ? 70 
        : columnAccessors[index].includes('Marze') || columnAccessors[index].includes('Cena') ? 130
        : 90,
      wrapHeaderText: true, // Zapne zalamování textu
      autoHeaderHeight: true, // Nastaví automatickou výšku
      editable: columnAccessors[index].includes('Add') || columnAccessors[index].includes('Oblast') || columnAccessors[index].includes('IndivMarze') || columnAccessors[index].includes('app'),
      cellStyle: columnAccessors[index].includes('Add') || columnAccessors[index].includes('Oblast') || columnAccessors[index].includes('IndivMarze') || columnAccessors[index].includes('app') ? { backgroundColor: theme.palette.secondary.light } : { backgroundColor: null },
      headerClass: 'headerClass-tableKalk',
      cellClass: 'cellClass-tableKalk',
      autoHeight: false, 
      cellRenderer: (params) => {
        const value = params.value;
        const mj = mjs[index];
        const format = inputFormat[index];
        let displayValue = '';
        if (value) {
          if (mj === 'Kč') displayValue = FormatCZK(value);
          else if (format === 'date') displayValue = dayjs(value).format('DD.MM.YYYY');
          else if (format === 'dateTime') displayValue = dayjs(value).format('DD.MM.YYYY HH:mm');
          else if (format === 'percent') displayValue = `${value}%`; // přidání možnosti zobrazení procent
          else displayValue = value.toString();
        }
        return displayValue;
      },
      
      aggFunc: (mjs[index] === 'Kč' && columnAccessors[index] && !columnAccessors[index].includes('kpJc')) 
        ? 'sum' 
        : (columnAccessors[index].includes('kpMnCalc')) 
            ? 'sum' 
            : null,
      enableRowGroup: true,
      enablePivot: true,
      //pinned: index === 0 ? 'left' : null,
    })),
    
    
    
  ];

  
  //console.log('v TableKalk - columnDefs', columnDefs);

  const finalColumnDefs = groupColumnsWithSk(columnDefs);

  // Funkce pro dynamické seskupení sloupců s 'sk'
  // Funkce pro dynamické seskupení sloupců s 'sk'
  function groupColumnsWithSk(columnDefs) {
    //console.log('v TableKalk - groupColumnsWithSk - columnDefs', columnDefs);
        

    // Definice pole pro různé skupiny sloupců
    const fieldGroups = {
      Skupina: ['ks2'],
      Podskupina: ['ks1'],
      'Položky - hlavní': ['kpId', 'kpName', 'kpMj', 'kpJc', 'kpMnAdd', 'kpMnBase', 'kpMnCalc', 'kpCenaCalc', 'kpP0', 'kpP1'],
      'Položky - vedlejší': ['kpIndivMarze', 'kpMarzeProcento', 'kpMarzeKc', 'kpCenaCelkemSMarzi', 'kpCenaCelkemSMarziSDph'],
      'Položky - počítatelné': ['kpP31', 'kpP31Calc', 'kpP30'],
      'Přiřazení do entit': ['app'],
      'Položky - ostaní': ['kpP2', 'kpP3', 'kpP4', 'kpP5', 'kpP6', 'kpP7', 'kpP8', 'kpP9', 'kpP10', 'kpP11', 'kpP12', 'kpP13', 'kpP14', 'kpP15', 'kpP16', 'kpP17',
        'kpP18', 'kpP19', 'kpP20', 'kpP21', 'kpP22', 'kpP23', 'kpP24', 'kpP25', 'kpP26', 'kpP27', 'kpP28', 'kpP29', 'kpP32', 'kpObrTitl'],
      'Automatické přiřazení': ['def'],
    };
    let viditelnost = [];
    //definice viditelnych skupin sloupcu podle zobrazovane tableEntity
    if(tableEntita === 'Kalkulace' || tableEntita === 'KalkulacniSkupina1' || tableEntita === 'KalkulacniSkupina2') {
      viditelnost = ['Položky - hlavní', 'Položky - vedlejší', 'Položky - ostaní', 'Skupina', 'Podskupina']
    } else if(tableEntita === 'Objednavka' || tableEntita === 'Reklamace') {
      viditelnost = ['Položky - hlavní', 'Automatické přiřazení', 'Přiřazení do entit']
    } else if(tableEntita === 'RealizacniCinnost') {
      viditelnost = ['Položky - hlavní', 'Přiřazení do entit', 'Skupina', 'Podskupina']
    } else {
      viditelnost = ['Položky - hlavní', 'Přiřazení do entit', 'Skupina', 'Podskupina']
    }

    // Získání sloupce s checkboxy
    const checkboxColumn = columnDefs?.find(colDef => colDef.checkboxSelection);

    // Filtrace sloupců, které nejsou součástí žádné skupiny a nejsou sloupec s checkboxy
    let otherColumns = columnDefs?.filter(colDef => {
      return colDef && colDef.field && !Object.values(fieldGroups).flat().some(str => colDef.field.includes(str)) && !colDef.checkboxSelection;
    });

    // Příprava výsledného pole columnDefs
    const resultColumnDefs = [...otherColumns]; // Začínáme s ostatními sloupci

    // Získání sloupců pro každou skupinu a přidání do příslušné skupiny
    Object.keys(fieldGroups).forEach(group => {
      let groupColumns;
      if (group === 'Položky - hlavní' || group === 'Položky - ostaní' || group === 'Položky - vedlejší' || group === 'Položky - počítatelné') {
        // Pro 'Položky hlavní' a 'Položky ostaní' použijeme přesnou shodu
        groupColumns = columnDefs?.filter(colDef => colDef.field && fieldGroups[group].includes(colDef.field));
      } else {
        // Pro ostatní skupiny použijeme metodu includes
        groupColumns = columnDefs?.filter(colDef => colDef.field && fieldGroups[group].some(str => colDef.field.includes(str)));
      }
      if (groupColumns && groupColumns.length > 0) {
        const hideGroup = !viditelnost.includes(group);
        groupColumns.forEach(column => {
          column.hide = hideGroup;
        });
        resultColumnDefs.push({ headerName: group, children: groupColumns });
          }
    });

    
    // Přidání sloupce s checkboxy na začátek pole
    if (checkboxColumn) {
        resultColumnDefs.unshift(checkboxColumn);
    }

    return resultColumnDefs;
  }
  

  

/*
  useEffect(() => {
    const updatedColumnDefs = groupColumnsWithSk(columnDefs);
    setFinalColumnDefs(updatedColumnDefs);
  }, [aktualDetailSection]); // Přidejte další závislosti podle potřeby
*/

  // Použití funkce pro seskupení sloupců s 'sk' na vaše columnDefs
  //columnDefs = groupColumnsWithSk(columnDefs);

  
  // Konfigurace pro autoGroupColumnDef pro přizpůsobení vzhledu seskupeného sloupce
  const autoGroupColumnDef = {
    headerName: 'Seskupení',
    minWidth: 250,
    cellRendererParams: {
      checkbox: true,
    },
    cellStyle: { fontWeight: 'bold' } // Tučný text pro skupinové řádky
  };

  /*
  // Upravená část: předání dat řádku do funkce onRowClick
  const handleRowClicked = event => {
    const { data } = event;
    if (onRowClick) {
      // Předpokládáme, že onRowClick očekává parametry jako (tableEntita, entita, pageP, id)
      // Upravte následující volání funkce podle vašich potřeb
      console.log('v TableKalk - handleRowClicked - data', data);
      onRowClick(tableEntita, data.id);
      
    }
  };
  */

  const exportToExcel = () => {
    gridRef.current.exportDataAsExcel();
  };

  //pro Prepocitej
  const sberDatTabulky = () => {
    
    let allData = [];
    console.log("0 - Klik Spocitej - vsechny radky tabilky :", allData);
    
    gridRef.current.forEachNode(node => {
      if (node.data) {
        //console.log('1 - v tableKalk1 - data kazdeho radku', node.data); // vypíše data pro každý řádek
        const temp = {...node.data};
        //console.log('2 - Klik Spocitej - temp', temp);
        //console.log('3 - Klik Spocitej - node.data', node.data);
        
        // Příklad doplnění prázdných hodnot pro sloupce, které chybí v 'node.data'
        // Předpokládejme, že 'columnAccessors' je pole se všemi názvy sloupců
        columnAccessors.forEach(accessor => {
            if (temp[accessor] === undefined) {
                temp[accessor] = null; // Nastaví prázdný řetězec nebo jinou výchozí hodnotu pro chybějící sloupce
            }
        });

        allData.push(temp);
      }
      
    });
    
    //console.log("Klik Spocitej - vsechny radky tabilky :", allData);
    
    setIsTabChanged(true);
    onRowClick(tableEntita, allData);
    
    
  };

  

  // pro Refreshovat data
  const sberDatTabulky1 = () => {
    
    let allData = [];
    
    
    gridRef.current.forEachNode(node => {
      if (node.data) {
        
        const temp = {...node.data};
       
        columnAccessors.forEach(accessor => {
            if (temp[accessor] === undefined) {
                temp[accessor] = null; // Nastaví prázdný řetězec nebo jinou výchozí hodnotu pro chybějící sloupce
            }
        });

        allData.push(temp);
      }
      
    });
        
    setIsTabChanged(true);
    onRowClick1(tableEntita, allData);
    
    
  };

  //pro Vymaz Radky
  const sberDatTabulky2 = () => {
    console.log("0 - Klik Vymaz Radky - mazat se budou tyto radky tabulky");

    let allData = [];
    const selectedNodes = gridRef.current.getSelectedNodes();
    //console.log("1 - Klik Vymaz Radky - selectedNodes", selectedNodes);
    const selectedData = selectedNodes.map(node => node.data);
    console.log("1 - Klik Vymaz Radky - selectedData", selectedData); 

    gridRef.current.forEachNode(node => {
      if (node.data && !selectedData.includes(node.data)) {
        const temp = {...node.data};

        columnAccessors.forEach(accessor => {
            if (temp[accessor] === undefined) {
                temp[accessor] = null; // Nastaví prázdný řetězec nebo jinou výchozí hodnotu pro chybějící sloupce
            }
        });

        allData.push(temp);
      }
    });

    setIsTabChanged(true);
    onRowClick2(tableEntita, allData);
};

    
  const onCellValueChanged = (event) => {
    console.log('Data po změně:', event.data);
    // Získání hodnoty a pole (field) ze změněného řádku
    const { value, colDef: { field }, node } = event;
    
    // Získání vybraných řádků
    const selectedNodes = gridRef.current.getSelectedNodes();

    // Aplikace hodnoty na všechny vybrané řádky
    selectedNodes.forEach(selectedNode => {
      if (node.data && !node.data.checkboxColumn) {
            const data = selectedNode.data;
            data[field] = value; // Nastavení nové hodnoty
            selectedNode.setData(data);
        }
    });

    console.log('Data po změně:', event.data);
    setIsTabChanged(true);
};

let iconTabList = [];
let appKalkulace = false;
    if(tableEntita === 'Kalkulace') {
        iconTabList = ['spocitej', 'refresh', 'globalFilter', 'vymazRadky', 'exportToExcel'];
    } else if(tableEntita === 'KalkulacniSkupina2') {
        iconTabList = ['spocitej', 'globalFilter', 'vymazRadky', 'exportToExcel'];
    } else if(tableEntita === 'KalkulacniSkupina1') {
        iconTabList = ['spocitej', 'globalFilter', 'vymazRadky', 'exportToExcel'];
    } else if( aktualDetailSection === 'Kalkulace') {
      iconTabList = ['appFilter', 'globalFilter', 'exportToExcel'];
      appKalkulace = true;
    } else {
        iconTabList = ['globalFilter', 'exportToExcel'];
    }


  return (
    <div className="flex-container" style={{ width: '100%' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
              {iconTabList.includes('spocitej') && 
                  <IconButton
                      title="Přepočítej"
                      onClick={sberDatTabulky}
                      sx={{ 
                          margin: '15px',
                          marginLeft: '12px',
                          backgroundColor: theme.palette.secondary.dark,
                          fontWeight: 700,
                          '&:hover': {
                              backgroundColor: theme.palette.secondary.main,
                          },
                      }}
                  >
                      <UpdateIcon
                          sx={{ 
                              color: 'white', 
                              fontSize: '22px'
                          }}
                      />
                  </IconButton>
              }
              {iconTabList.includes('refresh') && 
                  <IconButton
                      title="Refreshovat data"
                      onClick={sberDatTabulky1}
                      sx={{ 
                          margin: '15px',
                          marginLeft: '12px',
                          backgroundColor: theme.palette.secondary.dark,
                          fontWeight: 700,
                          '&:hover': {
                              backgroundColor: theme.palette.secondary.main,
                          },
                      }}
                  >
                      <RecyclingIcon
                          sx={{ 
                              color: 'white', 
                              fontSize: '22px'
                          }}
                      />
                  </IconButton>
              }
              {iconTabList.includes('appFilter') && 
                  <React.Fragment>
                      <FormControlLabel
                          control={
                              <Switch
                                  checked={inputBool}
                                  onChange={handleInputChange}
                                  color="secondary" // Toto způsobí, že Switch použije barvu sekundárního tématu
                              />
                          }
                          label='Jen přiřazené položky'
                      />
                      
                  </React.Fragment>
              }
              
              {iconTabList.includes('globalFilter') && 
                  <React.Fragment>
                      
                      <GlobalFilter onFilterChanged={value => gridRef.current && gridRef.current.setGridOption('quickFilterText', value)} />
                  </React.Fragment>
              }
              {iconTabList.includes('vymazRadky') && 
                  <IconButton
                      title="Vymaž řádky"
                      onClick={sberDatTabulky2}
                      sx={{ 
                          margin: '15px',
                          marginLeft: '12px',
                          backgroundColor: theme.palette.secondary.dark,
                          fontWeight: 700,
                          '&:hover': {
                              backgroundColor: theme.palette.secondary.main,
                          },
                      }}
                  >
                      <DeleteIcon
                          sx={{ 
                              color: 'white', 
                              fontSize: '22px'
                          }}
                      />
                  </IconButton>
              }
              {iconTabList.includes('exportToExcel') && 
                  <IconButton
                      title="Export do Excelu"
                      edge="end"
                      onClick={exportToExcel}
                      sx={{ 
                          mr: 2, marginRight: '0px',
                          marginLeft: '20px',
                          backgroundColor: theme.palette.secondary.dark,
                          fontWeight: 700,
                          '&:hover': {
                              backgroundColor: theme.palette.secondary.main,
                          },
                      }}
                  >
                      <ExitToAppIcon
                          sx={{ 
                              color: 'white', 
                              fontSize: '22px'
                          }} 
                      />
                  </IconButton>
              }
              
          </div>
          
              
          <div className="ag-theme-alpine" style={{ height: '900px', width: '100%' }}>
          {data ? (
            <AgGridReact
              columnDefs={finalColumnDefs}
              autoGroupColumnDef={autoGroupColumnDef}
              rowData={data}
              onGridReady={onGridReady}
              gridOptions={gridOptions} // Připojte definované možnosti k tabulce
              animateRows={true}
              onCellValueChanged={onCellValueChanged} // Přidáno pro zpracování změn
              groupIncludeFooter={true} // Zobrazí agregované hodnoty v zápatí skupiny
              enableRangeSelection={true} // Povolí výběr rozsahu pro výpočty agregací
              suppressRowClickSelection={true} // Toto zamezí označení celého řádku při kliknutí
              //domLayout='autoHeight' // Automatická výška podle obsahu
              rowSelection="multiple" // Povolení výběru více řádků
              //suppressRowClickSelection={true} // Zabrání výběru řádku při kliknutí na řádek
              rowMultiSelectWithClick={true} // umožní vybrat více řádků kliknutím s klávesou Ctrl
              groupIncludeTotalFooter={true}                                                        
              localeText={localeText} // Nastavení lokalizačních řetězců
              //domLayout="autoHeight" // Přidání tohoto atributu
              //autoHeaderHeight={true} // Automatická výška záhlaví
              sideBar={{
                toolPanels: [
                  {
                    id: 'columns',
                    labelDefault: 'Sloupce',
                    labelKey: 'columns',
                    iconKey: 'columns',
                    toolPanel: 'agColumnsToolPanel',
                  },
                  {
                    id: 'filters',
                    labelDefault: 'Filtry',
                    labelKey: 'filters',
                    iconKey: 'filter',
                    toolPanel: 'agFiltersToolPanel',
                  },
                  
                ],
                defaultToolPanel: 'columns'
              }}
            />
            ) : (
              appKalkulace 
                ? <div>Nejsou přiřazena žádná data, zrušte filtr a přiřaďte data</div> 
                : appKalkulace 
                  ? <div>Nejsou žádná data, začněte přidáním dat</div> 
                  : <div>Počkejte na zobrazení dat</div>
            )}
          </div>
     
    </div>
  );
};

export default TableKalk;
