// localeText.js
export const localeText = {
    // Column Tool Panel
    columns: 'Sloupce',
    filters: 'Filtry',
    rowGroupColumns: 'Sloupce skupin řádků',
    rowGroupColumnsEmptyMessage: 'Přetáhněte sloupec pro seskupení řádků',
    valueColumns: 'Hodnotové sloupce',
    pivotMode: 'Režim pivot',
    groups: 'Skupiny',
    values: 'Hodnoty',
    pivots: 'Pivoty',
    valueColumnsEmptyMessage: 'Přetáhněte sem sloupce pro agregaci',
    pivotColumnsEmptyMessage: 'Přetáhněte sem sloupce pro pivot',
    toolPanelButton: 'Panel nástrojů',
  
    // Other
    noRowsToShow: 'Žádné řádky k zobrazení',
    enabled: 'Povoleno',
  
    // Menus
    pinColumn: 'Připnout sloupec',
    valueAggregation: 'Agregace hodnot',
    autosizeThiscolumn: 'Automaticky upravit velikost tohoto sloupce',
    autosizeAllColumns: 'Automaticky upravit velikost všech sloupců',
    groupBy: 'Seskupit podle',
    ungroupBy: 'Zrušit seskupení podle',
    resetColumns: 'Resetovat sloupce',
    expandAll: 'Rozbalit vše',
    collapseAll: 'Sbalit vše',
    toolPanel: 'Panel nástrojů',
    export: 'Export',
    csvExport: 'CSV Export',
    excelExport: 'Excel Export',
  
    // Enterprise Menu
    pinLeft: 'Připnout vlevo',
    pinRight: 'Připnout vpravo',
    noPin: 'Nepřipnout',
    sum: 'Součet',
    min: 'Min',
    max: 'Max',
    none: 'Žádný',
    count: 'Počet',
    average: 'Průměr',
    // add other translations...
  };