//RestComponent.jsx
import React, { useState, useEffect, useContext, useCallback } from 'react';
import { DetailDataContext } from '../store/detailDataContext';
import RestForm from '../atoms/RestForm';
import FormatCZK from '../atoms/FormatCZK';
import unformatCZK from '../atoms/UnFormatCZK';
import dayjs from 'dayjs';
import { DataContext } from '../store/baseData';
import { set } from 'date-fns';
//import { isDefaultAxisSwapNeeded } from 'ag-grid-enterprise/dist/lib/ag-charts-community/chart/factory/seriesTypes';

//jen koment

const RestComponent = ({ param, placementRef }) => {
    
    const {
        inputRests,
        setInputRests,
        inputCalc,
        setInputCalc,
        detailDataNutne,
        isChanged,
        setIsChanged,
        tableEntita,
        pole1Data,
        setPole1Data,
        aktualDetailSection,
        setAktualDetailSection,
        detailDataRest,
        inputDefaults,
        setInputDefaults,
        inputLookups,
        setInputLookups,
    } = useContext(DetailDataContext); // Získání hodnot z kontextu

    const { paramsLAllAll } = useContext(DataContext);

    //console.log('paramsLAllAll', paramsLAllAll)

   

    const [edited, setEdited] = useState(false);
    const [editedDef, setEditedDef] = useState(false);
    const [inputValue, setInputValue] = useState(''); // Neformátovaná hodnota
    const [formattedInputValue, setFormattedInputValue] = useState(''); // Formátovaná hodnota pro zobrazení
    const [prvniNacteni, setPrvniNacteni] = useState(false);
        
    
    //console.log('inputRests:', inputRests);
    //console.log('param:', param);
    //console.log('param.id:', param.id);
    
    //const inputRest = inputRests?.[param.id] === undefined ? '' : inputRests?.[param.id];
    const inputRest = inputRests?.[param.id] || '';
    const inputCalcRes = inputCalc?.[param.id] || '';
    //console.log('000 - v RestComponent - inputCalcRes', inputCalcRes)
    //console.log('000 - v RestComponent - inputRests?.[param.id]', inputRests?.[param.id], 'param.id', param.id, 'pro parametr: ', param.vizu, 'paramsL.input', param.input)
    
    const rowData = detailDataNutne.dataGlob[0]; // Získání dat z detailDataContext
    const paramsL = detailDataNutne.paramsLGlob; // Získání dat z detailDataContext
    const headers = detailDataNutne.headers; // Získání dat z detailDataContext
    const header = param.parametr;
    const detailSectionsGlob = detailDataNutne?.detailSectionsGlob; // Získání dat z detailDataContext
    const dataDefaultGlob = detailDataRest?.dataDefaultGlob; // Získání dat z detailDataContext
    //console.log('v RestComponent - dataDefaultGlob', dataDefaultGlob);
    //console.log('000 - v RestComponent - rowData', param.id, param.vizu, rowData);

    const isReadOnly = param.input === 'auto';
   //console.log('v RestComponent - isReadOnly', isReadOnly, param.vizu);

     // uvodni nacteni pro idOPB
  useEffect(() => {
    if (detailDataRest) {
      //console.log('00 - v RestComponent - useEffect', 'edited', edited, 'editedDef', editedDef, 'param.parametr', param.parametr, 'inputRest', inputRest);
      if (!param.calc && !edited && !editedDef && param && param.parametr && param.parametr === 'idOPB' && param.input === 'auto') {
         
        console.log('----------------------------------------------------------------------------- v RestComponent - param.parametr', param.parametr, 'param.input ', param.input)
        
          let result = null;

          // Iterate over keys of the first object in dataGlob
          Object.keys(rowData).forEach(key => {
            //if (key.startsWith('id') && key !== 'id' && key !== 'idOPB' && key !== 'idOPBUser' && key !== 'idVizuProtokol') {
            if (key === 'idPredani' || key === 'idServisZasah') {
              //console.log('--------------------------------------key', key);
              const category = key.replace('id', '');
              const paramItem = paramsL.find(param => param.parametr === key);
              let idValue
              if (paramItem) {
                  const keyID = paramItem.id;
                  idValue = inputLookups && inputLookups[keyID];
                  // ...
              }
              /*
              const keyID = paramsL.find(param => param.parametr === key).id;
              //const idValue = rowData[key];
              const idValue = inputLookups && inputLookups[keyID];
              */

              if (dataDefaultGlob.default[category] && idValue) {
                //console.log('-----category', category, 'idValue', idValue, 'dataDefaultGlob.default[category]', dataDefaultGlob.default[category]);
                const foundItem = dataDefaultGlob.default[category].find(item => item.id === idValue);
                if (foundItem && foundItem.idOPB && foundItem.idOPB !== 0) {
                  result = foundItem.idOPB;
                }
              }
            }
            if (inputLookups && key == 'idOPBUser' && inputLookups[4330] && !result) {
              //console.log('-----------------------shoda s -------key', key, inputLookups[4330]);
              result = inputLookups[4330];
            }
          });
          //console.log('--------------------------------------result', result);
          const initValue = result;
          setInputRests(prevValues => ({
            ...prevValues,
            [param.id]: initValue,
          }));
       

        // inputRests[param.id]
      }                  
    }
  }, [detailDataRest, param, rowData, editedDef, inputLookups]);

    // uvodni nacteni kdyz inputRest je null, neni aktivni pro parametry s vyplnenym param.calc
  useEffect(() => {
    if (detailDataNutne) {
      //console.log('00 - v RestComponent - useEffect', 'edited', edited, 'editedDef', editedDef, 'param.parametr', param.parametr, 'inputRest', inputRest);
      if (!param.calc && !edited && !editedDef && param && param.parametr && rowData && rowData[param.parametr] && !inputRest) {
              
          const initValue = rowData[param.parametr];
        
          setInputRests(prevValues => ({
              ...prevValues,
              [param.id]: initValue,
          }));
          setInputValue(initValue); // Nastavuje neformátovanou hodnotu

          if (param.input === 'pole1') {
            //console.log('00 - v RestComponent - useEffect', 'param.parametr', param.parametr, 'initValue', JSON.parse(initValue));
          }
     
        
        
        
        //console.log('01 - v RestComponent - initValue', initValue, 'pro parametr: ', param.vizu, 'paramsL.input', param.input, isChanged)
  
        // Zkontrolujte, zda má parametr 'Kč' a pokud ano, nastavte formátovanou hodnotu
        if (param.mj === 'Kč') {
          setFormattedInputValue(FormatCZK(Number(initValue)));
          //console.log('02 - v RestComponent - initValue', initValue, 'pro parametr: ', param.vizu, 'paramsL.input', param.input, isChanged)
        } else {
          setFormattedInputValue(''); // Resetujte formátovanou hodnotu, pokud měna není 'Kč'
          //console.log('03 - v RestComponent - initValue', initValue, 'pro parametr: ', param.vizu, 'paramsL.input', param.input, isChanged)
        }
        
      }                  
    }
  }, [detailDataNutne, param, rowData, editedDef]);

  //prvni hodnota parametru s vyplnenym calc, nactena z db ihned po nacteni stranky
  useEffect(() => {
    if (detailDataNutne) {
      //console.log('x0x0x0x - po nacteni - pred if - inputCalcRes', inputCalcRes)
      if (param.calc && param && param.parametr && rowData && rowData[param.parametr] && !inputCalcRes) {
              
          const initValue = rowData[param.parametr];
          //console.log('x0x0x0 - RestComponent - parametr calc ihned po nacteni - initValue', initValue)
        
          setInputCalc(prevValues => ({
              ...prevValues,
              [param.id]: initValue,
          }));
          setInputValue(initValue); // Nastavuje neformátovanou hodnotu
        }                  
      }
  }, [detailDataNutne, param, rowData]);
  
  // Výpočet hodnoty pro parametr s param.calc, jen ne prvni nacteni stranky
  
  useEffect(() => {
    //console.log('x1ax1ax1a - v RestComponent - param.calc', param.calc, 'inputRests', inputRests, 'inputCalcRes', inputCalcRes, 'edited', edited, 'isChanged', isChanged)
    if (param.calc && paramsLAllAll && paramsLAllAll.length > 0 && inputRests && (inputCalcRes || isChanged)) {
      // Rozdělte vzorec na jednotlivé části
      let parts = param.calc.split(/\s+/);
      //console.log('x1x1x1 - v RestComponent param.calc - pred rozclenenim - parts', parts);
  
      //console.log('paramsLAllAll', paramsLAllAll)
      for (let i = 0; i < parts.length; i++) {
        const part = parts[i];
        // Pokud part není operátor, pokusíme se najít odpovídající paramItem
        if (!['+', '-', '*', '/'].includes(part)) {
          const paramItem = paramsLAllAll.find(item => item.parametr === part);
          //console.log('paramItem', paramItem)
          if (paramItem) {
            const id = paramItem.id;
            parts[i] = isNaN(inputRests[id]) ? 0 : inputRests[id];
            //console.log('x1x1x1 - v RestComponent param.calc - pred vypoctem - parts', parts);
          }
        }
      }
      //console.log('v RestComponent param.calc - pred vypoctem - parts', parts);
      //console.log('v RestComponent param.calc - pred vypoctem - jcSklad', inputRests[2006]);
      //console.log('v RestComponent param.calc - pred vypoctem - inputRests', inputRests);
  
      // Spojujte části zpět do jednoho řetězce a vypočítejte výsledek
      const calcValue = eval(parts.join(' '));
      //console.log('calcValue', calcValue)
  
      setInputCalc(prevValues => ({
        ...prevValues,
        [param.id]: calcValue,
      }));
      
    }
  }, [inputRests, paramsLAllAll]);

  // ------------ zakladni nacteni, pouzito jen v Kalkulace, kde chci nacist data, i kdyz user nevstoupi do jinych detailSectin ------------
   
    useEffect(() => {
      if(!prvniNacteni && detailDataNutne && dataDefaultGlob && paramsLAllAll && paramsL && !pole1Data[2088] && paramsL?.some(param => param.input === 'pole1' && tableEntita === 'Kalkulace')) {

          //--------nacteny obsah do pole1Data
          let initValue = rowData?.pole1Data;
         
          console.log('A1 - v RestComponent - uvodni nacteni dat KalkulaceTable - initValue', initValue);
          if (initValue !== null) {
              initValue = JSON.parse(initValue);
              //console.log('001 - v KalkulaceTable - initValue', initValue); 
              setPole1Data({[2088]: initValue});
              
          }

          //--------nacteni defaultnich hodnot-
          if(dataDefaultGlob){
            paramsL.filter(param => param.input === 'default').forEach(param => {
              const ridiciParametr = param.ridiciParametr;
              const zdrojPar = param.zdrojParametr;
              const ridiciParametrValue = rowData[ridiciParametr];
              const zdrojParRidicihoPar = param.zdrojParRidicihoPar;
              //console.log('zdrojParRidicihoPar:', zdrojParRidicihoPar, 'ridiciParametrValue:', ridiciParametrValue);
              const dataDefaultGlobBase = dataDefaultGlob?.default[param.zdroj]
              //console.log('1 - v DefaultComponent - useEffect - dataDefaultGlobBase', dataDefaultGlobBase );
              const dataDefaultGlobOne = dataDefaultGlobBase?.filter(item => item[zdrojParRidicihoPar] === ridiciParametrValue)
              //console.log('A2 - v RestComponent - uvodni nacteni dat KalkulaceTable - dataDefaultGlobOne[0]', dataDefaultGlobOne[0]);
              //console.log('A2 - v RestComponent - uvodni nacteni dat KalkulaceTable - param', param);
              setInputDefaults(prevValues => ({
                ...prevValues,
                [param.id]: (dataDefaultGlobOne?.length > 0 && zdrojPar) ? dataDefaultGlobOne[0][zdrojPar] : '',
              }));
            });
          }

          
          //nacteni rest a lookup hodnot
          //console.log('A3 - v RestComponent - uvodni nacteni dat KalkulaceTable - rowData', rowData);
          if(rowData){
            paramsL.filter(param => param.input !== 'default' && param.input !== 'pole1Data' && param.input !== 'file' && param.input !== 'pole' && param.input !== 'bool').forEach(param => {
              const initValue = rowData[param.parametr];
              //console.log('A4 - v RestComponent - uvodni nacteni dat KalkulaceTable - initValue', initValue, 'param.parametr', param.parametr);
              if(param.input === 'lookup' && initValue){
                setInputLookups(prevValues => ({
                  ...prevValues,
                  [param.id]: initValue,
                }));
              }else{
              setInputRests(prevValues => ({
                  ...prevValues,
                  [param.id]: initValue ? initValue : '',
              }));
            }
            });
            
          }
          
          setPrvniNacteni(true); // zajisti, ze se tento blok provede jen jednou
          

         
      }// zda některý z objektů v poli paramsL má vlastnost input rovnou 'pole1'
       
  }, [detailDataNutne, dataDefaultGlob]); // Prázdné závislosti znamenají, že tento efekt se spustí pouze jednou po prvním vykreslení
      
  const handleInputChange = (event) => {
    let rawValue = event.target.value;
    console.log('rawValue na začátku:', rawValue); // Přidáno pro ladění
  
    if (event.key === "Enter" && param.input !== 'editLong') {
      event.preventDefault();
    }
  
    if (typeof rawValue === 'string' && rawValue.includes('Kč') && param.input !== 'editLong') {
      rawValue = unformatCZK(rawValue);
    }
  
    console.log('rawValue před konverzí na číslo:', rawValue); // Přidáno pro ladění

    // Zkontrolujte, zda je rawValue stále řetězec, než zavoláte replace
    if (param.mj && param.input !== 'editLong') {
      if (typeof rawValue === 'string' && !isNaN(Number(rawValue.replace(',', '.'))) && !rawValue.endsWith('.') && !rawValue.endsWith(',')) {
        rawValue = Number(rawValue.replace(',', '.'));
      }
    }
    
    console.log('rawValue po konverzi na číslo:', rawValue); // Přidáno pro ladění
  
    setInputValue(rawValue);
    setInputRests(prevValues => ({
      ...prevValues,
      [param.id]: rawValue,
    }));
    setEdited(true);
    setEditedDef(false);
    setIsChanged(true);
  };
  
  
    
  const handleInputBlur = () => {
    const rawValue = inputRests[param.id];
    console.log('v RestComponent - handleInputBlur - rawValue', rawValue, 'param.mj', param.mj, 'isNaN(Number(rawValue))', isNaN(Number(rawValue)));
    if (!rawValue || isNaN(Number(rawValue))) {
      setFormattedInputValue(``);
    } else if (param.mj === 'Kč') {
      const formattedValue = FormatCZK(Number(rawValue));
      setFormattedInputValue(formattedValue);
    } else if (param.mj === '%') {
      setFormattedInputValue(`${rawValue} %`);
    }else if (param.mj === 'kW') {
      console.log('v RestComponent - handleInputBlur - kW', rawValue);
      setFormattedInputValue(`${rawValue} kW`);
    }else if (param.mj === 'kWp') {
      setFormattedInputValue(`${rawValue} kWp`);
    }else if (param.mj === 'kWh') {
      setFormattedInputValue(`${rawValue} kWh`);
    }else if (param.mj === 'ks') {
      setFormattedInputValue(`${rawValue} ks`);
    }else if (param.mj === 'h') {
      setFormattedInputValue(`${rawValue} h`);
    }
    setEditedDef(true);
    setEdited(false)
  };

  const onKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Zabrání defaultnímu chování Enter
      setInputValue(event.target.value); // Nastaví hodnotu vstupu
    }
  };
  
    //----------------------------
    
    //console.log('v RestComponent - editedDef', editedDef, 'inputRest', inputRest);
    return (
      <RestForm
      
      value={
        editedDef && (param.mj === 'Kč' || param.mj === '%' || param.mj === 'kW' || param.mj === 'kWp' || param.mj === 'kWh' || param.mj === 'ks' || param.mj === 'h')
          ? formattedInputValue
          : param.mj === 'Kč' && !edited
          ? FormatCZK(Number(param.calc ? inputCalcRes : inputRest))
          : param.mj === '%' && !edited
            ? `${param.calc ? inputCalcRes : inputRest} %`
          : param.mj === 'kW' && !edited
            ? `${param.calc ? inputCalcRes : inputRest} kW`
          : param.mj === 'kWp' && !edited
            ? `${param.calc ? inputCalcRes : inputRest} kWp`
          : param.mj === 'kWh' && !edited
            ? `${param.calc ? inputCalcRes : inputRest} kWh`
          : param.mj === 'ks' && !edited
            ? `${param.calc ? inputCalcRes : inputRest} ks`
          : param.mj === 'h' && !edited
            ? `${param.calc ? inputCalcRes : inputRest} h`
          : param.calc ? inputCalcRes : inputRest
      }
      onKeyDown={handleInputChange}
      onChange={handleInputChange}
      onBlur={handleInputBlur}
        required={false}
        id={`input_${header}`}
        label={param.vizu}
        variant="outlined"
        //unit={param.mj ? param.mj : null}
        helperText={null}
        readOnly={isReadOnly || paramsL.input === 'auto'}
        disableUnderline={true || paramsL.input === 'auto'}
        paramInput={param.input}
        style={{
          display: 'flex',
          fontSize: '16px',
          margin: '5px 0 0px 0',
          whiteSpace: 'pre-wrap',
          padding: '0 0px', // posunuti textu od okraju
          overflowWrap: 'break-word',
          width: '300px',
          backgroundColor: isReadOnly || paramsL.input === 'auto' ? 'transparent' : undefined,
          padding: param.input === 'editLong' || isReadOnly ? '10px 10px 0px 10px' : '0px', // Podmínka pro 'editLong' nebo 'isReadOnly'
          alignItems: param.input === 'editLong'|| param.input === 'pole1' ? 'flex-start' : 'center', // Aplikováno pouze jednou
          height: param.input === 'editLong' || param.input === 'pole1' ? '250px' : '40px',
        }}
        multiline={param.input === 'editLong' || param.input === 'pole1'}
        rows={param.input === 'editLong' || param.input === 'pole1' ? 10 : 1}
        
      />
    );
};

export default RestComponent;

/*

        if(param.parametr === 'pole1Kp'){
          let pole1KpX = [{kpId: 3, kpMn: 2}, {kpId: 4, kpMn: 2, xxx: '01 Zaklad'} ];
          setInputRests(prevValues => ({
            ...prevValues,
            [param.id]: JSON.stringify(pole1KpX),
          }));
          console.log('pole1KpX', pole1KpX);

        }else {
          */