import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { GenContext } from '../store/genData';
import Alert from '@mui/material/Alert';

const Logout = () => {
    const navigate = useNavigate();
    const [message, setMessage] = useState(null);
    const [severity, setSeverity] = useState(null);
    const { setUserEmail } = useContext(GenContext);

    useEffect(() => {
        const logout = async () => {
            const response = await fetch('/api/logout', {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            });

            const data = await response.json();

            if(!response.ok){
                console.log('odhlaseni se nepovedlo: ', response);
                setSeverity('error');
                setMessage('Něco se pokazilo');
                setTimeout(() => {
                    setMessage(null);
                    navigate('/login');
                }, 1000);
            } else {
                console.log('prijata datat po prihlaseni: ', data);
                console.log(`v login - ${data.message} `);
                localStorage.removeItem('token'); // Vymazani JWT z localStorage
                localStorage.removeItem('userEmail');
                setSeverity('success');
                setMessage('Odhlášení proběhlo úspěšně');
                setTimeout(() => {
                    setMessage(null);
                    navigate('/login');
                }, 1000);
            }
        };

        logout();
    }, [navigate, setUserEmail]);

    return (
        message && (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <Alert variant="outlined" severity={severity} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'auto', width: 350, overflow: 'auto', backgroundColor: 'white' }}>
                    {message}
                </Alert>
            </div>
        )
    );
};

export default Logout;