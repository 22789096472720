import React, { useEffect, useRef, useState, useContext } from 'react';
import { DetailDataContext } from '../store/detailDataContext';
import { gantt } from 'dhtmlx-gantt';
import 'dhtmlx-gantt/codebase/dhtmlxgantt.css';
import GroupButton from '../atoms/GroupButton';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import MenuItem from '@mui/material/MenuItem';
import theme from '../theme';
import RecyclingIcon from '@mui/icons-material/Recycling';
import { set } from 'date-fns';

const DataGraf = ({ tableEntita, dataVstup, links, resource, priority, type1, taskBuilding, taskAktualizuj, vizuProcesMapa }) => {

  //console.log('v DataGraf dataVstup, ver. nova', dataVstup);
  //console.log('v DataGraf links', links);
  //console.log('v DataGraf tableEntita', tableEntita);

  const ganttContainerRef = useRef(null);

  const [capacities, setCapacities] = useState([]);
  const [chartWidth, setChartWidth] = useState(0);
  const [chartLeftMargin, setChartLeftMargin] = useState(0);
  const [scaleUnit, setScaleUnit] = useState('quarter');
  const [filterValue, setFilterValue] = useState('');
  const [mapaAppVisible, setMapaAppVisible] = useState(false); // Inicializováno jako false
  const [refresh, setRefresh] = useState(false);
  const [criticalPathShown, setCriticalPathShown] = useState(false);
  const {pole1Data, setPole1Data, mapaData, setMapaData} = useContext(DetailDataContext); // Získání hodnot z kontextu
  const [resourceId, setResourceId] = useState('');
  const [type1Text, setType1Text] = useState('');
  const [originalHiddenValues, setOriginalHiddenValues] = useState({});
  
  const WORK_DAY = 8; // Definice počtu pracovních hodin v jednom dni
  const idIdent = '3492';
  let ganttData;

  // jen pro uvodni vlozeni dat
  useEffect(() => {
    console.log('v DataGraf useEffect ', 'mapaData', mapaData); 
    if(!mapaData?.data && !mapaData?.links ){
      try{
        gantt.clearAll();           
        console.log('------------------- v DataGraf useEffect data - uvodni vlozeni dat: ', dataVstup);
        const dataVstupX = ensureTaskDurations(dataVstup);
        const dataJson = JSON.stringify({data: dataVstupX, links: links, resource: resource, priority: priority})
       
        
        if(vizuProcesMapa !== 'listProcesMapa'){
          setMapaData({data: dataVstupX, links: links, resource: resource, priority: priority});
          setPole1Data({[idIdent]: dataJson});
          gantt.parse({ data: dataVstupX, links: links })
        }else{
          gantt.parse({ data: dataVstupX, links: links })
        }
        
        //gantt.serverList("resource", resource);
        gantt.serverList("resource", resource.map(item => ({ key: item.id, label: item.text })));
        gantt.serverList("priority", priority);
        /*
        if(mapaData?.data && mapaData?.links){
          console.log('------------------- v DataGraf useEffect data - uplne uvodni vlozeni dat - mapaData: ', mapaData);
          gantt.parse({ data: mapaData.data, links: mapaData.links })
        };
        */
      }catch(error){
        console.error('Chyba při zpracování dat:', error);
      }
      
     
    }
  }, [dataVstup, links, resource, mapaData]);
 
   

  //-----------today-------------
  const dateToStr = gantt.date.date_to_str(gantt.config.task_date);
  const today = new Date();
  
  gantt.plugins({
    marker: true
  });
  gantt.addMarker({
    start_date: today,
    css: "today",
    text: "Dnes",
    title: "Dnes: " + dateToStr(today)
  });
  //-----------today-------------
  

  //------- useEffect--------------------------------------------------------------------------------------------------------------------------------------------
  useEffect(() => {
    if (!ganttContainerRef.current) return;
    
      // nastaveni casove osy gantt         
      const zoomConfig = {
        levels: [
          { name: "day", scale_height: 27, min_column_width: 80, scales: [{ unit: "day", step: 1, format: "%d %M" }] },
          { name: "week", scale_height: 50, min_column_width: 50, scales: [{ unit: "week", step: 1, format: function (date) { var dateToStr = gantt.date.date_to_str("%d %M"); var endDate = gantt.date.add(date, 6, "day"); var weekNum = gantt.date.date_to_str("%W")(date); return "KT " + weekNum + ", " + dateToStr(date) + " - " + dateToStr(endDate); } }, { unit: "day", step: 1, format: "%j %D" }] },
          { name: "month", scale_height: 50, min_column_width: 120, scales: [{ unit: "month", format: "%F, %Y" }, { unit: "week", step: 1, format: "KT %W" }] },
          {
            name: "quarter",
            height: 50,
            min_column_width: 90,
            scales: [
                { 
                    unit: "quarter", 
                    step: 1, 
                    /*
                    format: function (date) {
                        var year = gantt.date.date_to_str("%Y")(date);
                        var dateToStr = gantt.date.date_to_str("%M");
                        var endDate = gantt.date.add(gantt.date.add(date, 3, "month"), -1, "day"); 
                        return dateToStr(date) + " - " + dateToStr(endDate) + ', ' + year; 
                    } 
                    */
                    format: function (date) {
                      var year = gantt.date.date_to_str("%Y")(date);
                      var month = date.getMonth();
                      var quarter = Math.floor(month / 3) + 1;
                      var romanQuarter = ["I", "II", "III", "IV"][quarter - 1];
                      return romanQuarter + ' - ' + year; 
                    } 
                },
                { 
                  unit: "month", 
                  step: 1, 
                  format: "%M" 
              }
            ]
          },
          { name: "year", scale_height: 50, min_column_width: 30, scales: [{ unit: "year", step: 1, format: "%Y" }] }
        ]
      };
      
      gantt.ext.zoom.init(zoomConfig);
      gantt.ext.zoom.setLevel(scaleUnit);
      gantt.ext.zoom.attachEvent("onAfterZoom", function (level, config) {
        const radio = document.querySelector(".gantt_radio[value='" + config.name + "']");
        if (radio) {
          radio.checked = true;
        }
        resourceConfig.scales = config.scales;
        //gantt.render();
        
      });
  
      gantt.plugins({
        marker: true
      });
  
      const today = new Date();
      gantt.addMarker({
        start_date: today,
        css: "today",
        text: "Dnes",
        title: "Dnes: " + gantt.date.date_to_str(gantt.config.task_date)(today)
      });
  
      gantt.config.date_format = "%Y-%m-%d";
      gantt.config.date_grid = "%d.%m.%Y";
      gantt.config.resource_property = "resource";
      gantt.config.resource_store = "resource";
      gantt.config.duration_unit = "day";
      gantt.config.auto_scheduling = true;
      gantt.config.auto_scheduling_strict = true;
      gantt.config.work_time = true;
      gantt.config.grid_width = 600;
  
      //definovani dat do gantt.serverList
      gantt.serverList("resource", resource.map(item => ({ key: item.id, label: item.text })));
      gantt.serverList("priority", priority);


    //{ name: "actual_start_date", label: "Skutečný start", width: 100, align: "center", template: (task) => task.actual_start_date ? formatDate(task.actual_start_date) : "" },
    //{ name: "actual_end_date", label: "Skutečné dokončení", width: 100, align: "center", template: (task) => task.actual_end_date ? formatDate(task.actual_end_date) : "" },
    gantt.config.columns = [
      ...(mapaAppVisible ? [{
        name: "mapaApp",
        label: "Aktuální v OP",
        align: "center",
        width: 30,
        template: (task) => {
          const isVisible = task.mapaApp;
          return `<input type="checkbox" data-task-id="${task.id}" ${isVisible ? 'checked' : ''} onclick='toggleTaskMapaApp("${task.id}")'/>`;
          
        }
      }] : []),
      {
        name: "owner", align: "center", width: 50, label: "Vlastník", template: function(task) {
          if (task.type === gantt.config.types.project) {
            return "";
          }
          const resourceId = task.resource;
          if (!resourceId) {
            return "";
          }
          const owner = resource.find(staffMember => staffMember.id === resourceId);
          if (owner) {
            return owner.text;
          }

          return "";
        }
      },
      { name: "text", label: "Úkol", width: 500, tree: true },
      { name: "start_date", label: "Začátek", width: 120, align: "center", template: (task) => gantt.templates.date_grid(task.start_date) },
      { name: "duration", label: "Trvání", width: 50, align: "center", template: (task) => task.type === gantt.config.types.milestone ? "" : task.duration },
      
      { name: "capacity", label: "Pracnost", width: 30, align: "center" },
      { name: "add", width: 44 }
    ];

    //------------vyskakovaci okno pro editaci úkolu, nebo noveho tasku
    gantt.locale.labels.section_text = "Úkol";
    gantt.locale.labels.section_priority = "Priorita";
    gantt.locale.labels.section_owner = "Vlastník";
    gantt.locale.labels.section_state = "Stav"; // Přidání popisku pro nový atribut
    gantt.locale.labels.section_capacity = "Kapacita"; // Přidání popisku pro nový atribut
    gantt.locale.labels.section_mapaApp = "Mapa App"; // Přidání popisku pro nový atribut
    gantt.locale.labels.section_hidden = "Skrytý"; // Přidání popisku pro nový atribut

    gantt.config.lightbox.sections = [
      {name: "text", height: 38, map_to: "text", type: "textarea", focus: true},
      {name: "priority", height: 22, map_to: "priority", type: "select", options: gantt.serverList("priority")},
      {name: "owner", height: 22, map_to: "resource", type: "select", options: gantt.serverList("resource")},
      {name: "state", height: 22, map_to: "state", type: "select", options: [
        {key: "actual", label: "actual"},
        {key: "planned", label: "model"}
      ]}, // Přidání pole pro stav
      {name: "capacity", height: 22, map_to: "capacity", type: "textarea"}, // Přidání pole pro kapacitu
      {name: "mapaApp", height: 22, map_to: "mapaApp", type: "checkbox"}, // Přidání pole pro mapaApp
      {name: "hidden", height: 22, map_to: "hidden", type: "checkbox"}, // Přidání pole pro hidden
      {name: "time", type: "duration", map_to: "auto"}
    ];

    gantt.attachEvent("onTaskCreated", function(task){
      task.state = "actual";
      task.capacity = 8;
      task.mapaApp = true;
      task.hidden = false;
      //console.log('onTaskCreated - task: ', task);
      
      return true;
    });
    
    //zakladani noveho tasku
    gantt.attachEvent("onLightboxSave", function(id, task, is_new){
       // Konverze string na číslo
      task.resource = parseInt(task.resource, 10) || task.resource;
      task.capacity = parseInt(task.capacity, 10) || task.capacity;
      task.priority = parseInt(task.priority, 10) || task.priority;

      if (is_new) {
        task.state = task.state || "actual";
        task.capacity = task.capacity;
        task.mapaApp = task.mapaApp !== undefined ? task.mapaApp : true;
        task.hidden = task.hidden !== undefined ? task.hidden : false;
        //console.log('onLightboxSave - v if - task: ', task);
      }
     
      return true;
    });
    
    
    // ----------------------------------------------------------------------
   
  
    // priraďte zdroje za task v grafu
    gantt.templates.rightside_text = function(start, end, task){
      return byId(gantt.serverList('resource'), task.resource);
    };

    // nastavte barvu pozadí pro víkendy
    gantt.templates.timeline_cell_class = function(task, date) {
      if(scaleUnit === 'week' || scaleUnit === 'day'){
        if (!gantt.isWorkTime({date: date, task: task}))
        return "week_end";
      }
      return "";
    };

    //--------------------------------- nastaveni pro zobrazeni histogramu--------------------------------------
    const resourceConfig = {
      
      columns: [
        {
          name: "name", label: "Name", tree: true, width: 250, template: function(resource) {
            return resource.text;
          }, resize: true
        },
       
        {
          name: "workload", label: "Alokováno", align: "center", template: function(resource) {
            var totalDuration = 0;
    
            var assignments = getResourceAssignments(resource.id);
            assignments.forEach(function(assignment) {
              var task = gantt.getTask(assignment.task_id);
              totalDuration += Number(assignment.value); // Use capacity directly
            });
    
            return (totalDuration || 0) + "h";
          }, resize: true
        },
        {
          name: "capacity", label: "Kapacita", align: "center", template: function(resource) {
            var store = gantt.getDatastore(gantt.config.resource_store);
            var n = store.hasChild(resource.id) ? store.getChildren(resource.id).length : 1;
    
            var state = gantt.getState();
    
            return gantt.calculateDuration(state.min_date, state.max_date) * n * (resource.capacity ? resource.capacity : WORK_DAY) + "h";
          }
        },
        {
          name: "vyuziti", label: "Využití", align: "center", template: function(resource) {
            var totalDuration = 0;
            var assignments = getResourceAssignments(resource.id);
            assignments.forEach(function(assignment) {
              var task = gantt.getTask(assignment.task_id);
              totalDuration += Number(assignment.value); // Use capacity directly
            });
      
            var store = gantt.getDatastore(gantt.config.resource_store);
            var n = store.hasChild(resource.id) ? store.getChildren(resource.id).length : 1;
            var state = gantt.getState();
            var capacity = gantt.calculateDuration(state.min_date, state.max_date) * n * (resource.capacity ? resource.capacity : WORK_DAY);
      
            // Vypočítejte vyuziti jako workload/capacity
            var vyuziti = totalDuration / capacity;
      
            return Math.round((vyuziti || 0) * 100) + "%"; // Vraťte hodnotu jako procento
          }, resize: true
        }
      ],
      
    };
    
    gantt.ext.zoom.attachEvent("onAfterZoom", function(level, config) {
      resourceConfig.scales = config.scales;
      //gantt.render();
    });

    gantt.$resourcesStore = gantt.createDatastore({
      name: gantt.config.resource_store,
      type: "treeDatastore",
      initItem: function(item) {
        item.parent = item.parent || gantt.config.root_id;
        item[gantt.config.resource_property] = item.parent;
        item.open = true;
        return item;
      }
    });

    gantt.silent(() => {
    gantt.$resourcesStore.parse(resource);
    })

    gantt.config.layout = {
      css: "gantt_container",
      rows: [
        {
          gravity: 3, // urcuje prostor pro hisgram, vetsi cislo je mensi prostor
          cols: [
            { view: "grid", group: "grids", scrollY: "scrollVer" },
            { resizer: true, width: 1 },
            { view: "timeline", scrollX: "scrollHor", scrollY: "scrollVer" },
            { view: "scrollbar", id: "scrollVer", group: "vertical" }
          ]
        },
        { resizer: true, width: 1, next: "resources" },
        
        {
          gravity: 1,
          id: "resources",
          config: resourceConfig,
          templates: {
            grid_row_class: function(start, end, resource) {
              var css = [];
              if (gantt.$resourcesStore.hasChild(resource.id)) {
                css.push("folder_row");
                css.push("group_row");
              }
              if (shouldHighlightResource(resource)) {
                css.push("highlighted_resource");
              }
              return css.join(" ");
            },
            task_row_class: function(start, end, resource) {
              var css = [];
              if (shouldHighlightResource(resource)) {
                css.push("highlighted_resource");
              }
              if (gantt.$resourcesStore.hasChild(resource.id)) {
                css.push("group_row");
              }
              return css.join(" ");
            }
          },
          cols: [
            { view: "resourceGrid", group: "grids", scrollY: "resourceVScroll",  scrollX: "scrollHor" },
            { resizer: true, width: 1 },
            { view: "resourceHistogram", capacity: getCapacity('', resource), scrollX: "scrollHor", scrollY: "resourceVScroll" }, //capacity je funkce, která určuje kapacitu (tj. maximální dostupnost) každého zdroje pro každý časový interval
            { view: "scrollbar", id: "resourceVScroll", group: "vertical" }
          ]
        },
        { view: "scrollbar", id: "scrollHor" }
      ]
    };
    //{ view: "resourceHistogram", capacity: getCapacity('', resource), scrollX: "scrollHor", scrollY: "resourceVScroll" },

    //pro vytváření popisků buněk histogramu. Argumenty start_date a end_date jsou poskytnuty knihovnou DHTMLX Gantt a reprezentují časový interval, který daná buňka histogramu pokrývá.
    //nastaveni zobrazeni dane bynky je pod view: "resourceHistogram"
    //gantt.config.scale_unit = scaleUnit;
    //gantt.config.step = 1;
    //console.log('----------------------------------- v DataGraf useEffect - scaleUnit: ', scaleUnit);
    gantt.templates.histogram_cell_label = function(start_date, end_date, resource, tasks) { 
      //console.log('gantt.templates.histogram_cell_label - tasks: ', tasks);
      //console.log('gantt.templates.histogram_cell_label - resource: ', resource);
      //if (tasks.length && !gantt.$resourcesStore.hasChild(resource.id)) {
      if (tasks.length && resource.capacity !== 0) {
        var allocated = getAllocatedValue(tasks, resource, start_date, end_date, '1');
        var capacity = getCapacity(start_date, resource);
        //var capacity = 1;
        //console.log('vypocet vypocet vypocet vypocetvypocetvypocetvypocetvypocet vypocetvypocetvypocetvypocet v gantt.templates.histogram_cell_label - start_date: ', start_date, 'resource: ', resource, 'allocated: ', allocated, 'capacity: ', capacity);
        var utilization = (allocated / capacity) * 100;
        if (utilization > 100) {
          //return "<span style='color: red;'>" + utilization.toFixed(1) + "%</span>";
          return utilization.toFixed(0) + "%";
        }
        //console.log('utilization: ', utilization);
        return utilization.toFixed(0) + "%";
      } else {
        if (!gantt.$resourcesStore.hasChild(resource.id)) {
          return '–';
        }
        return '';
      }
    };

    
    // vytvari probarveni bunky na zaklade velikosti vyuziti - urcuje vysku probarveni v bunce
    gantt.templates.histogram_cell_allocated = function(start_date, end_date, resource, tasks) {
      const allocated = getAllocatedValue(tasks, resource, start_date, end_date, '2');
      const capacity = getCapacity(start_date, resource);
      return (allocated / capacity) * 100;
    };
    // urcuje barvu probarveni bunky
    gantt.templates.histogram_cell_class = function(start_date, end_date, resource, tasks) {
      var value = getAllocatedValue(tasks, resource, start_date, end_date, '2');
      var capacity = getCapacity(start_date, resource);
      if (value > capacity) {
          return "overload"; // název třídy CSS
      }
    };
    
    gantt.templates.histogram_cell_capacity = function(start_date, end_date, resource, tasks) {
      if (!gantt.isWorkTime(start_date)) {
        return 0;
      }
      return getCapacity(start_date, resource);
    };
    
    gantt.config.histogram = {
      capacity: getCapacity, // Set capacity function to ensure the curve is drawn
      template: function(start_date, end_date, resource, tasks) {
        return getAllocatedValue(tasks, resource, start_date, end_date, '3');
      }
    };
        
    
    
    // ------------------------------------------------ konec nastaveni pro zobrazeni histogramu--------------------------------------

    gantt.attachEvent("onParse", function(){
      var styleId = "dynamicGanttStyles";
      var element = document.getElementById(styleId);
      if(!element){
        element = document.createElement("style");
        element.id = styleId;
        document.querySelector("head").appendChild(element);
      }
      var html = [];
      var resources = gantt.serverList("resource");

      resources.forEach(function(r){
        html.push(".gantt_task_line.gantt_resource_" + r.key + "{" +
          "background-color:"+r.backgroundColor+"; " +
          "color:"+r.textColor+";" +
        "}");
        html.push(".gantt_row.gantt_resource_" + r.key + " .gantt_cell:nth-child(1) .gantt_tree_content{" +
          "background-color:"+r.backgroundColor+"; " +
          "color:"+r.textColor+";" +
          "}");
      });
      element.innerHTML = html.join("");
    });

   

    // nastaveni barev podle task.state a podle toho zda ma task v datech barvu
    gantt.templates.task_class = function(start, end, task) {
      if (task.type === "project" && !task.color) {
        return "project-task";
      } else if (task.state === "actual" && task.type === "milestone" && !task.color) {
        return "milestone-actual-task";
      } else if (task.state === "actual" && !task.color) {
        return "actual-task";
      } else if (task.type === "milestone" && !task.color) {
        return "milestone-mapa-task";
      } else if (!task.color) { // Apply color only if task does not have a color property
        return "mapa-task";
      }
      return "";
    };
    
    
    
    
    // regeneruje vykresleni v gantt casti
    gantt.attachEvent("onBeforeTaskDisplay", function (id, task) {
      if (task.hidden) {
        return false;
      }

      return true;
    });
 
    
    gantt.attachEvent("onBeforeRowDisplay", function (id, resource) {
      if (resource.hidden) {
        return false;
      }
      return true;
    });

    
    gantt.i18n.setLocale('cs');

    gantt.init(ganttContainerRef.current);

    
    if(vizuProcesMapa !== 'listProcesMapa'){
      if (mapaData?.data && mapaData?.links) {
        console.log('------------------- v DataGraf useEffect data - uvodni vlozeni dat pri NE listProcesMapa: ', mapaData.data);
        //gantt.clearAll();
        gantt.parse({ data: mapaData.data, links: mapaData.links });
      }
    }else{
      console.log('------------------- v DataGraf useEffect data - uvodni vlozeni dat pri listProcesMapa: ', dataVstup);
      //gantt.clearAll();
      gantt.parse({ data: dataVstup, links: links });
    }
    
    
        
    const events = ['onTaskAdd', 'onAfterTaskUpdate', 'onTaskDelete', 'onLinkAdd', 'onAfterLinkUpdate', 'onLinkDelete'];
        events.forEach(event => {
          gantt.attachEvent(event, function () {
            updateGanttData()
           
          });
    });

    
    //gantt.refreshData();

  }, [mapaData, mapaAppVisible, scaleUnit, refresh]);

  //------------------------------------- konec useEffect---------------------------------------------------------------------------------------------------------------------------------------

  
  const zoomIn = () => {
    gantt.ext.zoom.zoomIn();
  };

  const zoomOut = () => {
    gantt.ext.zoom.zoomOut();
  };

    
  const changeScale = (unitCz) => {
    const unit = aliases[unitCz];
    setScaleUnit(unit);
    gantt.ext.zoom.setLevel(unit);
   
  };
  
  const aliases = {Den: 'day', Týden: 'week', Měsíc: 'month', Čtvrtletí: 'quarter', Rok: 'year'};
  

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  function byId(list, id) {
    for (var i = 0; i < list.length; i++) {
      if (list[i].key == id)
        return list[i].label || "";
    }
    return "";
  }
  
  // prirazeni today do ganttData do end_date, kdyz chybi duration a end_date je prazdne
  function ensureTaskDurations(tasks) {
    const now = new Date();
    tasks.forEach(task => {
      if (!task.duration && !task.end_date) {
        task.end_date = now;
      }
    });
    return tasks;
  };

  function shouldHighlightResource(resource) {
    var selectedTaskId = gantt.getState().selected_task;
    if (gantt.isTaskExists(selectedTaskId)) {
      var selectedTask = gantt.getTask(selectedTaskId),
          selectedResource = selectedTask[gantt.config.resource_property];

      if (resource.id == selectedResource) {
        return true;
      } else if (gantt.$resourcesStore.isChildOf(selectedResource, resource.id)) {
        return true;
      }
    }
    return false;
  }

  // Funkce pro získání přiřazení zdrojů k úkolům
  function getResourceAssignments(resourceId) {
    var assignments = [];
    gantt.eachTask(function(task) {
      if (task.resource === resourceId && !task.hidden) {
        assignments.push({ task_id: task.id, value: task.capacity, start_date: task.start_date, end_date: task.end_date});
      }
    });
    //console.log('assignments: ', assignments);
    return assignments;
  }

  // Aktualizujte ganttData pokaždé, když se data v Ganttově diagramu změní
  function updateGanttData() {
    if(vizuProcesMapa !== 'listProcesMapa'){
      const ganttDataJson = JSON.stringify(gantt.serialize())
      setMapaData(gantt.serialize());
      
      setPole1Data(prevValues => ({
        ...prevValues,
        [idIdent]: ganttDataJson,
      }));
    }
    
    //console.log('updateGanttData - ganttDataJson: ', ganttDataJson);
  }

  function toggleMapaAppVisibility(labelText) {
    gantt.eachTask((task) => {
      if(labelText === 'Edituj mapu'){
        task.hidden = false; 
      }else if(labelText === 'Aplikuj zvolenou mapu'){
        task.hidden = !task.mapaApp; // Skryje úkol, pokud má atribut mapaApp nastaven na false
        
      }
      //gantt.updateTask(task.id); // updatetuje data v Ganttově diagramu ale ne v datech, ktera vstupuji do gantt.parse
    });
    setMapaAppVisible(!mapaAppVisible);
    //gantt.refreshData();
    try{
      
      if(labelText === 'Aplikuj zvolenou mapu'){
               
        const ganttData = taskBuilding('appMapa')
        console.log('-------------------------- nova ganttData:', ganttData);
        // Znovu načtěte data do Ganttova diagramu
        if (ganttData) {
          // Znovu načtěte data do Ganttova diagramu
          gantt.clearAll(); // Odstraní všechny úkoly a odkazy
          //gantt.parse(ganttData); // Načte nová data
  
          // Ujistěte se, že všechny úkoly jsou zobrazeny před serializací
          gantt.eachTask((task) => {
            task.hidden = false;
          });
  
          console.log('-------------------------- gantt.serialize():', gantt.serialize());
        }
        setMapaData(ganttData); //dosazeni tecto dat do gantt se provede pomoci parse v useEffect     
        
      }
      
    }catch(error){
      console.error('Chyba při aktualizaci dat:', error);
    }
    
  }

  function aktualizujTaskActual() {
    gantt.eachTask((task) => {
      task.hidden = !task.mapaApp; // Skryje úkol, pokud má atribut mapaApp nastaven na false
    });
    setRefresh(!refresh);
    try{
        if (mapaData) {
          console.log('------------------- aktualizujTaskActual - data ke zpracovani: ', mapaData);
            const ganttData = taskAktualizuj()
            console.log('-------------------------- nova ganttData:', ganttData);
            // Znovu načtěte data do Ganttova diagramu
            if (ganttData) {
                // Znovu načtěte data do Ganttova diagramu
                gantt.clearAll(); // Odstraní všechny úkoly a odkazy
                setMapaData(ganttData); //dosazeni tecto dat do gantt se provede pomoci parse v useEffect     
            }else{ console.log('-------------------------- ganttData jsem z fce taskAktualizuj nedostal');}
            
        }
        gantt.refreshData();
    }catch(error){
        console.error('Chyba při aktualizaci dat:', error);
    }
  }

  // ----------- filtrujte úkoly podle zdroje
  

  const filterTasksByResource = (newResourceId) => {
    gantt.eachTask((task) => {
      if (task.mapaApp && task.type !== "project") {
        if (newResourceId === '') {
          // Pokud je newResourceId prázdný, vrátíme task.hidden na původní hodnotu
          task.hidden = originalHiddenValues[task.id];
        } else {
          // Uložíme původní hodnotu task.hidden, pokud ještě nebyla uložena
          if (originalHiddenValues[task.id] === undefined) {
            originalHiddenValues[task.id] = task.hidden;
            setOriginalHiddenValues({...originalHiddenValues});
          }
          // Nastavíme task.hidden na základě toho, zda task.resource odpovídá newResourceId
          if(task.resource === newResourceId){
            console.log('task: ', task, 'newResourceId: ', newResourceId);
            task.hidden = task.hidden ? true : false;
          }else{ task.hidden = true; }
          
        }
        //gantt.updateTask(task.id);
      }
    });
    gantt.refreshData();
    gantt.$resourcesStore.eachItem((resource) => {
      resource.hidden = resource.id !== newResourceId && newResourceId !== '';
    });
    

    setResourceId(newResourceId);

    gantt.$resourcesStore.refresh();
    //gantt.render();
  };

  const handleFilterChange1 = (event) => {
    const newResourceId = event.target.value;
    
    filterTasksByResource(newResourceId);
  };

  const handleClearFilter = () => {
    filterTasksByResource('');
  };
  // ------------- konec filtru úkolů podle zdroje

  const filterTasksByType1 = (newType1) => {
    
    gantt.eachTask((task) => {
      if (task.mapaApp && task.type !== "project") {
        if (newType1 === '') {
          // Pokud je newResourceId prázdný, vrátíme task.hidden na původní hodnotu
          task.hidden = originalHiddenValues[task.id];
        } else {
          // Uložíme původní hodnotu task.hidden, pokud ještě nebyla uložena
          if (originalHiddenValues[task.id] === undefined) {
            originalHiddenValues[task.id] = task.hidden;
            setOriginalHiddenValues({...originalHiddenValues});
          }
          // Nastavíme task.hidden na základě toho, zda task.resource odpovídá newResourceId
          if(task.type1 === newType1){
            console.log('task: ', task, 'newType1: ', newType1);
            task.hidden = task.hidden ? true : false;
          }else{ task.hidden = true; }
          
        }
        //gantt.updateTask(task.id);
      }
    });
    gantt.refreshData();
    setType1Text(newType1);

  };

  const handleFilterChange2 = (event) => {
    const newType1 = event.target.value;
    
    filterTasksByType1(newType1);
  };

  const handleClearFilter2 = () => {
    filterTasksByType1('');
  };

  

  // skryti / zobrazeni tasku s atributem actual
  const toggleActualTasksVisibility = (buttonText) => {
    gantt.eachTask((task) => {
      if (task.state === "actual") {
        if (!task.mapaApp){ return } // kdyz neni mapaApp, tak se funkce nepouzije
        task.hidden = buttonText === 'Skryj skutečné úkoly' ? true : buttonText === 'Zobraz skutečné úkoly' ? false : task.hidden;
        //gantt.updateTask(task.id);
      }
      originalHiddenValues[task.id] = task.hidden;
      setOriginalHiddenValues({...originalHiddenValues});
    });
    gantt.refreshData();
  };

  // skryti / zobrazeni tasku mapoveho podkladu, krome project tasku
  const toggleMapaTasksVisibility = (buttonText) => {
    gantt.eachTask((task) => {
      if ((!task.state || task.state === "model") && task.type !== "project"){
        if (!task.mapaApp){ return } // kdyz neni mapaApp, tak se funkce nepouzije
        task.hidden = buttonText === 'Skryj mapové úkoly' ? true : buttonText === 'Zobraz mapové úkoly' ? false : task.hidden;
        //gantt.updateTask(task.id);
      }
    });
    gantt.refreshData();
  };
  
 
  // vypocet hodnoty alokace v histogramu
  function getAllocatedValue(tasks, resource, start_date, end_date, m) {
    var result = 0;
    //console.log(m, m, m, m, m, m, m, m, m, m, m, m, m, m, m, m, m, m, m, m, m, m, m, ' getAllocatedValue - tasks: ', tasks, 'resource: ', resource, 'start_date: ', start_date, 'end_date: ', end_date);
    tasks.forEach(function(item) {
      if (!item.hidden && resource.id === item.resource) { // jen tasky ktere nejsou hidden
        const assignments = getResourceAssignments(resource.id);
        //console.log('--------------------------------------------- getAllocatedValue - assignments: ', assignments);
        //assignments.forEach(function(assignment) {
          // Přidáme podmínku, která zkontroluje, zda start_date a end_date leží mezi start_date a end_date assignmentu
          //console.log('--------------------------------------------- getAllocatedValue - resource: ', resource, 'start_date: ', start_date, 'end_date: ', end_date, 'task: ', item, 'scaleUnit: ', scaleUnit);
          //console.log("Scale unit: ", gantt.config.scale_unit);
          //console.log("Step: ", gantt.config.step);
          if (new Date(item.start_date) < end_date && new Date(item.end_date) > start_date) {
            //console.log('--------------------------------------------- getAllocatedValue - proslo do if - assignment: ', assignment);
            
            // Najdeme průnik intervalů
            let intersectionStartDate = new Date(Math.max(new Date(item.start_date), start_date));
            let intersectionEndDate = new Date(Math.min(new Date(item.end_date), end_date));
        
            // Vypočítáme délku průniku v pracovních dnech
            let intersectionDuration = 0;
            for (let d = new Date(intersectionStartDate); d <= intersectionEndDate; d.setDate(d.getDate() + 1)) {
              if (d.getDay() !== 0 && d.getDay() !== 6) { // 0 je neděle, 6 je sobota
                intersectionDuration++;
              }
            }
                    
            let value = item.capacity !== null ? Number(item.capacity) : 0;
            result += item.duration !== 0 ? (value / item.duration) * intersectionDuration : 0;
            //console.log('--------------------------------------------- getAllocatedValue - intersectionDuration: ', intersectionDuration, 'value: ', value, 'item.duration: ', item.duration, 'result: ', result);
          }
        //});
      }
    });
    //console.log('vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv getAllocatedValue - result: ', result, ' h', 'start_date: ', start_date, 'end_date: ', end_date, 'resource: ', resource, 'tasks: ', tasks, 'm: ', m);
    return result;
  }

/*
  // vypocet kapacity v histogramu  
  function getCapacity(date, resource) {
    if (gantt.$resourcesStore.hasChild(resource.id)) {
      return -1;
    }
  
    return (resource.capacity ? resource.capacity : WORK_DAY); // Each day is assumed to be 8 hours of work
  }
  */

  
  // Funkce pro získání kapacity
function getCapacity(start_date, resource) {
  if (!start_date) {
    start_date = new Date(); // Použije aktuální datum, pokud není specifikováno
  }

  // u resources ktere nemaji kapacitu a maji id mensi nez 100 (tzn. skupiny a podskupiny) se nastavi kapacita na -1, zdroje jednotliuvi lide projdou dale
  if ((resource.capacity === 0) || (resource.capacity === undefined && resource.id < 100)) {
    return -1;
  }
  /*
  if (gantt.$resourcesStore.hasChild(resource.id)) {
    return -1;
  }
  */

  let capacityUn = resource.capacity ? resource.capacity : WORK_DAY;

  // Získáme konec období pro danou škálu
  var end_date;
  switch (scaleUnit) {
    case 'week':
      end_date = gantt.date.add(start_date, 1, 'week');
      break;
    case 'month':
      end_date = gantt.date.add(start_date, 1, 'week');
      break;
    case 'quarter':
      end_date = gantt.date.add(start_date, 1, 'month');
      break;
    case 'year':
      end_date = gantt.date.add(start_date, 1, 'year');
      break;
    default:
      end_date = gantt.date.add(start_date, 1, 'day');
  }

  // Vypočítáme počet pracovních dnů v období
  var workDays = gantt.calculateDuration(start_date, end_date);
   
  const capacity = capacityUn * workDays;
  //console.log('getCapacity - start_date: ', start_date, 'end_date: ', end_date, 'workDays: ', workDays, 'capacityUn: ', capacityUn, 'scaleUnit: ', scaleUnit, 'capacity: ', capacity);
  return capacity;
} 

  //---------------- zaskrtnuti checkboxu v tabulce
  window.toggleTaskMapaApp = toggleTaskMapaApp;

  function toggleTaskMapaApp(taskId) {
    const task = gantt.getTask(taskId);
    if (task) {
      gantt.silent(() => {
        const toggleVisibility = (task, parentMapaApp) => {
          const checkbox = document.querySelector(`input[data-task-id='${task.id}']`);
          if (checkbox) {
            checkbox.checked = parentMapaApp !== undefined ? parentMapaApp : !task.mapaApp;
            task.mapaApp = checkbox.checked;
          }
          gantt.eachTask((childTask) => {
            if (childTask.parent === task.id) {
              toggleVisibility(childTask, task.mapaApp);
            }
          });
          gantt.updateTask(task.id);
        };

        toggleVisibility(task);
      });

      //gantt.refreshData(); // Refresh the Gantt chart data
    }
  }


  //------------------

  


  const toggleCollapseExpandState = (level) => {
    const stateKey = `collapsedLevel${level}`;
    const newState = !window[stateKey];
    collapseExpandTasks(level, newState ? 'collapse' : 'expand');
    window[stateKey] = newState;
  };

  const collapseExpandTasks = (level, action) => {
    gantt.eachTask((task) => {
      if (task.$level === level) {
        if (action === 'collapse') {
          gantt.close(task.id);
        } else if (action === 'expand') {
          gantt.open(task.id);
        }
      }
    });
  };

  function updateCriticalPath() {
    const newCriticalPathShown = !criticalPathShown;
    setCriticalPathShown(newCriticalPathShown);
		// Povolení rozšíření pro kritickou cestu
    gantt.plugins({ criticalPath: true });
		if (newCriticalPathShown) {
			gantt.config.highlight_critical_path = true;
		} else {
			gantt.config.highlight_critical_path = false;
		}
		gantt.render();
	}


  
  return (
    <div className="flex-container" style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <Stack spacing={2} direction="row" alignItems="center" justifyContent="center" margin="5px">
        <GroupButton buttons={['Den', 'Týden', 'Měsíc', 'Čtvrtletí', 'Rok']} changeScale={changeScale} />
        <Button variant="outlined" size="small" color="secondary" onClick={zoomIn}>Zoom +</Button>
        <Button variant="outlined" size="small" color="secondary" onClick={zoomOut}>Zoom -</Button>
        <Button variant="outlined" size="small" color="secondary" onClick={() => toggleCollapseExpandState(0)}>Sbalit/Rozbalit OP</Button>
        <Button variant="outlined" size="small" color="secondary" onClick={() => toggleCollapseExpandState(1)}>Sbalit/Rozbalit hladinu pod OP</Button>
        <Button variant="outlined" size="small" color="secondary" onClick={() => toggleCollapseExpandState(2)}>Sbalit/Rozbalit detaily</Button>
        <Button variant="outlined" size="small" color="secondary" onClick={() => updateCriticalPath()}>Ukaž kritickou cestu</Button>
      </Stack>
      <Stack spacing={2} direction="row" alignItems="center" justifyContent="center" margin="5px">
        <Button variant="outlined" size="small" color="secondary" onClick={() => toggleActualTasksVisibility('Skryj skutečné úkoly')}>Skryj skutečné úkoly</Button>
        <Button variant="outlined" size="small" color="secondary" onClick={() => toggleActualTasksVisibility('Zobraz skutečné úkoly')}>Zobraz skutečné úkoly</Button>
        <Button variant="outlined" size="small" color="secondary" onClick={() => toggleMapaTasksVisibility('Skryj mapové úkoly')}>Skryj mapové úkoly</Button>
        <Button variant="outlined" size="small" color="secondary" onClick={() => toggleMapaTasksVisibility('Zobraz mapové úkoly')}>Zobraz mapové úkoly</Button>
                
        
        <FormControl style={{ margin: '10px 10px 5px 5px', height: '35px', width: '250px', display: 'flex', alignItems: 'center' }}>
          <TextField
            select
            label="Vlastník úkolu"
            value={resourceId}
            onChange={handleFilterChange1}
            variant="outlined"
            size="small"
            color="secondary"
            style={{ fontSize: '12px', textAlign: 'left', width: '250px' }}
            InputProps={{
              style: { height: '30px'},
              endAdornment: (
                <InputAdornment position="end">
                  {resourceId && (
                    <IconButton onClick={handleClearFilter} style={{ marginRight: '10px' }}>
                      <CloseIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              shrink: true,
              style: { textAlign: 'left', width: '100%' }
              
            }}
          >
            {Array.isArray(resource) && resource.map((resource) => (
              <MenuItem key={resource.id} value={resource.id}>{resource.text}</MenuItem>
            ))}
          </TextField>
        </FormControl>
        <FormControl style={{ margin: '10px 10px 5px 5px', height: '35px', width: '250px', display: 'flex', alignItems: 'center' }}>
          <TextField
            select
            label="Typ úkolu"
            value={type1Text}
            onChange={handleFilterChange2}
            variant="outlined"
            size="small"
            color="secondary"
            style={{ fontSize: '12px', textAlign: 'left', width: '250px' }}
            InputProps={{
              style: { height: '30px'},
              endAdornment: (
                <InputAdornment position="end">
                  {type1Text && (
                    <IconButton onClick={handleClearFilter2} style={{ marginRight: '10px' }}>
                      <CloseIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              shrink: true,
              style: { textAlign: 'left', width: '100%' }
              
            }}
          >
            {Array.isArray(type1) && type1.map((type1) => (
              <MenuItem key={type1.id} value={type1.text}>{type1.text}</MenuItem>
            ))}
          </TextField>
        </FormControl>
        {vizuProcesMapa === 'detailProcesMapa' && (
          <Button variant="outlined" size="small" color="secondary" onClick={() => toggleMapaAppVisibility(mapaAppVisible ? 'Aplikuj zvolenou mapu' : 'Edituj mapu')}>
            {mapaAppVisible ? 'Aplikuj zvolenou mapu' : 'Edituj mapu'}
          </Button>
        )}
        <IconButton
                      title="Aktualizuj skutečné úkoly"
                      onClick={aktualizujTaskActual}
                      sx={{ 
                          margin: '15px',
                          marginLeft: '12px',
                          backgroundColor: theme.palette.secondary.dark,
                          fontWeight: 700,
                          '&:hover': {
                              backgroundColor: theme.palette.secondary.main,
                          },
                      }}
                  >
                      <RecyclingIcon
                          sx={{ 
                              color: 'white', 
                              fontSize: '22px'
                          }}
                      />
        </IconButton>
        
      </Stack>
      <div ref={ganttContainerRef} style={{ flex: 3, width: '100%' }}></div>
    </div>
  );
};

export default DataGraf;
